import { Button, Text } from '@aisekisan/bond'
import type { AccountInvitationDetail } from '@aisekisan/anya-api'
import { AccountLayout } from '../../layout'
import { useStyles } from '../../styles'
import { T } from '@/libs/intl/t'

interface Props {
  invitation: AccountInvitationDetail
}

export function SignUpAcceptSuccess(props: Props): JSX.Element {
  const { invitation } = props

  const styles = useStyles()

  return (
    <AccountLayout>
      <div className={styles.card}>
        <div className={styles.flexCol}>
          <Text className={styles.title3}>
            <T
              id="sign-up.accept.success.title {{.orgName}}"
              params={{ orgName: `${invitation.orgName}` }}
            />
          </Text>
          <Text className={styles.textBody}>
            <T id="sign-up.accept.success.help" />
          </Text>
          <Button as="a" href="/sign-in" appearance="primary" size="large">
            <T id="sign-up.accept.success.go" />
          </Button>
        </div>
      </div>
    </AccountLayout>
  )
}
