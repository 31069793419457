import { Button, Text, mergeClasses } from '@aisekisan/bond'
import { CheckmarkCircle48Regular } from '@fluentui/react-icons'
import { AccountLayout } from '../layout'
import { useStyles } from '../styles'
import { T } from '@/libs/intl/t'

export function SignUpAcceptSuccess(): JSX.Element {
  const styles = useStyles()
  return (
    <AccountLayout>
      <div className={mergeClasses(styles.flexCol, styles.card)}>
        <CheckmarkCircle48Regular color="green" className={styles.alignCenter} />
        <Text className={styles.alignCenter}>
          <T id="reset-password.accept.success.help" />
        </Text>
        <Button
          type="button"
          as="a"
          href="/sign-in"
          appearance="primary"
          size="large"
        >
          <T id="reset-password.accept.success.go" />
        </Button>
      </div>
    </AccountLayout>
  )
}
