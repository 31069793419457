import type { AnnotationDetail, PageDetail } from '@aisekisan/anya-api'
import { useConstructionModuleList } from '@aisekisan/anya-api'
import groupBy from 'lodash/groupBy'
import type { ReportHead } from './type'

interface Params {
  constructionID: string
  pages: PageDetail[]
  reportAnnots: AnnotationDetail[]
}

export function useReportHeads(params: Params): ReportHead[] {
  const { constructionID, pages, reportAnnots } = params
  const cModules = useConstructionModuleList({ constructionID }).data ?? []
  const groups = groupBy(reportAnnots, 'pageID')

  return Object.entries(groups)
    .map(([pageID, annots]) => {
      const pageName = pages.find(page => pageID === page.id)?.name ?? ''
      const modules = [...new Set(
        annots.map(({ moduleID }) => cModules.find(({ id }) => id === moduleID)),
      )]
      return ({ pageID, pageName, modules })
    })
}
