import { Body1, Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Input, OverlaySpinner, makeStyles, tokens } from '@aisekisan/bond'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { Dismiss24Regular } from '@fluentui/react-icons'
import type { GlobalPresetDetail, OrgPresetDetail } from '../type'
import { T } from '../libs/intl/t'
import { useT } from '../libs/intl/useT'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalL,
  },
})

interface Props {
  preset: OrgPresetDetail | GlobalPresetDetail
  isPending: boolean
  deletePreset: () => void
  close: () => void
}

export function PresetDelete(props: Props): ReactElement {
  const { preset, isPending, deletePreset, close } = props

  const t = useT()
  const styles = useStyles()

  const [confirmation, setConfirmation] = useState<string>('')
  const [error, setError] = useState<string | null>(null)

  return (
    <Dialog open onOpenChange={close}>
      <DialogSurface>
        <DialogBody>
          <OverlaySpinner visible={isPending} appearance="primary" />
          <DialogTitle action={(
            <DialogTrigger action="close">
              <Button
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
                onClick={close}
              />
            </DialogTrigger>
          )}
          >
            <T id="preset-property.preset.delete" />
          </DialogTitle>
          <DialogContent>
            <div className={styles.content}>
              <Body1>
                <T id="preset-property.preset.delete.helper.1" />
                <strong>
                  {preset.name}
                </strong>
                <T id="preset-property.preset.delete.helper.1.1" />
              </Body1>
              <Body1>
                <ul style={{ margin: 0 }}>
                  <li>
                    <T id="preset-property.preset.delete.list.1" />
                  </li>
                  <li>
                    <T id="preset-property.preset.delete.list.2" />
                  </li>
                </ul>
              </Body1>
              <Body1>
                <T id="preset-property.preset.delete.helper.2" />
              </Body1>
              <Body1>
                <T id="preset-property.preset.delete.helper.3" />
              </Body1>
              <Field
                label={t('preset-property.preset.delete.validation')}
                validationState={error !== null ? 'error' : 'none'}
                validationMessage={error}
              >
                <Input
                  onChange={e => setConfirmation(e.target.value)}
                  defaultValue={confirmation}
                  size="large"
                  appearance="filled-darker"
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={close}>
              <T id="preset-property.action.cancel" />
            </Button>
            <Button
              appearance="primary"
              onClick={() => {
                if (confirmation === preset.name)
                  deletePreset()
                else
                  setError(`Preset name must be same with '${preset.name}'`)
              }}
            >
              <T id="preset-property.preset.delete.submit" />
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
