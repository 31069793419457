import { parseServerError, useOrgPresetUpdate } from '@aisekisan/anya-api'
import type { OrgPresetDetail } from '@aisekisan/anya-api'
import { PresetFormDialog } from '@aisekisan/preset-property'
import type { ReactElement } from 'react'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'

interface Props {
  preset: OrgPresetDetail
  close: () => void
}

export function OrgPresetEdit(props: Props): ReactElement {
  const { preset, close } = props

  const t = useT()

  const update = useOrgPresetUpdate({ orgPresetID: preset.id, orgID: preset.orgID })

  return (
    <PresetFormDialog
      preset={preset}
      type="update"
      isPending={update.isPending}
      submit={(values) => {
        update.mutate(values, { onSuccess: close })
      }}
      close={close}
      errorAlert={update.isError
        ? (
          <ErrorAlert
            title={t('preset.edit.error.title')}
            help={t('all.error.review')}
            detail={parseServerError(update.error)}
            onClose={() => update.reset()}
          />
          )
        : null}
    />
  )
}
