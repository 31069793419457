import type { BoxLike, TLDefaultColorStyle, TLShapeId } from '@tldraw/tldraw'
import { createShapeId } from '@tldraw/tldraw'
import { randomAnnotShapeColor } from '../../shape/color'
import type { PieceBoxShapePartial } from './shape'

export function createPieceBoxShape(props: {
  box: BoxLike
  //
  id: TLShapeId | null
  color: TLDefaultColorStyle | null
  group: string | null
}): PieceBoxShapePartial {
  const { box, id, group, color } = props

  const { x, y, w, h } = box

  const piece: PieceBoxShapePartial = {
    type: 'geo',
    id: id ?? createShapeId(),
    x,
    y,
    opacity: 0.9,
    props: {
      geo: 'rectangle',
      dash: 'solid',
      fill: 'none',
      size: 's',
      color: color ?? randomAnnotShapeColor(),
      w,
      h,
    },
    meta: {
      type: 'annot',
      group: group ?? crypto.randomUUID(),
      annot: 'piece',
      shape: 'box',
    },
  }

  return piece
}
