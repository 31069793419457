import type { SVGProps } from 'react'

export function FlagJA(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.83331 4.5H17.8333V15.5H2.83331V4.5Z" fill="#F2FAFF" />
      <path
        d="M17.3333 5V15H3.33331V5H17.3333ZM18.3333 4H2.33331V16H18.3333V4Z"
        fill="#A3B7CC"
      />
      <path
        d="M10.3333 7.5C9.67027 7.5 9.03439 7.76339 8.56555 8.23223C8.09671 8.70107 7.83331 9.33696 7.83331 10C7.83331 10.663 8.09671 11.2989 8.56555 11.7678C9.03439 12.2366 9.67027 12.5 10.3333 12.5C10.9964 12.5 11.6322 12.2366 12.1011 11.7678C12.5699 11.2989 12.8333 10.663 12.8333 10C12.8333 9.33696 12.5699 8.70107 12.1011 8.23223C11.6322 7.76339 10.9964 7.5 10.3333 7.5Z"
        fill="#F78F8F"
      />
      <path
        d="M10.3333 8C11.4363 8 12.3333 8.897 12.3333 10C12.3333 11.103 11.4363 12 10.3333 12C9.23031 12 8.33331 11.103 8.33331 10C8.33331 8.897 9.23031 8 10.3333 8ZM10.3333 7C8.67631 7 7.33331 8.343 7.33331 10C7.33331 11.657 8.67631 13 10.3333 13C11.9903 13 13.3333 11.657 13.3333 10C13.3333 8.343 11.9903 7 10.3333 7Z"
        fill="#C74343"
      />
    </svg>
  )
}
