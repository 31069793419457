import type {
  MenuProps,
} from '@aisekisan/bond'
import {
  Menu,
  MenuButton,
  MenuItemRadio,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from '@aisekisan/bond'
import {
  Eye20Regular,
  Key20Regular,
  Pen20Regular,
} from '@fluentui/react-icons'
import { useState } from 'react'
import type { ConstructionRole } from '@aisekisan/anya-api'
import { useConstructionMemberUpdateRole } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'

const ROLE_ICONS = [
  { role: 'owner', icon: <Key20Regular /> },
  { role: 'editor', icon: <Pen20Regular /> },
  { role: 'viewer', icon: <Eye20Regular /> },
]

interface Props {
  constructionID: string
  accountID: string
  role: ConstructionRole
}

export function UpdateRoleSelect(props: Props) {
  const { constructionID, accountID, role } = props

  const [updateRole, setUpdateRole] = useState<Record<string, string[]>>({
    role: [role],
  })

  const update = useConstructionMemberUpdateRole({ constructionID, accountID })

  const onChange: MenuProps['onCheckedValueChange'] = (
    _e,
    { name, checkedItems },
  ) => {
    setUpdateRole((s) => {
      return s ? { ...s, [name]: checkedItems } : { [name]: checkedItems }
    })
    update.mutate(checkedItems[0] as ConstructionRole)
  }

  const getRole = (): JSX.Element => {
    switch (role) {
      case 'owner':
        return <T id="construction.role.owner.title" />
      case 'editor':
        return <T id="construction.role.editor.title" />
      case 'viewer':
        return <T id="construction.role.viewer.title" />
    }
  }

  return (
    <Menu onCheckedValueChange={onChange} checkedValues={updateRole}>
      <MenuTrigger>
        <MenuButton
          appearance="transparent"
          icon={ROLE_ICONS.find(roleIcon => roleIcon.role === role)?.icon}
          style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: 'normal',
          }}
        >
          {getRole()}
        </MenuButton>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          <MenuItemRadio icon={<Pen20Regular />} name="role" value="editor">
            <T id="construction.role.editor.title" />
          </MenuItemRadio>
          <MenuItemRadio icon={<Key20Regular />} name="role" value="owner">
            <T id="construction.role.owner.title" />
          </MenuItemRadio>
          <MenuItemRadio icon={<Eye20Regular />} name="role" value="viewer">
            <T id="construction.role.viewer.title" />
          </MenuItemRadio>
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
