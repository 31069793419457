import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { T } from '../../libs/intl/t'

interface Props {
  close: () => void
  onDelete: () => void
}

export function DeleteInsulSizeProperty(props: Props): ReactElement {
  const { onDelete, close } = props

  return (
    <Dialog open onOpenChange={close}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle action={(
            <DialogTrigger action="close">
              <Button
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
                onClick={close}
              />
            </DialogTrigger>
          )}
          >
            <T id="preset-property.insul-size.delete" />
          </DialogTitle>
          <DialogContent>
            <T id="preset-property.insul-size.delete.help" />
          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              onClick={onDelete}
            >
              <T id="preset-property.insul-size.delete.submit" />
            </Button>
            <Button onClick={close}>
              <T id="preset-property.action.cancel" />
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
