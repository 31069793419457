import {
  Body1,
  Button,
  Input,
  Label,
  Text,
  mergeClasses,
} from '@aisekisan/bond'
import { Mail20Regular } from '@fluentui/react-icons'
import { useStyles } from '../styles'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'

export function ResetPasswordSentEmail(props: {
  email: string
  setSent: (value: string | false) => void
}): JSX.Element {
  const styles = useStyles()

  const { email, setSent } = props

  const t = useT()
  return (
    <div className={mergeClasses(styles.flexCol, styles.card)}>
      <Text className={styles.title3}>
        <T id="reset-password.email.sent" />
      </Text>

      <Body1 className={styles.textBody}>
        <T id="reset-password.email.sent.description" />
      </Body1>
      <div className={styles.field}>
        <Label htmlFor="email">{t('account.field.email')}</Label>
        <Input
          type="email"
          placeholder="name@gmail.com"
          required
          contentBefore={<Mail20Regular />}
          value={email}
          readOnly
          size="large"
          appearance="filled-darker"
        />
      </div>
      <Body1 className={styles.textBody}>
        <T id="reset-password.email.sent.help.1" />
      </Body1>
      <Body1 className={styles.textBody}>
        <T id="reset-password.email.sent.help.2" />
      </Body1>
      <div className={styles.fullWidth}>
        <Button onClick={() => void setSent(false)} size="large">
          <T id="reset-password.email.sent.go-back" />
        </Button>
      </div>
    </div>
  )
}
