import type { IntlDict } from './type'

export const INTL_DICT_JA: IntlDict = {
  'preset-property.action.cancel': 'キャンセル',
  'preset-property.action.delete': '削除',
  'preset-property.action.edit': '編集',
  'preset-property.action.required_field': 'この項目は必須です',
  'preset-property.action.select-one': '未選択',
  'preset-property.children.delete.cancel': 'キャンセル',
  'preset-property.children.delete.help': 'この子プロパティを削除してもよろしいですか?',
  'preset-property.children.delete.submit': '削除',
  'preset-property.children.delete.title': 'プロパティ削除',
  'preset-property.children.delete': '削除',
  'preset-property.children.label': 'ラベル',
  'preset-property.copy.equipment': '他の資材にコービー',
  'preset-property.copy.help': '貼り付けたい部材を選択ください',
  'preset-property.copy.submit': 'コピー',
  'preset-property.insul-size.delete.help': 'サイズのプロパティを削除すると、関連データも削除されます。サイズのプロパティを削除しますか? ',
  'preset-property.insul-size.delete.submit': '削除',
  'preset-property.insul-size.delete': '保温プロパティの削除',
  'preset-property.insul-size.equip.hint': 'これらの設定は、指定された資材のみ適用されます。適用する資材を選択してください。注：この選択肢を変更できません。',
  'preset-property.insul-size.equip': '適用資材',
  'preset-property.insul-size.from': 'から',
  'preset-property.insul-size.size': 'サイズ',
  'preset-property.insul-size.thickness': '厚み',
  'preset-property.insul-size.to': 'まで',
  'preset-property.insul.cover.hint': '施工箇所によって外装材が異なることがあります。この施工箇所に適切な外装材を選択してください。注：この選択肢を変更できません。',
  'preset-property.insul.cover': '外装材',
  'preset-property.insul.delete.help': '保温のプロパティを削除すると、関連データも削除されます。保温のプロパティを削除しますか?',
  'preset-property.insul.delete.submit': '削除',
  'preset-property.insul.delete': '保温の削除',
  'preset-property.insul.inner': '保温材',
  'preset-property.locale.jp': '日本語',
  'preset-property.locale.us': '英語',
  'preset-property.market.jp': '日本',
  'preset-property.market.us': 'アメリカ',
  'preset-property.node.delete.help.1': '全てのプロパティ',
  'preset-property.node.delete.help.2': '全てのサイズプロパティ',
  'preset-property.node.delete.help': 'この選択肢を削除しますと、次のような関連データがすべて削除されます：',
  'preset-property.node.delete.submit': '削除',
  'preset-property.node.delete': '選択肢削除',
  'preset-property.node.menu.children': '一般プロパティ',
  'preset-property.node.menu.insul-size': 'サイズのプロパティ',
  'preset-property.node.menu.insul': '保温のプロパティ',
  'preset-property.node.menu.size': 'サイズプロパティ',
  'preset-property.node.menu': 'プロパティ作成',
  'preset-property.option.create.hint.1': '拾い作業の時にこの選択肢しか選択できません',
  'preset-property.option.create.hint.2': '“…”アイコンをクリックし、プロパティを作成することができます',
  'preset-property.option.create': '選択肢の追加',
  'preset-property.preset.create.submit': '作成',
  'preset-property.preset.create': 'プリセット作成',
  'preset-property.preset.delete.helper.1.1': ' 次のような関連データがすべて削除されます：',
  'preset-property.preset.delete.helper.1': 'プリセットを削除してもよろしいですか？ ',
  'preset-property.preset.delete.helper.2': '今後の案件はこのプリセットを使用できません。 ただし、既存の案件には影響しません。',
  'preset-property.preset.delete.helper.3': '削除した後は戻すことができません。 削除を確認するため、プリセット名を入力してください。',
  'preset-property.preset.delete.list.1': '全てのカスタム部材',
  'preset-property.preset.delete.list.2': '全てのカスタムプロパティ',
  'preset-property.preset.delete.submit': 'プリセット削除',
  'preset-property.preset.delete.validation': 'プリセット名',
  'preset-property.preset.delete': 'プリセット削除',
  'preset-property.preset.description': '説明',
  'preset-property.preset.label': 'プリセット',
  'preset-property.preset.locale': '言語',
  'preset-property.preset.market': '地域',
  'preset-property.preset.name': '名称',
  'preset-property.preset.overview': '概要',
  'preset-property.preset.update.submit': '更新',
  'preset-property.preset.update': 'プリセット更新',
  'preset-property.preset.view': '表示',
  'preset-property.property.delete.help': 'このプロパティを削除してもよろしいですか?',
  'preset-property.property.delete.submit': '削除',
  'preset-property.property.delete': 'プロパティ削除',
  'preset-property.property.duplicate': '複製',
  'preset-property.property.equip-name.hint': '部材を変更することができません。',
  'preset-property.property.equip-name': '部材名',
  'preset-property.property.equip.hint': '部材を変更することができませんが、他の部材にコピーすることができます',
  'preset-property.property.equip': '部材',
  'preset-property.property.group': '選択されている：',
  'preset-property.property.menu.children': '一般プロパティの作成',
  'preset-property.property.menu.insul-size': '保温サイズの作成',
  'preset-property.property.menu.insul': '保温のプロパティの作成',
  'preset-property.property.menu.size': 'サイズの作成',
  'preset-property.property.options': '選択肢',
  'preset-property.property.overview': '概要',
  'preset-property.property.view': ' 依存プロパティを表示する',
  'preset-property.property.when': '選択されている：',
  'preset-property.shape.box.height': '高さ',
  'preset-property.shape.box.length': '長さ',
  'preset-property.shape.box.width': '幅',
  'preset-property.shape.box': 'ボックス',
  'preset-property.shape.circle.diameter': '直径',
  'preset-property.shape.circle': '円形',
  'preset-property.shape.custom.size': 'サイズ',
  'preset-property.shape.custom': 'その他',
  'preset-property.shape.delete': '削除',
  'preset-property.shape.duplicate': '複製',
  'preset-property.shape.electric-power.kW': '機器能力',
  'preset-property.shape.electric-power': '機器能力',
  'preset-property.shape.rect.height': '高さ',
  'preset-property.shape.rect.width': '幅',
  'preset-property.shape.rectangle': '長方形',
  'preset-property.shape.refrigerant-pipe.liquid': '液管',
  'preset-property.shape.refrigerant-pipe.suction': 'ガス管',
  'preset-property.shape.refrigerant-pipe': '冷媒管',
  'preset-property.size.delete.help': 'このサイズプロパティを削除してもよろしいですか?',
  'preset-property.size.delete.submit': '削除',
  'preset-property.size.delete.title': 'サイズプロパティ削除',
  'preset-property.size.label.hint': '例：品目、品名',
  'preset-property.size.label': 'ラベル',
  'preset-property.size.shape.hint': 'サイズのタイプによってはサイズの選択肢が変わります',
  'preset-property.size.shape': 'サイズのタイプ',
  'preset-property.size.type.hint': 'タイプを変更することができません',
  'preset-property.size.type': 'タイプ',
  'preset-property.size.unit': '単位',
  'preset-property.size': 'サイズ',
  'preset-property.treeType.any': 'その他',
  'preset-property.treeType.cArea': '施工箇所',
  'preset-property.treeType.material': '材料',
  'preset-property.unit.cubic_meter': '㎥',
  'preset-property.unit.kilowatt': 'kW',
  'preset-property.unit.m': 'm',
  'preset-property.unit.mm': 'mm',
  'preset-property.unit.model': '型',
  'preset-property.unit.packet': '式',
  'preset-property.unit.phi': 'Φ',
  'preset-property.unit.piece': '個',
  'preset-property.unit.square_meter': '㎡',
}
