import { Button, Menu, MenuDivider, MenuGroupHeader, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@aisekisan/bond'
import { ChevronCircleDown20Regular, Copy20Regular, Delete20Regular, ListBarTreeOffset20Regular, MoreHorizontal20Regular, Ruler20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import type { EquipmentAttributeTreeType, EquipmentClass } from '../../../type'
import type { EquipmentAttributeTreeNode } from '../../type'
import { getLatestID } from '../utils'
import { getDataShapeOptionsInitial } from '../../type'
import { getInsulThicknessInitial } from '../../insul-size/type'
import { getInsulOptionsInitial } from '../../insul/type'
import { EQUIP_CLASS } from '../../../equipment-class'
import { T } from '../../../libs/intl/t'

interface Props {
  root: EquipmentAttributeTreeNode
  node: EquipmentAttributeTreeNode
  equip: EquipmentClass
  treeType: EquipmentAttributeTreeType
  onChange: (node: EquipmentAttributeTreeNode) => void
  duplicate: () => void
  deleteNode: () => void
  selectNode: () => void
  readonly?: boolean
  isViewDependentProps: boolean
}

export function NodeMenu(props: Props): ReactElement {
  const { root, node, equip, treeType, onChange, duplicate, deleteNode, selectNode, readonly, isViewDependentProps } = props

  const addProperty = () => {
    node.children.push({ id: getLatestID(root) + 1, value: '', children: [] })
    onChange(root)
  }

  const addSizeProperty = () => {
    node.shapeOptions = getDataShapeOptionsInitial()
    onChange(root)
  }

  const addInsulSizeProperty = () => {
    node.shapeOptions = node.shapeOptions ?? getDataShapeOptionsInitial()
    node.shapeOptions.shape = 'InsulationThickness'
    node.shapeOptions.insulShapes = {
      ...node.shapeOptions.insulShapes,
      [EQUIP_CLASS.OTHER]: [getInsulThicknessInitial()],
    }
    onChange(root)
  }

  const addInsulationProperty = () => {
    node.insulationOptions = { ...node.insulationOptions, '-1': getInsulOptionsInitial() }
    onChange(root)
  }

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <Button icon={<MoreHorizontal20Regular />} disabled={readonly} />
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          <MenuItem icon={<Delete20Regular />} onClick={deleteNode}>
            <T id="preset-property.children.delete" />
          </MenuItem>
          <MenuItem icon={<Copy20Regular />} onClick={duplicate}>
            <T id="preset-property.property.duplicate" />
          </MenuItem>
          <MenuDivider />
          <MenuGroupHeader>
            <T id="preset-property.property.group" />
          </MenuGroupHeader>
          {isViewDependentProps && (
            <MenuItem
              icon={<ListBarTreeOffset20Regular />}
              onClick={selectNode}
            >
              <T id="preset-property.property.view" />
            </MenuItem>
          )}
          {treeType === 'material' && equip !== EQUIP_CLASS.INSULATION && (
            <MenuItem
              icon={<Ruler20Regular />}
              onClick={() => addSizeProperty()}
            >
              <T id="preset-property.property.menu.size" />
            </MenuItem>
          )}
          {treeType === 'material' && equip === EQUIP_CLASS.INSULATION && (
            <MenuItem
              icon={<Ruler20Regular />}
              onClick={() => addInsulSizeProperty()}
            >
              <T id="preset-property.property.menu.insul-size" />
            </MenuItem>
          )}
          {treeType === 'construction_area' && (
            <MenuItem
              icon={<Ruler20Regular />}
              onClick={() => addInsulationProperty()}
            >
              <T id="preset-property.property.menu.insul" />
            </MenuItem>
          )}
          <MenuItem
            icon={<ChevronCircleDown20Regular />}
            onClick={() => addProperty()}
          >
            <T id="preset-property.property.menu.children" />
          </MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
