import { Body1, Link, Text } from '@aisekisan/bond'
import { useEffect } from 'react'
import type { SetState } from '@aisekisan/bond'
import type { AccountInvitationDetail } from '@aisekisan/anya-api'
import { useAccountCheckInvitation, useAccountSignOut } from '@aisekisan/anya-api'
import { AccountLayout } from '../../layout'
import { useStyles } from '../../styles'
import { T } from '@/libs/intl/t'

interface Props {
  token: string
  setInvitation: SetState<AccountInvitationDetail | null>
}

export function SignUpAcceptCheck(props: Props): JSX.Element | null {
  const { token, setInvitation } = props

  const styles = useStyles()

  const checkMutation = useAccountCheckInvitation()
  const check = checkMutation.mutate

  const signOutMutation = useAccountSignOut()
  const signOut = signOutMutation.mutate

  useEffect(() => {
    // Run once strictly
    const timer = window.setTimeout(async () => {
      const onSuccess = (invitation: AccountInvitationDetail) => {
        setInvitation(invitation)
      }
      // Check after tried to sign out
      const onSettled = () => {
        check({ token }, { onSuccess })
      }
      signOut(undefined, { onSettled })
    }, 100)
    return () => void window.clearTimeout(timer)
  }, [token, setInvitation, check, signOut])

  const NavFooterNeedAssistance = (
    <div className={styles.navigateFlex}>
      <div>
        <Body1 className={styles.textBody}>
          <T id="reset-password.need-assistance" />
          {' '}
          <Link href="" inline>
            <T id="reset-password.contact-support" />
          </Link>
        </Body1>
      </div>
    </div>
  )
  if (checkMutation.isError) {
    return (
      <AccountLayout navigate={NavFooterNeedAssistance}>
        <div className={styles.card}>
          <div className={styles.flexCol}>
            <Text className={styles.title3}>
              <T id="sign-up.accept.error" />
            </Text>
            <Body1 className={styles.textBody}>
              <T id="sign-up.accept.error.help.1" />
            </Body1>
            <Body1 className={styles.textBody}>
              <T id="sign-up.accept.error.help.2" />
            </Body1>
          </div>
        </div>
      </AccountLayout>
    )
  }

  return null
}
