import type { MenuButtonProps } from '@aisekisan/bond'
import { Body1Strong, Divider, Link, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, SplitButton, makeStyles, shorthands, tokens } from '@aisekisan/bond'
import { Copy20Regular, Delete20Regular, MoreHorizontal20Regular } from '@fluentui/react-icons'
import type { EquipmentAttributeTreeType } from '../type'
import { T } from '../libs/intl/t'
import { getTreeType } from './tree/tree-type'

const useStyles = makeStyles({
  primaryButton: {
    width: 'calc(100% - 32px)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  menuButton: {
    width: '24px',
    height: '24px',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    rowGap: tokens.spacingHorizontalS,
    ...shorthands.margin(0, 0, tokens.spacingHorizontalS, 0),
  },
  link: {
    'color': tokens.colorNeutralForeground2,
    '&:hover': {
      ...shorthands.textDecoration('none'),
    },
  },
})

interface Props {
  id: string
  type: EquipmentAttributeTreeType
  href: string
  setDeleteProperty: (presetID: string) => void
  setCopyProperty: (presetID: string) => void
}

export function PropertyButton(props: Props): JSX.Element {
  const { id, type, setDeleteProperty, setCopyProperty, href } = props
  const treeType = getTreeType(type)

  const styles = useStyles()

  return (
    <Menu positioning="below-end" hasIcons>
      <MenuTrigger disableButtonEnhancement>
        {(triggerProps: MenuButtonProps) => (
          <div className={styles.buttonGroup}>
            <Divider appearance="strong" />
            <SplitButton
              menuButton={{ ...triggerProps, className: styles.menuButton }}
              appearance="transparent"
              menuIcon={<MoreHorizontal20Regular />}
              primaryActionButton={{ className: styles.primaryButton }}
              icon={treeType?.icon}
              size="small"
              key="primary-button"
              as="div"
            >
              <Link href={href}>
                <Body1Strong>
                  {treeType ? <T id={treeType.label} /> : type}
                </Body1Strong>
              </Link>
            </SplitButton>
          </div>
        )}
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          <MenuItem
            icon={<Delete20Regular />}
            onClick={() => setDeleteProperty(id)}
          >
            <T id="preset-property.action.delete" />
          </MenuItem>
          <MenuItem
            icon={<Copy20Regular />}
            onClick={() => setCopyProperty(id)}
          >
            <T id="preset-property.copy.equipment" />
          </MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
