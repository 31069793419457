import { useIntl } from '@aisekisan/bond'
import type { IntlDictId } from './dicts/type'

export type tFn = (id: IntlDictId) => string

export function useT() {
  const { dict } = useIntl()

  const t = (id: IntlDictId, params?: Record<string, string>) => {
    let result = dict[id] || id
    if (params) {
      result = Object.entries(params).reduce((t, [param, value]) => {
        return t.replaceAll(`{{.${param}}}`, value)
      }, result)
    }
    return result
  }

  return t
}
