import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { Market } from '../generated'
import { server } from '../server'

const getAllQueryKey = () => ['preset']

interface OrgID { orgID: string }

interface OrgPresetID { orgPresetID: string }

interface GlobalPresetID { globalPresetID: string }

function getOrgPresetList(params: OrgID) {
  return [
    ...getAllQueryKey(),
    'org',
    params.orgID,
  ]
}

function getOrgPresetDetail(params: OrgPresetID) {
  return [
    ...getAllQueryKey(),
    'detail',
    params.orgPresetID,
  ]
}

export function useOrgPresetList(params: {
  orgID: string
  market?: Market
}) {
  const { orgID, market } = params
  return useQuery({
    queryKey: getOrgPresetList({ orgID }),
    queryFn: () => server.listOrgPresets(orgID, market),
  })
}

type OrgPresetCreateBody = Parameters<typeof server.createOrgPreset>[1]

export function useOrgPresetCreate(params: { orgID: string }) {
  const { orgID } = params

  const client = useQueryClient()

  return useMutation({
    mutationFn: (body: OrgPresetCreateBody) =>
      server.createOrgPreset(orgID, body),
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getOrgPresetList({ orgID }),
      })
    },
  })
}

export function useOrgPresetDetail(params: { orgPresetID: string }) {
  const { orgPresetID } = params
  return useQuery({
    queryKey: getOrgPresetDetail({ orgPresetID }),
    queryFn: () => server.getOrgPreset(orgPresetID),
    enabled: orgPresetID !== '',
  })
}
export type OrgPresetUpdateBody = Parameters<typeof server.updateOrgPreset>[1]

export function useOrgPresetUpdate(params: { orgPresetID: string, orgID?: string }) {
  const { orgPresetID, orgID } = params
  const client = useQueryClient()

  return useMutation({
    mutationFn: (body: OrgPresetCreateBody) =>
      server.updateOrgPreset(orgPresetID, body),
    onSuccess: async () => {
      const queryKey = orgID ? getOrgPresetList({ orgID }) : getOrgPresetDetail({ orgPresetID })
      await client.invalidateQueries({
        queryKey,
      })
    },
  })
}

export function useOrgPresetDelete(params: OrgID) {
  const { orgID } = params
  const client = useQueryClient()

  return useMutation({
    mutationFn: (orgPresetId: string) =>
      server.deleteOrgPreset(orgPresetId),
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getOrgPresetList({ orgID }),
      })
    },
  })
}

export function useGlobalPresetDetail(params: GlobalPresetID) {
  const { globalPresetID } = params
  return useQuery({
    queryKey: ['global-preset', globalPresetID],
    queryFn: () => server.getGlobalPreset(globalPresetID),
  })
}
