import type { CategoryDetail, WithTreePath } from '@aisekisan/anya-api'

export enum ANNOTATION_TYPE {
  ALL = 'all',
  WITH_ANNOTATIONS = 'with-annotations',
  WITHOUT_ANNOTATIONS = 'without-annotations',
}

export function getCategoryPathLabel(path: number[]): string {
  return path.map(index => index + 1).join('-')
}

export function getCategoryLabel(category: WithTreePath<CategoryDetail>): string {
  return `${category.value.name}`
}
