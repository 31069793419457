import { getDefaultColorTheme } from '@tldraw/tldraw'
import type { ReactElement } from 'react'
import { getAnnotDisplaySize } from '../../shape/base'
import type { SegmentFlatShape } from './shape'
import { getSegmentFlatSize } from './shape'
import { getLineShapeEdgeRelative } from '@/components/editor/editor/shape/util/line'

export function toSegmentFlatSvg(props: {
  flat: SegmentFlatShape
  original: ReactElement
  scale: number
}): ReactElement {
  const { original, scale, flat } = props

  const size = getSegmentFlatSize(flat)
  const display = getAnnotDisplaySize({ size, fraction: 2, scale })
  const colors = getDefaultColorTheme({ isDarkMode: false })
  const color = colors[flat.props.color]
  const center = getLineShapeEdgeRelative(flat).center

  return (
    <>
      {/* The original line in SVG */}
      {original}
      {/* Label */}
      <g>
        <rect
          x={`${center.x - 12}`}
          y={`${center.y - 4}`}
          fill="#fff"
          width="20px"
          height="10px"
        />
        <text
          fill={color.solid}
          x={`${center.x - 8}`}
          y={`${center.y + 4}`}
          fontFamily="Arial"
          fontSize="7px"
          strokeWidth="1px"
        >
          {display}
        </text>
      </g>
    </>
  )
}
