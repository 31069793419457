import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { server } from '../server'

interface OrgPresetID { orgPresetID: string }
interface ConstructionID { constructionID: string }
interface TreeID { treeID: string }

function getOrgEATList(params: OrgPresetID) {
  return [
    'attribute-tree',
    'org',
    params.orgPresetID,
  ]
}

function getEATList(params: ConstructionID) {
  return [
    'attribute-tree',
    'construction',
    params.constructionID,
  ]
}

function getEATDetail(params: TreeID) {
  return [
    'attribute-tree',
    'detail',
    params.treeID,
  ]
}

export function useEquipmentClasses() {
  return useQuery({
    queryKey: ['equipment-classes'],
    queryFn: () => server.listLocaledEquipmentClasses(),
  })
}

/** * Equipment attribute in org */

export function useEquipmentAttributeTreeInOrgList(params: OrgPresetID) {
  const { orgPresetID } = params
  return useQuery({
    queryFn: () => {
      return server.listEquipmentAttributeTreesInOrgPreset(orgPresetID)
    },
    queryKey: getOrgEATList({ orgPresetID }),
  })
}

export function useEquipmentAttributeTreeInOrgDetail(params: TreeID) {
  const { treeID } = params
  return useQuery({
    queryFn: () => {
      return server.getEquipmentAttributeTreeInOrgPreset(treeID)
    },
    queryKey: getEATDetail({ treeID }),
  })
}

export function useEquipmentAttributeTreeOrgPresetDelete(params: OrgPresetID) {
  const client = useQueryClient()
  const { orgPresetID } = params

  return useMutation({
    mutationFn: (requestBody: TreeID) => {
      const { treeID } = requestBody

      return server.deleteEquipmentAttributeTreeInOrgPreset(treeID)
    },
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getOrgEATList({ orgPresetID }),
      })
    },
  })
}

type EquipmentAttributeTreeInOrgPresetUpdate = Parameters<
  typeof server.updateEquipmentAttributeTreeInOrgPreset
>[1]

export function useEquipmentAttributeTreeInOrgPresetUpdate(params: TreeID) {
  const { treeID } = params
  const client = useQueryClient()

  return useMutation({
    mutationFn: (body: EquipmentAttributeTreeInOrgPresetUpdate) => {
      return server.updateEquipmentAttributeTreeInOrgPreset(treeID, body)
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: getEATDetail({ treeID }) })
    },
  })
}

type EquipmentAttributeTreeInOrgPresetCreate = Parameters<
  typeof server.createEquipmentAttributeTreeInOrgPreset
>[1]

export function useEquipmentAttributeTreeInOrgPresetCreate(params: OrgPresetID) {
  const { orgPresetID } = params
  const client = useQueryClient()

  return useMutation({
    mutationFn: (body: EquipmentAttributeTreeInOrgPresetCreate) => {
      return server.createEquipmentAttributeTreeInOrgPreset(orgPresetID, body)
    },
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getOrgEATList({ orgPresetID }),
      })
    },
  })
}

export function useEquipmentAttributeTreeDelete(params: ConstructionID) {
  const client = useQueryClient()
  const { constructionID } = params

  return useMutation({
    mutationFn: (requestBody: TreeID) => {
      const { treeID } = requestBody

      return server.deleteEquipmentAttributeTree(treeID)
    },
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getEATList({ constructionID }),
      })
    },
  })
}

/** * Equipment attribute in construction */
export function useEquipmentAttributeTrees(params: ConstructionID) {
  const { constructionID } = params
  return useQuery({
    queryFn: () => {
      return server.listEquipmentAttributeTrees(constructionID)
    },
    queryKey: getEATList({ constructionID }),
    enabled: constructionID !== '',
  })
}

export type EquipmentAttributeTreeCreate = Parameters<
  typeof server.createEquipmentAttributeTree
>[0]

export function useEquipmentAttributeTreeCreate(params: ConstructionID) {
  const { constructionID } = params
  const client = useQueryClient()

  return useMutation({
    mutationFn: (body: EquipmentAttributeTreeCreate) => {
      return server.createEquipmentAttributeTree(body)
    },
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getEATList({ constructionID }),
      })
    },
  })
}

export function useEquipmentAttributeTreeDetail(params: TreeID) {
  const { treeID } = params
  return useQuery({
    queryFn: () => {
      return server.getEquipmentAttributeTree(treeID)
    },
    queryKey: getEATDetail({ treeID }),
  })
}

type EquipmentAttributeTreeUpdate = Parameters<
  typeof server.updateEquipmentAttributeTree
>[1]

export function useEquipmentAttributeTreeUpdate(params: TreeID) {
  const { treeID } = params
  const client = useQueryClient()

  return useMutation({
    mutationFn: (body: EquipmentAttributeTreeUpdate) => {
      return server.updateEquipmentAttributeTree(treeID, body)
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: getEATDetail({ treeID }) })
    },
  })
}
