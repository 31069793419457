import { IntlProvider as Provider } from '@aisekisan/bond'
import type { ReactNode } from 'react'
import { PRESET_PROPERTY_DICT } from '@aisekisan/preset-property'
import { INTL_DICT_EN } from '@/libs/intl/dicts/en'
import { INTL_DICT_JA } from '@/libs/intl/dicts/ja'

export function IntlProvider(props: { children: ReactNode }): JSX.Element {
  const { children } = props

  return (
    <Provider
      translations={[
        { lang: 'en', dict: { ...INTL_DICT_EN, ...PRESET_PROPERTY_DICT.EN } },
        { lang: 'ja', dict: { ...INTL_DICT_JA, ...PRESET_PROPERTY_DICT.JA } },
      ]}
    >
      {children}
    </Provider>
  )
}
