import type { EquipmentAttributeTreeNodeServer } from '../type'
import type { CustomInsulOptions } from './insul/type'
import type { CustomShapeOptions } from './size/type'
import { getCustomShapeOptionsInitial } from './size/type'
import type { CustomInsulThicknesses } from './insul-size/type'

export type EquipmentAttributeTreeNode =
  Omit<EquipmentAttributeTreeNodeServer, 'children' | 'shapeOptions' | 'insulationOptions'> & {
    children: Array<EquipmentAttributeTreeNode>
    shapeOptions?: CustomShapeOptions & { insulShapes: CustomInsulThicknesses }
    insulationOptions?: CustomInsulOptions
  }

export interface SelectedNode {
  node: EquipmentAttributeTreeNode
  level: number
}

export function getDataShapeOptionsInitial(): CustomShapeOptions & { insulShapes: CustomInsulThicknesses } {
  return {
    ...getCustomShapeOptionsInitial(),
    insulShapes: {},
  }
}
