import { Body1, Title3, makeStyles, mergeClasses, shorthands } from '@aisekisan/bond'
import type { CategoryDetail, ConstructionDetail } from '@aisekisan/anya-api'
import type { ReportHead } from '../type'
import { T } from '@/libs/intl/t'
import { useFormatDate } from '@/utils/date'

const useStyles = makeStyles({
  default: {
    ...shorthands.border('none'),
    ...shorthands.padding('5px'),
  },
  p0: {
    ...shorthands.padding('0'),
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
    paddingRight: '8px',
  },
})

interface Props {
  category: CategoryDetail
  construction: ConstructionDetail
  reportHeads: ReportHead[]
}

export function DefaultReportOverview(props: Props) {
  const { category, construction, reportHeads } = props

  const modules = reportHeads.flatMap(({ modules }) => modules)

  const s = useStyles()
  const formatDate = useFormatDate('short-none')

  return (
    <>
      <tr>
        <td className={s.default}>
          <Body1>
            <T id="report.field.construction_code" />
            <span>:</span>
          </Body1>
        </td>
        <td className={s.default}>
          <Body1>
            {construction.code}
          </Body1>
        </td>
      </tr>

      <tr>
        <td className={s.default}>
          <Body1>
            <T id="report.field.construction_name" />
            <span>:</span>
          </Body1>
        </td>
        <td className={s.default}>
          <Body1>{construction.name}</Body1>
        </td>
        <td
          className={mergeClasses(s.default, s.center)}
          colSpan={2 + modules.length}
        >
          <Title3>
            <T id="report.title.summary" />
          </Title3>
        </td>
        <td
          className={mergeClasses(s.default, s.p0, s.right)}
          colSpan={2}
        >
          <Body1>
            <T id="report.field.date" />
            <span>:</span>
          </Body1>
        </td>
        <td className={mergeClasses(s.default, s.p0)}>
          <Body1>
            {formatDate(new Date().toISOString())}
          </Body1>
        </td>
      </tr>

      <tr>
        <td className={s.default}>
          <Body1>
            <T id="report.field.category" />
            <span>:</span>
          </Body1>
        </td>
        <td className={s.default}>
          <Body1>
            {category.name}
          </Body1>
        </td>
        <td
          className={s.default}
          colSpan={2 + modules.length}
        />
        <td
          className={mergeClasses(s.default, s.p0, s.right)}
          colSpan={2}
        >
          <Body1>
            <T id="report.field.page" />
            <span>:</span>
          </Body1>
        </td>
        <td className={mergeClasses(s.default, s.p0)}>
          <Body1>
            1 / 1
          </Body1>
        </td>
      </tr>
    </>
  )
}
