import type { BoxShapes, Circles, CustomShapes, ElectricPowers, Rectangles, RefrigerantPipeShapes } from '../../type'
import type { CustomShapeOptions, DataShapes } from './type'

export function updateDataShape(shapeOptions: CustomShapeOptions, shapes: DataShapes): CustomShapeOptions {
  const updatedShapeOptions = { ...shapeOptions }

  switch (shapeOptions.shape) {
    case 'Rectangle':
      updatedShapeOptions.rectangles = shapes as Rectangles
      break
    case 'Circle':
      updatedShapeOptions.circles = shapes as Circles
      break
    case 'BoxShape':
      updatedShapeOptions.boxShapes = shapes as BoxShapes
      break
    case 'CustomShape':
      updatedShapeOptions.customShapes = shapes as CustomShapes
      break
    case 'ElectricPower':
      updatedShapeOptions.electricPowers = shapes as ElectricPowers
      break
    case 'RefrigerantPipeShape':
      updatedShapeOptions.refrigerantPipeShapes = shapes as RefrigerantPipeShapes
      break
  }

  return updatedShapeOptions
}

export function addDataShape(shapeOptions: CustomShapeOptions): CustomShapeOptions {
  const updatedShapeOptions = { ...shapeOptions }

  switch (shapeOptions.shape) {
    case 'Rectangle':
      updatedShapeOptions.rectangles.push({
        width: 0,
        length: 0,
      })
      break
    case 'Circle':
      updatedShapeOptions.circles.push({
        diameter: 0,
      })
      break
    case 'BoxShape':
      updatedShapeOptions.boxShapes.push({
        width: 0,
        length: 0,
        height: 0,
      })
      break
    case 'RefrigerantPipeShape':
      updatedShapeOptions.refrigerantPipeShapes.push({
        liquidPipeDiameter: 0,
        suctionPipeDiameter: 0,
      })
      break
    case 'ElectricPower':
      updatedShapeOptions.electricPowers.push({
        kilowatt: 0,
      })
      break
    case 'CustomShape':
      updatedShapeOptions.customShapes.push({
        size: '',
      })
      break
  }

  return updatedShapeOptions
}
