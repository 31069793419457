import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  OverlaySpinner,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { parseServerError, useOrgMemberRemove } from '@aisekisan/anya-api'
import type { OrgMemberDetail } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalL,
  },
})

interface Props {
  orgID: string
  member: OrgMemberDetail
  close: () => void
}

export function OrgMemberRemove(props: Props): JSX.Element {
  const { orgID, close, member } = props

  const t = useT()
  const styles = useStyles()

  const remove = useOrgMemberRemove({ orgID })

  return (
    <Dialog open={!!orgID} onOpenChange={close}>
      <DialogSurface>
        <DialogBody>
          <OverlaySpinner visible={remove.isPending} appearance="primary" />
          <DialogTitle action={(
            <DialogTrigger action="close">
              <Button
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
                onClick={close}
              />
            </DialogTrigger>
          )}
          >
            <T id="org.member.remove.title" />
          </DialogTitle>
          <DialogContent>
            {remove.isError
              ? (
                <ErrorAlert
                  title={t('org.member.remove.error.title')}
                  help={<T id="all.error.retry" />}
                  detail={parseServerError(remove.error)}
                  onClose={() => remove.reset()}
                />
                )
              : null}
            <div className={styles.content}>
              <Body1>
                <T id="org.member.remove.helper {{.email}}" params={{ email: member.email }} />
              </Body1>
              <Body1><T id="org.member.remove.helper.2" /></Body1>
              <Body1><T id="org.member.remove.helper.3" /></Body1>
            </div>

          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              onClick={() => {
                remove.mutate({ accountID: member.id }, { onSuccess: close })
              }}
            >
              <T id="org.member.remove.submit" />
            </Button>
            <Button onClick={close}>
              <T id="org.member.remove.cancel" />
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
