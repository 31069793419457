import type { ReactElement } from 'react'
import { DrainPipe } from './icons/DrainPipe'
import type { EquipmentClass } from './type'

export const EQUIP_CLASS = {
  OTHER: 0,
  ROUND_DUCT: 1,
  VENTCAP: 2,
  INSULATION: 3,
  REFRIGERANT_PIPE: 4,
  DRAIN_PIPE: 5,
  RECTANGLE_DUCT: 6,
} as const satisfies Record<string, EquipmentClass>

interface EquipmentIcon {
  equipmentName: 'Duct' | 'Ventcap' | 'Other' | 'Drain pipe' | 'Refrigerant pipe'
  icon: ReactElement
}

const EQUIPMENT_ICONS: EquipmentIcon[] = [
  {
    equipmentName: 'Duct',
    icon: <DrainPipe />,
  },
  {
    equipmentName: 'Ventcap',
    icon: <DrainPipe />,
  },
]

export function getEquipmentIcon(name: string) {
  return EQUIPMENT_ICONS.find(({ equipmentName }) => equipmentName === name)?.icon
}
