import { type ReactElement, useRef } from 'react'
import type { CategoryDetail, ConstructionDetail } from '@aisekisan/anya-api'
import { makeStyles } from '@aisekisan/bond'
import { ReportExport } from '../export/button'
import type { ReportHead, ReportRow } from '../type'
import { downloadReport, getRef } from '../export/download'
import { DefaultReportOverview } from './overview'
import { DefaultReportHeader } from './header'
import { DefaultReportBody } from './body'

const useStyles = makeStyles({
  hidden: {
    display: 'none',
  },
})

interface Props {
  construction: ConstructionDetail
  category: CategoryDetail
  reportHeads: ReportHead[]
  reportRows: ReportRow[]
}

export function DefaultReportExport(props: Props): ReactElement {
  const { construction, category, reportHeads, reportRows } = props

  const s = useStyles()
  const tableRef = useRef<HTMLTableElement>(null)

  return (
    <>
      <ReportExport
        download={(format) => {
          downloadReport({
            table: getRef(tableRef),
            name: `${construction.name}-${category.name}`,
            format,
          })
        }}
      />
      <div className={s.hidden}>
        <table ref={tableRef}>
          <tbody>
            <DefaultReportOverview
              category={category}
              construction={construction}
              reportHeads={reportHeads}
            />
            <DefaultReportHeader
              reportHeads={reportHeads}
            />
            <DefaultReportBody
              reportHeads={reportHeads}
              reportRows={reportRows}
            />
          </tbody>
        </table>
      </div>
    </>
  )
}
