import type { Editor } from '@tldraw/tldraw'
import { getSvgAsImage } from '@tldraw/tldraw'
import { getDocument } from 'pdfjs-dist'
import type { Dispatch, SetStateAction } from 'react'

import type { PdfImage } from '@/components/category/page/export/utils'
import { getStrict } from '@/utils/primitive'

interface Params {
  editor: Editor
  canvas: HTMLCanvasElement
  url: string
  setPdfImages: Dispatch<SetStateAction<PdfImage[]>>
}

export async function exportImage(params: Params): Promise<void> {
  const { editor, canvas, url, setPdfImages } = params

  const pdf = await getDocument({ url }).promise
  const page = await pdf.getPage(1)
  const viewport = page.getViewport({ scale: 8 })

  const newCanvas = document.createElement('canvas')

  const canvasContext = newCanvas.getContext('2d')
  if (!canvasContext)
    throw new Error('Incorrect canvas context')

  newCanvas.height = viewport.height
  newCanvas.width = viewport.width

  const direction = viewport.width > viewport.height ? 'landscape' : 'portrait'

  drawBackground({ main: newCanvas, scale: 4, canvas })
  if (editor.getCurrentPageShapeIds().size > 0)
    await drawAnnots({ editor, main: newCanvas, scale: 4 })

  const image = newCanvas.toDataURL('image/png')
  setPdfImages(prev => [...prev, { image, direction }])
}

function drawBackground(params: {
  main: HTMLCanvasElement
  scale: number
  canvas: HTMLCanvasElement
}): void {
  const { main, scale, canvas } = params

  const { width: sw, height: sh } = canvas
  const [dw, dh] = [sw / 8 * scale, sh / 8 * scale];
  [main.width, main.height] = [dw, dh]

  const context = getStrict(main.getContext('2d'))
  context.drawImage(canvas, 0, 0, sw, sh, 0, 0, dw, dh)
}

async function drawAnnots(params: {
  main: HTMLCanvasElement
  editor: Editor
  scale: number
}): Promise<void> {
  const { editor, main, scale } = params

  const bounds = editor.getCurrentPageBounds()

  // If there are no annotations, return early to continue printing background
  if (typeof bounds === 'undefined')
    return

  const svg = await editor.getSvgString(
    [...editor.getCurrentPageShapeIds()],
    { scale, padding: 0, background: false },
  )

  // If there are no annotations, svg is returned 'undefined'.
  if (typeof svg === 'undefined')
    return

  const blob = await getSvgAsImage(
    getStrict(svg.svg),
    false, // isSafari
    { quality: 1, scale: 1, type: 'png', width: svg.width, height: svg.height },
  )
  const bitmap = await createImageBitmap(getStrict(blob))

  const context = getStrict(main.getContext('2d'))
  context.drawImage(bitmap, bounds.x * scale, bounds.y * scale)
}
