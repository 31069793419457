import type { TLShape } from '@tldraw/tldraw'
import type { VerticalDirection } from '@aisekisan/anya-api'

export interface AnnotMetaBase {
  type: 'annot'
  group: string
}

export type AnnotShapeBase = TLShape & {
  meta: AnnotMetaBase
}

export function isAnnotShapeBase(
  shape: TLShape,
): shape is AnnotShapeBase {
  const test = shape as AnnotShapeBase
  return test.meta.type === 'annot'
}

export function createAnnotMetaBase(props: {
  group: string
}): AnnotMetaBase {
  const { group } = props
  return { type: 'annot', group }
}

export interface AnnotSize {
  unit: 'px' | 'mm' | 'piece'
  value: number
  direction: VerticalDirection | null | 'extension'
}

export function getAnnotDisplaySize(props: {
  size: AnnotSize
  scale: number
  fraction: number
}): string {
  const { scale, size, fraction } = props

  const prefix = (() => {
    switch (size.direction) {
      case null: return ''
      case 'up': return '△'
      case 'down': return '▽'
    }
  })()

  const value = (() => {
    switch (size.unit) {
      case 'piece':
        return size.value.toFixed(0)
      case 'mm': {
        const m = size.value / 1000
        const display = m.toFixed(fraction)
        return display
      }
      case 'px': {
        const mm = size.value * scale
        const m = mm / 1000
        const display = m.toFixed(fraction)
        return display
      }
    }
  })()

  return `${prefix}${value}`
}
