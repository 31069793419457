import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Input, makeStyles, shorthands, tokens } from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { parseServerError, useConstructionModuleCreate } from '@aisekisan/anya-api'
import type { ConstructionModuleCreateBody } from '@aisekisan/anya-api'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  container: {
    minWidth: '100%',
  },
  body: {
    width: '30%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap(0, '10px'),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
})

export function CreateCModule(props: { constructionID: string, close: () => void }): JSX.Element {
  const { constructionID, close } = props

  const t = useT()
  const styles = useStyles()

  const schema = z.object({
    name: z.string().min(1, { message: t('form.error.required_field') }),
    quantity: z.number({ invalid_type_error: t('form.error.required_field'), required_error: t('form.error.required_field') }).nonnegative(),
  })

  const form = useForm<ConstructionModuleCreateBody>({
    defaultValues: { name: '', quantity: 1 },
    resolver: zodResolver(schema),
  })

  const { formState: { errors } } = form

  const create = useConstructionModuleCreate({ constructionID })

  return (
    <Dialog open onOpenChange={close}>
      <DialogSurface className={styles.body}>
        <form
          className={styles.form}
          onSubmit={form.handleSubmit((body) => {
            create.mutate(body, { onSuccess: close })
          })}
        >
          <DialogBody>
            <DialogTitle
              action={(
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    onClick={close}
                  />
                </DialogTrigger>
              )}
            >
              <T id="annotation.module.create.title" />
            </DialogTitle>
            <DialogContent className={styles.content}>
              {create.isError
                ? (
                  <ErrorAlert
                    title={t('construction.module.create.error')}
                    help={t('all.error.review')}
                    detail={parseServerError(create.error)}
                    onClose={() => create.reset()}
                  />
                  )
                : null}

              <Field
                label={<T id="construction.module.field.name" />}
                validationState={errors.name ? 'error' : 'none'}
                validationMessage={errors.name?.message}
              >
                <Input
                  defaultValue={form.getValues('name')}
                  {...form.register('name')}
                  size="large"
                  appearance="filled-darker"
                />
              </Field>

              <Field
                label={<T id="construction.module.field.quantity" />}
                validationState={errors.quantity ? 'error' : 'none'}
                validationMessage={errors.quantity?.message}
              >
                <Input
                  defaultValue={form.getValues('quantity')?.toString()}
                  {...form.register('quantity', {
                    valueAsNumber: true,
                  })}
                  type="number"
                  appearance="filled-darker"
                  size="large"
                />
              </Field>
            </DialogContent>
            <DialogActions>
              <Button
                {...{ fullWidth: true, large: true, type: 'submit' }}
                disabled={create.isPending}
                appearance="primary"
              >
                <T id="construction.module.create.submit" />
              </Button>
              <Button onClick={close}>
                <T id="dialog.action.cancel" />
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>

  )
}
