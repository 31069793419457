import type { IntlDictId } from '../../libs/intl/dicts/type'
import type { ShapeType, Unit } from '../../type'

interface ShapeOption {
  id: ShapeType
  label: IntlDictId
}

export const SHAPES: ShapeOption[] = [
  {
    label: 'preset-property.shape.custom',
    id: 'CustomShape',
  },
  {
    label: 'preset-property.shape.rectangle',
    id: 'Rectangle',
  },
  {
    label: 'preset-property.shape.circle',
    id: 'Circle',
  },
  {
    label: 'preset-property.shape.refrigerant-pipe',
    id: 'RefrigerantPipeShape',
  },
  {
    label: 'preset-property.shape.electric-power',
    id: 'ElectricPower',
  },
  {
    label: 'preset-property.shape.box',
    id: 'BoxShape',
  },
]

interface UnitOption {
  value: Unit
  label: IntlDictId
}

export const UNIT: UnitOption[] = [
  {
    label: 'preset-property.unit.mm',
    value: 'mm',
  },
  {
    label: 'preset-property.unit.m',
    value: 'm',
  },
  {
    label: 'preset-property.unit.square_meter',
    value: 'square_meter',
  },
  {
    label: 'preset-property.unit.cubic_meter',
    value: 'cubic_meter',
  },
  {
    label: 'preset-property.unit.phi',
    value: 'phi',
  },
  {
    label: 'preset-property.unit.kilowatt',
    value: 'kilowatt',
  },
  {
    label: 'preset-property.unit.model',
    value: 'model',
  },
  {
    label: 'preset-property.unit.piece',
    value: 'piece',
  },
  {
    label: 'preset-property.unit.packet',
    value: 'packet',
  },
]
