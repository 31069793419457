export const FIRE_REPORT_FIXED_COLS = 13

export const FIRE_REPORT_FIXED_ROWS = 30

export function getFireReportEmptyCols(cols: number) {
  if (cols <= 0)
    return null

  return Array.from(
    { length: cols },
    (_, index) => <th key={index}></th>,
  )
}

export function getFireReportEmptyRows(rows: number, cols: number) {
  if (rows <= 0)
    return null

  return Array.from(
    { length: rows },
    (_, index) => <tr key={index}>{getFireReportEmptyCols(cols)}</tr>,
  )
}
