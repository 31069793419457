import type { TLHandle, TLLineShape } from '@tldraw/tldraw'
import { Edge2d, LineShapeUtil as TLLineShapeUtil, Vec, sortByIndex } from '@tldraw/tldraw'
import type { ReactElement } from 'react'
import { documentMetaSchema } from '../../document/type'
import { isSegmentFlatShape } from '@/components/editor/annot/segment/flat/shape'
import { toSegmentFlatSvg } from '@/components/editor/annot/segment/flat/svg'

const PAGE_SCALE_DEFAULT = 0
export class LineShapeUtil extends TLLineShapeUtil {
  /**
   * Remove the "create from middle" behaviour. There's no shape that we need
   * this behaviour.
   */
  override getHandles(shape: TLLineShape): TLHandle[] {
    const prev = super.getHandles(shape)
    const next = prev.filter(handle => handle.type !== 'create')
    return next
  }

  override toSvg(shape: TLLineShape): ReactElement {
    const original = super.toSvg(shape)

    if (isSegmentFlatShape(shape)) {
      const raw = this.editor.getDocumentSettings().meta
      const meta = documentMetaSchema.parse(raw)
      // @TODO: Throw error if scale is not ready? Or maybe better scale should
      // always be defined.
      const scale = meta.scale ?? PAGE_SCALE_DEFAULT
      return toSegmentFlatSvg({ flat: shape, original, scale })
    }

    return original
  }
}

/**
 * In tldraw, LineShape is actually more like poly-line as they can have more
 * than 2 handles. However, in our app, most "line" shapes are edges with only
 * 2 handles (see ScaleShape and SegmentShape).
 *
 * This is a convenient utility that is similar to LineShapeUtils' getGeometry
 * but instead of a polyline, we return an edge (from the first 2 handles).
 * For practical reasons, we throw an error if there's more than 2 handles,
 * because it's likely we don't support polyline LineShape in the near future.
 *
 * This returns relative coordinates, as TLLineShape's handles are positioned
 * relative to the shape's "x" and "y".
 */
export function getLineShapeEdgeRelative(shape: TLLineShape): Edge2d {
  const handles = Object.values(shape.props.points).toSorted(sortByIndex)
  const [start, end] = [handles.at(0), handles.at(1)]
  if (handles.length !== 2 || !start || !end)
    throw new Error('Line must have exactly 2 handles.')

  const edge = new Edge2d({
    start: Vec.From(start),
    end: Vec.From(end),
  })
  return edge
}
