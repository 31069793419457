import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { server } from '../server'
import { getReportAnnotationKey } from './annotation'

interface CategoryID { categoryID: string }
interface PageID { pageID: string }

const getAllQueryKey = () => ['page']

function getListPageKey(params: CategoryID) {
  return [
    ...getAllQueryKey(),
    'list',
    params.categoryID,
  ]
}
function getDetailQueryKey(params: PageID) {
  return [
    ...getAllQueryKey(),
    'detail',
    params.pageID,
  ]
}

export function useListPages(params: CategoryID) {
  const { categoryID } = params

  // Heads up: Don't sort pages here because it will break when adding before/after a specific page.
  return useQuery({
    queryFn: () => server.listPages(categoryID, 0, 200),
    queryKey: getListPageKey({ categoryID }),
  })
}

type PageInsertBody = Omit<
  Parameters<typeof server.insertPages>[0],
  'categoryID'
>

export function usePageInsert(params: CategoryID) {
  const { categoryID } = params
  const client = useQueryClient()

  return useMutation({
    mutationFn: async (body: PageInsertBody) => {
      return server.insertPages({ categoryID, ...body })
    },
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getListPageKey({ categoryID }),
      })
    },
  })
};

export type PageUpdateParams = Parameters<typeof server.updatePage>[1]

export function usePageUpdate(params: PageID) {
  const { pageID } = params

  const client = useQueryClient()

  const mutation = useMutation({
    mutationFn: (body: PageUpdateParams) => {
      return server.updatePage(pageID, body)
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: getAllQueryKey() })
    },
  })

  return mutation
};

export function usePageClone() {
  const client = useQueryClient()

  const mutation = useMutation({
    mutationFn: (pageID: string) => {
      return server.clonePage(pageID)
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: getAllQueryKey() })
    },
  })

  return mutation
}

type PageReplaceBody = Parameters<typeof server.replacePage>[1]

export function usePageReplace(params: PageID) {
  const { pageID } = params
  const client = useQueryClient()

  return useMutation({
    mutationFn: async (body: PageReplaceBody) => {
      return server.replacePage(pageID, body)
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: getAllQueryKey() })
    },
  })
}

export function usePageDelete(params: { categoryID: string, constructionID?: string }) {
  const { categoryID, constructionID } = params
  const client = useQueryClient()

  return useMutation({
    mutationFn: async (pageIDs: string[]) => {
      return server.deletePages({ categoryID, pageIDs })
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: getAllQueryKey() })
      if (constructionID)
        await client.invalidateQueries({ queryKey: getReportAnnotationKey({ constructionID }) })
    },
  })
}

export function usePageDetail(params: PageID) {
  const { pageID } = params
  return useQuery({
    queryFn: () => server.getPageDetail(pageID),
    queryKey: getDetailQueryKey({ pageID }),
    // Google's signature for file_url
    // @todo: Ask BE to provide dedicated endpoint for page.url?
    enabled: !!params.pageID,
  })
}
