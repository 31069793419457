import { z } from 'zod'

interface PaperSize {
  width: number
  height: number
}

const paperNameSchema = z.enum(['A0', 'A1', 'A2', 'A3', 'A4'])

type PaperName = z.infer<typeof paperNameSchema>

/**
 * PaperSize is in pixels. Sizes are defined in portrait, so width is always
 * smaller than height. Note that PDF pages may be in landscape mode.
 */
export const PAPER_DETAILS: Record<PaperName, PaperSize> = {
  A0: { width: 2383, height: 3370 },
  A1: { width: 1683, height: 2383 },
  A2: { width: 1190, height: 1683 },
  A3: { width: 841, height: 1190 },
  A4: { width: 595, height: 841 },
}

export type DIRECTION = 'landscape' | 'portrait'

type State =
  | { mode: 'idle' }
  | { mode: 'generating' }
  | { mode: 'generated', pdfPage: number }

export type PdfRenderState = State

export interface PdfRenderError { failedCount: number, messages: string[] }
export const DEFAULT_PDF_RENDER_ERROR: PdfRenderError = { failedCount: 0, messages: [] }

export interface PdfImage { image: string, direction: DIRECTION }
