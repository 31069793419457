import type { EquipmentAttributeTreeInOrgPreset } from '@aisekisan/anya-api'
import {
  parseServerError,
  useEquipmentAttributeTreeInOrgList,
  useEquipmentAttributeTreeInOrgPresetUpdate,
  useEquipmentClasses,
} from '@aisekisan/anya-api'
import {
  Button,
  Title3,
  makeStyles,
  shorthands,
  tokens,
  useIntl,
} from '@aisekisan/bond'
import type { PropertyFormBody } from '@aisekisan/preset-property'
import {
  PropertyForm,
  parseTreeNode,
  serializeTreeNode,
} from '@aisekisan/preset-property'
import {
  Copy20Regular,
  Delete20Regular,
  Save20Regular,
} from '@fluentui/react-icons'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { CopyOrgProperty } from './copy'
import { DeleteOrgProperty } from './delete'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'
import { useToast } from '@/hooks/use-toast'
import { EQUIP_CLASS } from '@/components/equipment-class'

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    rowGap: tokens.spacingVerticalL,
    columnGap: tokens.spacingHorizontalL,
    boxSizing: 'border-box',
    flexWrap: 'wrap',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalL,
    ...shorthands.margin(0),
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
    columnGap: tokens.spacingHorizontalL,
    overflowX: 'auto',
    boxSizing: 'border-box',
  },
})

export function OrgEquipmentPropertyDetail(props: {
  equipmentAttributeTree: EquipmentAttributeTreeInOrgPreset
  orgPresetID: string
}) {
  const { equipmentAttributeTree, orgPresetID } = props

  const t = useT()
  const styles = useStyles()
  const { lang } = useIntl()

  const toast = useToast({
    title: `${t('org.property.update-successfully')}`,
    intent: 'success',
  })

  const [deleteProperty, setDeleteProperty] = useState<boolean>(false)
  const [copyProperty, setCopyProperty] = useState<boolean>(false)

  const form = useForm<PropertyFormBody>({
    defaultValues: {
      equipmentClass: equipmentAttributeTree.equipmentClass,
      equipmentClassOther: equipmentAttributeTree.equipmentClassOther,
      tree: parseTreeNode(equipmentAttributeTree.tree),
      treeType: equipmentAttributeTree.treeType,
    },
  })

  const { data } = useEquipmentClasses()
  const equipmentClasses = (data ?? [])
    .filter(equipmentClass => equipmentClass.locale === lang)
    .map(({ equipmentClass, localed }) => {
      return { equipmentClass, name: localed }
    })

  const { data: trees } = useEquipmentAttributeTreeInOrgList({ orgPresetID })
  const insulTree = trees?.find((tree) => {
    return tree.equipmentClass === EQUIP_CLASS.INSULATION && tree.treeType === 'material'
  })?.tree

  const update = useEquipmentAttributeTreeInOrgPresetUpdate({
    treeID: equipmentAttributeTree.id,
  })

  const onSave = form.handleSubmit((values) => {
    const transformedTree = serializeTreeNode(values.tree)
    update.mutate(transformedTree, { onSuccess: () => toast.notify() })
  })

  return (
    <div className={styles.wrapper}>
      {toast.toast}
      <div className={styles.controls}>
        <Title3>
          <T id="preset.equipment.property.label" />
        </Title3>
        <Button appearance="primary" icon={<Save20Regular />} onClick={onSave}>
          <T id="preset.equipment.property.tree.save" />
        </Button>
        <Button icon={<Copy20Regular />} onClick={() => setCopyProperty(true)}>
          <T id="preset.equipment.property.tree.copy" />
        </Button>
        <Button icon={<Delete20Regular />} onClick={() => setDeleteProperty(true)}>
          <T id="preset.equipment.property.tree.delete" />
        </Button>
      </div>

      <PropertyForm
        equipmentClasses={equipmentClasses}
        form={form}
        insulTree={insulTree ? parseTreeNode(insulTree) : null}
      />

      {deleteProperty && (
        <DeleteOrgProperty
          property={equipmentAttributeTree}
          close={() => setDeleteProperty(false)}
        />
      )}

      {copyProperty && (
        <CopyOrgProperty
          {...form.getValues()}
          orgPresetID={orgPresetID}
          close={() => setCopyProperty(false)}
        />
      )}

      {update.isError && (
        <ErrorAlert
          title={t('preset.equipment.property.update.form.error.title')}
          help={t('all.error.review')}
          detail={parseServerError(update.error)}
          onClose={() => update.reset()}
        />
      )}
    </div>
  )
}
