import { makeStyles, tokens } from '@aisekisan/bond'
import { useConstructionDetail } from '@aisekisan/anya-api'
import { AppPage } from '@/AppPage'
import { useT } from '@/libs/intl/useT'
import { CreateConstructionProperty } from '@/components/construction/property/create'

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
    columnGap: tokens.spacingHorizontalL,
    overflowX: 'auto',
    boxSizing: 'border-box',
  },
})

export function ConstructionPropertyCreate(props: { constructionID: string }): JSX.Element {
  const { constructionID } = props

  const t = useT()
  const styles = useStyles()

  const { data: construction } = useConstructionDetail({ constructionID })

  return (
    <AppPage
      breadcrumbs={[
        {
          title: `${t('construction.title')} ${construction?.name}`,
          href: `/construction/${constructionID}`,
        },
        {
          title: `Create property`,
          href: ``,
        },

      ]}
    >
      <div className={styles.wrapper}>
        <CreateConstructionProperty constructionID={constructionID} />
      </div>
    </AppPage>
  )
}
