import type { SVGProps } from 'react'

export function AISekisan(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group 2">
        <g id="Group 1">
          <path
            id="Vector"
            d="M13 6.99998C9.68629 6.99998 7 9.68628 7 13H13V6.99998Z"
            fill="#D0EBFF"
          />
          <path
            id="Vector_2"
            d="M13 1H7L1 13H7C7 9.68629 9.68629 7 13 7V1Z"
            fill="#208BE6"
          />
          <rect
            id="Rectangle 1"
            x="13"
            y="1"
            width="6"
            height="12"
            fill="#73C0FC"
          />
        </g>
        <path
          id="Vector_3"
          d="M7 1H19M7 1L1 13M7 1H13M19 1V13M19 1H13M19 13H1M19 13H7M19 13H13M1 13H7M13 1V13M13 1V7M13 13V7M7 13C7 9.68629 9.68629 7 13 7"
          stroke="black"
        />
      </g>
    </svg>
  )
}
