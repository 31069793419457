import { Button, Field, Input, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, makeStyles, tokens } from '@aisekisan/bond'
import { Copy20Regular, Delete20Regular, MoreHorizontal20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import type { Rectangle } from '../../../type'
import type { DataShapes } from '../type'
import { useT } from '../../../libs/intl/useT'
import { T } from '../../../libs/intl/t'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: tokens.spacingHorizontalS,
    flexWrap: 'wrap',
    alignItems: 'flex-end',
  },
  field: {
    width: '104px',
  },
})

interface Props {
  rectangles: Rectangle[]
  onUpdate: (value: DataShapes) => void
}

export function RectangleForm(props: Props): ReactElement {
  const { rectangles, onUpdate } = props

  const t = useT()
  const styles = useStyles()

  const onUpdateProp = (
    value: string,
    key: keyof Rectangle,
    rectangle: Rectangle,
  ) => {
    rectangle[key] = Number(value)
    onUpdate(rectangles)
  }

  const onRemoveProp = (index: number) => {
    rectangles.splice(index, 1)
    onUpdate(rectangles)
  }

  const onDuplicateProp = (rectangle: Rectangle) => {
    rectangles.push(rectangle)
    onUpdate(rectangles)
  }

  return (
    <div className={styles.wrapper}>
      {rectangles.map((rectangle, index) => (
        <div className={styles.item} key={index}>
          <Field label={t('preset-property.shape.rect.width')}>
            <Input
              type="number"
              value={rectangle.width ? rectangle.width.toString() : ''}
              onChange={event =>
                onUpdateProp(event.target.value, 'width', rectangle)}
              appearance="filled-darker"
              className={styles.field}
            />
          </Field>
          <Field label={t('preset-property.shape.rect.height')}>
            <Input
              type="number"
              value={rectangle.length ? rectangle.length.toString() : ''}
              onChange={event =>
                onUpdateProp(event.target.value, 'length', rectangle)}
              appearance="filled-darker"
              className={styles.field}
            />
          </Field>
          <div>
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <Button icon={<MoreHorizontal20Regular />} />
              </MenuTrigger>

              <MenuPopover>
                <MenuList>
                  <MenuItem
                    icon={<Delete20Regular />}
                    onClick={() => onRemoveProp(index)}
                  >
                    <T id="preset-property.shape.delete" />
                  </MenuItem>
                  <MenuItem
                    icon={<Copy20Regular />}
                    onClick={() => onDuplicateProp(rectangle)}
                  >
                    <T id="preset-property.shape.duplicate" />
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </div>
        </div>
      ))}
    </div>
  )
}
