import { useForm } from 'react-hook-form'
import { Body1, Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Input, OverlaySpinner, Radio, RadioGroup, makeStyles, tokens } from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { useState } from 'react'
import { parseServerError, usePageInsert } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'

interface PageInsertForm {
  file: File | null
}

interface AddPageProps {
  categoryID: string
  close: () => void
  beforePage?: string
  afterPage?: string
}

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
})

function extractPageNumber(arr: string[]): number[] {
  const regexSingle = /^\d+$/
  const regexRange = /^(\d+)-(\d+)$/
  const numbers = new Set<number>()

  for (const item of arr) {
    if (typeof item !== 'string')
      return []

    // Check for single numbers
    if (regexSingle.test(item)) {
      numbers.add(Number(item))
    }
    // Check for ranges
    else if (regexRange.test(item)) {
      const match = item.match(regexRange)
      if (match) {
        const start = Number(match[1])
        const end = Number(match[2])
        for (let i = start; i <= end; i++)
          numbers.add(i)
      }
    }
  }
  return Array.from(numbers)
}

export function AddPages(props: AddPageProps): JSX.Element {
  const { categoryID, close, beforePage, afterPage } = props

  const t = useT()
  const styles = useStyles()

  const [uploadType, setUploadType] = useState('allPages')
  const [specificPages, setSpecificPages] = useState<string>('')
  const [error, setError] = useState<{ error: boolean, message: string } | null>(null)

  const form = useForm<PageInsertForm>({
    defaultValues: {
      file: null,
    },
  })

  const insert = usePageInsert({ categoryID })

  const onSubmit = form.handleSubmit((values: PageInsertForm) => {
    const { file } = values

    if (file === null) {
      setError({ error: true, message: t('file.error.message') })
      throw new Error('file is null, validator fails')
    }

    if (error?.error)
      setError(null)

    const fileInput = Object.values(file)[0]
    let pageNumbers: string = '' // All pages

    if (uploadType === 'specificPages') {
      const pageString = specificPages.split(',')
      pageNumbers = JSON.stringify(extractPageNumber(pageString))
    }

    insert.mutate({ file: fileInput, pageNumbers, beforePage, afterPage }, { onSuccess: close })
  })

  return (
    <Dialog open={!!categoryID} onOpenChange={close}>
      <DialogSurface>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <OverlaySpinner
              visible={insert.isPending}
              appearance="primary"
            />
            <DialogTitle
              action={(
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    onClick={close}
                  />
                </DialogTrigger>
              )}
            >
              <T id="page.upload.title" />
            </DialogTitle>
            <DialogContent className={styles.content}>
              {insert.isError
                ? (
                    <ErrorAlert
                      title={t('page.add.error.title')}
                      help={t('all.error.review')}
                      detail={parseServerError(insert.error)}
                      onClose={() => insert.reset()}
                    />
                  )
                : null}
              <Field
                label={t('page.upload.field.file')}
                hint={t('page.upload.field.file.hint')}
                validationState={error?.error ? 'error' : 'none'}
                validationMessage={error?.message}
                className={styles.content}
              >
                <input
                  {...form.register('file')}
                  type="file"
                  multiple={false}
                />
              </Field>
              <Field label={t('category.add-pages.pages')}>
                <RadioGroup value={uploadType} onChange={(_, data) => setUploadType(data.value)}>
                  <Radio value="allPages" label={t('page.upload.options.allPages')} />
                  <Radio value="specificPages" label={t('page.upload.options.specificPages')} />
                </RadioGroup>
              </Field>
              {uploadType === 'specificPages' && (
                <Field hint={t('page.upload.specificPages.hint')}>
                  <Input size="large" appearance="filled-darker" onChange={e => setSpecificPages(e.target.value)} />
                </Field>
              )}
              <Body1>
                <T id="page.upload.rules" />
              </Body1>
            </DialogContent>
            <DialogActions>
              <Button appearance="primary" type="submit">
                <T id="page.upload.submit" />
              </Button>
              <Button onClick={close}>
                <T id="page.upload.cancel" />
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )
}
