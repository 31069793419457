import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { server } from '../server'
import { addTreePath } from '../utils/tree'

interface ConstructionID { constructionID: string | null }
interface CategoryID { categoryID: string }

const getAllQueryKey = () => ['category']

function getListQueryKey(params: ConstructionID) {
  return [
    ...getAllQueryKey(),
    'list',
    params.constructionID,
  ]
}

function getDetailQueryKey(params: CategoryID) {
  return [
    ...getAllQueryKey(),
    'detail',
    params.categoryID,
  ]
}

export function useCategoryList(params: ConstructionID) {
  const { constructionID } = params

  return useQuery({
    queryFn: () => {
      if (constructionID === null)
        throw new Error('constructionID is null')
      return server.listCategories(constructionID)
    },
    queryKey: getListQueryKey({ constructionID }),
    select: addTreePath,
    enabled: constructionID !== null,
  })
}

export function useCategoryDetail(params: CategoryID) {
  const { categoryID } = params

  return useQuery({
    queryFn: () => server.getCategoryDetail(categoryID),
    queryKey: getDetailQueryKey({ categoryID }),
    enabled: !!params.categoryID,
  })
}

export type CategoryCreateBody = Omit<
  Parameters<typeof server.createCategory>[0],
  'constructionID' | 'parentID'
> & { parentID: string | undefined }

export function useCategoryCreate(init: { constructionID: string }) {
  const { constructionID } = init
  const client = useQueryClient()

  return useMutation({
    mutationFn: (body: CategoryCreateBody) => {
      return server.createCategory({ constructionID, ...body } as any)
    },
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getListQueryKey({ constructionID }),
      })
    },
  })
}

export function useCategoryRename(init: CategoryID) {
  const { categoryID } = init
  const client = useQueryClient()

  return useMutation({
    mutationFn: (body: { name: string }) => {
      return server.renameCategory(categoryID, body)
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: getAllQueryKey() })
    },
  })
}

export function useCategoryDelete(params: ConstructionID) {
  const client = useQueryClient()

  return useMutation({
    mutationFn: (categoryID: string) => {
      return server.deleteCategory(categoryID)
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: getListQueryKey(params) })
    },
  })
}
