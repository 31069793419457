import type { SVGProps } from 'react'

export function DrainPipe(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M10 3.7C11.6 5.9 14 9.7 14 12C14 14.2 12.2 16 10 16C7.8 16 6 14.2 6 12C6 9.7 8.4 5.8 10 3.7ZM10 2C10 2 5 8.3 5 12C5 14.8 7.2 17 10 17C12.8 17 15 14.8 15 12C15 8.2 10 2 10 2Z"
        fill="#0F6CBD"
      />
      <path
        d="M10 15C8.3 15 7 13.7 7 12H8C8 13.1 8.9 14 10 14V15Z"
        fill="#0F6CBD"
      />
    </svg>
  )
}
