import groupBy from 'lodash/groupBy'
import type { InsulationThickness, InsulationThicknesses, ShapeOptions } from '../../type'

export type CustomInsulThickness = Omit<InsulationThickness, 'equipmentClass'>

export interface CustomInsulThicknesses {
  [equip: string]: CustomInsulThickness[]
}

export function getInsulThicknessInitial(): CustomInsulThickness {
  return { from: 0, to: 0, thickness: 0 }
}

export function parseInsulThicknesses(shapeOptions: ShapeOptions): CustomInsulThicknesses {
  return shapeOptions.kind === 'InsulationThickness'
    ? groupBy(shapeOptions.data as InsulationThicknesses, 'equipmentClass')
    : {}
}

export function serializeInsulThicknesses(insulShapes: CustomInsulThicknesses): ShapeOptions {
  return {
    kind: 'InsulationThickness',
    data: Object.entries(insulShapes).flatMap(([equip, shapes]) => {
      return shapes.map(shape => ({ ...shape, equipmentClass: Number(equip) }))
    }),
  }
}
