import { useIntl } from './context'

interface TProps {
  id: string
  params?: Record<string, string>
}

/**
 * @description This module uses in common. Use the internal component instead
 */
export function Translate(props: TProps): JSX.Element {
  const { id, params } = props
  const { dict } = useIntl()

  let result = dict[id] || id
  if (params) {
    result = Object.entries(params).reduce((t, [param, value]) => {
      return t.replaceAll(`{{.${param}}}`, value)
    }, result)
  }

  return <>{result}</>
}
