import { type ReactElement, useState } from 'react'
import type {
  AnnotationDataType,
  CategoryDetail,
  ConstructionDetail,
} from '@aisekisan/anya-api'
import {
  Card,
  Tab,
  TabList,
  makeStyles,
  shorthands,
} from '@aisekisan/bond'
import type { ReportHead, ReportRow } from '../type'
import { FirePipeReport } from './pipe/report'
import { FirePieceReport } from './piece/report'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  tab: {
    width: '100%',
    ...shorthands.overflow('auto'),
  },
  container: {
    width: '100%',
    maxHeight: '50vh',
    ...shorthands.overflow('auto'),
  },
})

interface Props {
  construction: ConstructionDetail
  category: CategoryDetail
  reportHeads: ReportHead[]
  reportRows: ReportRow[]
}

export function FireReport(props: Props): ReactElement | null {
  const { construction, category, reportHeads, reportRows } = props
  const s = useStyles()
  const [tab, setTab] = useState<AnnotationDataType>('Pipes')

  return (
    <>
      <div className={s.tab}>
        <TabList
          selectedValue={tab}
          onTabSelect={(_, data) => setTab(data.value as AnnotationDataType)}
        >
          <Tab value="Pipes">
            <T id="report-fire.tab.pipes" />
          </Tab>
          <Tab value="Pieces">
            <T id="report-fire.tab.pieces" />
          </Tab>
        </TabList>
      </div>
      <Card className={s.container}>
        <table>
          {tab === 'Pipes' && (
            <FirePipeReport
              construction={construction}
              category={category}
              reportHeads={reportHeads}
              reportRows={reportRows.filter(row => row.dataType === 'Pipes')}
            />
          )}
          {tab === 'Pieces' && (
            <FirePieceReport
              construction={construction}
              category={category}
              reportHeads={reportHeads}
              reportRows={reportRows.filter(row => row.dataType === 'Pieces')}
            />
          )}
        </table>
      </Card>
    </>
  )
}
