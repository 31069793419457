import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from '@aisekisan/bond'
import { Person24Regular } from '@fluentui/react-icons'
import { useAccountInfo, useAccountSignOut } from '@aisekisan/anya-api'
import { useLocation } from '@/hooks/use-location'
import { T } from '@/libs/intl/t'

export function AuthInformation(): JSX.Element | null {
  const { navigate } = useLocation()

  const { data: account } = useAccountInfo()
  const signOut = useAccountSignOut()

  if (typeof account === 'undefined')
    return null

  return (
    <Menu>
      <MenuTrigger>
        <MenuButton
          appearance="transparent"
          icon={<Person24Regular />}
          style={{ fontWeight: 'normal' }}
        >
          {account.email}
        </MenuButton>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          <MenuItem onClick={() => void navigate('/settings')}><T id="account.manage.title" /></MenuItem>
          <MenuItem
            onClick={() => {
              signOut.mutate(void {}, {
                onSuccess: () => void navigate('/sign-in'),
              })
            }}
          >
            <T id="account.signout" />
          </MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
