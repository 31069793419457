import { useIntl } from '@aisekisan/bond'
import { parseServerError, useEquipmentAttributeTreeCreate, useEquipmentClasses } from '@aisekisan/anya-api'
import type { EquipmentAttributeTree } from '@aisekisan/anya-api'
import { CopyProperty } from '@aisekisan/preset-property'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'

interface Props {
  property: EquipmentAttributeTree
  close: () => void
}

export function CopyConstructionPropertyDirectly(props: Props): JSX.Element {
  const { property, close } = props
  const { tree, treeType, constructionID, equipmentClassOther } = property

  const t = useT()
  const { lang } = useIntl()

  const { data } = useEquipmentClasses()
  const equipmentClasses = (data ?? [])
    .filter(equipmentClass => equipmentClass.locale === lang)
    .map(({ equipmentClass, localed }) => {
      return { equipmentClass, name: localed }
    })

  const copy = useEquipmentAttributeTreeCreate({ constructionID })

  return (
    <>
      <CopyProperty
        equipmentClasses={equipmentClasses}
        isPending={copy.isPending}
        close={close}
        copy={(equipmentClass) => {
          copy.mutate(
            {
              equipmentClass,
              equipmentClassOther,
              tree,
              treeType,
              constructionID,
            },
            { onSuccess: close },
          )
        }}
      />
      {copy.isError
        ? (
          <ErrorAlert
            title={t('preset.equipment.property.copy.error')}
            help={t('all.error.review')}
            detail={parseServerError(copy.error)}
            onClose={() => copy.reset()}
          />
          )
        : null}
    </>
  )
}
