import type { SVGProps } from 'react'

export function IntlFlagJa(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Japanese">
        <path
          id="Vector"
          d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z"
          fill="#ECEFF1"
        />
        <path
          id="Vector_2"
          d="M33 24C33 28.969 28.969 33 24 33C19.031 33 15 28.969 15 24C15 19.031 19.031 15 24 15C28.969 15 33 19.031 33 24Z"
          fill="#D50000"
        />
      </g>
    </svg>
  )
}
