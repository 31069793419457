import {
  Body1,
  Button,
  Subtitle2,
  makeStyles,
  shorthands,
  tokens,
} from '@aisekisan/bond'
import {
  ChevronRight20Regular,
  ChevronRight24Regular,
} from '@fluentui/react-icons'
import type { ConstructionDetail } from '@aisekisan/anya-api'
import { useCategoryList, useConstructionDetail, useConstructionList } from '@aisekisan/anya-api'
import { navigate } from 'wouter/use-browser-location'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'
import {
  getCategoryLabel,
} from '@/components/category/utils'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: tokens.spacingHorizontalL,
    rowGap: tokens.spacingVerticalL,
  },
  card: {
    height: 'max-content',
    width: '320px',
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    ...shorthands.border(`1px solid ${tokens.colorNeutralStroke2}`),
  },
  root: {
    display: 'flex',
    backgroundColor: tokens.colorNeutralBackground1,
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box',
    height: '100%',
    ...shorthands.padding(tokens.spacingHorizontalL),
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    ...shorthands.border(`1px solid ${tokens.colorNeutralStroke2}`),
    boxShadow: tokens.shadow4,
  },
  categories: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    rowGap: tokens.spacingVerticalS,
    height: '80px',
  },
  hintText: {
    color: tokens.colorNeutralForeground3,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalL,
    alignContent: 'center',
    marginBottom: tokens.spacingVerticalL,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    rowGap: tokens.spacingVerticalL,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.spacingHorizontalS,
  },
  construction: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...shorthands.padding(0),
  },
  category: {
    ...shorthands.padding(0),
  },
})

interface ConstructionCardProps {
  title: React.ReactNode
  children: React.ReactNode
}

function ConstructionCard(props: ConstructionCardProps): JSX.Element {
  const { title, children } = props

  const styles = useStyles()

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {title && <Subtitle2 className={styles.title}>{title}</Subtitle2>}
      </div>
      <div className={styles.main}>{children}</div>
    </div>
  )
}

interface ConstructionProps {
  construction: ConstructionDetail
  orgID: string
}

function ConstructionOverview(props: ConstructionProps): JSX.Element | null {
  const { construction } = props

  const t = useT()
  const styles = useStyles()

  const detail = useConstructionDetail({
    constructionID: construction.id,
  }).data

  const categories = useCategoryList({ constructionID: construction.id }).data

  if (typeof detail === 'undefined')
    return null

  return (
    <div className={styles.card}>
      <ConstructionCard
        title={(
          <Button
            icon={<ChevronRight24Regular />}
            iconPosition="after"
            appearance="transparent"
            style={{ padding: 0 }}
            onClick={() => void navigate(`/construction/${construction.id}`)}
          >
            <Subtitle2>{`${t('construction.title')} ${detail.name}`}</Subtitle2>
          </Button>
        )}
      >
        <div className={styles.categories}>
          {typeof categories === 'undefined' || categories.length === 0
            ? (
              <Body1 className={styles.hintText}>
                <T id="construction.categories.empty" />
              </Body1>
              )
            : (
              <>
                {categories.slice(0, 2).map(category => (
                  <Button
                    appearance="transparent"
                    onClick={() => void navigate(`/category/${category.value.id}`)}
                    className={styles.construction}
                    icon={<ChevronRight20Regular />}
                    key={category.value.id}
                  >
                    {`${t('construction.field.category')} ${getCategoryLabel(category)}`}
                  </Button>
                ))}
                {categories.length > 2 && (
                  <Button
                    appearance="transparent"
                    onClick={() => void navigate(`/construction/${construction.id}`)}
                    className={styles.category}
                  >
                    <Body1 className={styles.hintText}>
                      {`+ ${categories.length - 2} ${t('category.categories.label')}`}
                    </Body1>
                  </Button>
                )}
              </>
              )}
        </div>
      </ConstructionCard>
    </div>
  )
}

interface Props {
  orgID: string
}

export function OrgConstructions(props: Props): JSX.Element {
  const { orgID } = props

  const styles = useStyles()

  const constructions = useConstructionList({ orgID }).data ?? []

  return (
    <div className={styles.wrapper}>
      {constructions.map(construction => (
        <ConstructionOverview
          key={construction.id}
          construction={construction}
          orgID={orgID}
        />
      ))}
    </div>
  )
}
