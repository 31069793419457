import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { server } from '../server'

const KEY = 'account-otp'
export function useAccountOTPStatus() {
  return useQuery({
    queryKey: [KEY, 'status'],
    queryFn: server.getTotpStatus,
  })
}

export function useAccountOTPRegister() {
  return useQuery({
    queryKey: [KEY, 'register'],
    queryFn: server.registerTotp,
  })
}

export function useDisableAccountOTP() {
  const client = useQueryClient()

  return useMutation({
    mutationFn: server.disableTotp,
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: [KEY, 'status'] })
    },
  })
}

export function useEnableAccountOTP() {
  const client = useQueryClient()

  return useMutation({
    mutationFn: server.enableTotp,
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: [KEY, 'status'] })
    },
  })
}
