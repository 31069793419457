import { parseServerError, useOrgPresetCreate } from '@aisekisan/anya-api'
import { DEFAULT_PRESET_FORM, PresetFormDialog } from '@aisekisan/preset-property'
import type { ReactElement } from 'react'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'

interface Props {
  orgID: string
  close: () => void
}

export function OrgPresetCreate(props: Props): ReactElement {
  const { close, orgID } = props

  const t = useT()

  const create = useOrgPresetCreate({ orgID })

  return (
    <PresetFormDialog
      preset={DEFAULT_PRESET_FORM}
      type="create"
      isPending={create.isPending}
      close={close}
      submit={(values) => {
        create.mutate(values, { onSuccess: close })
      }}
      errorAlert={create.isError
        ? (
          <ErrorAlert
            title={t('preset.create.error.title')}
            help={t('all.error.review')}
            detail={parseServerError(create.error)}
            onClose={() => create.reset()}
          />
          )
        : null}
    />
  )
}
