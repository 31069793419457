import { type AnnotationDetail, type Pieces, type Pipes, listAnnotations } from '@aisekisan/anya-api'
import type { Editor } from '@tldraw/tldraw'
import { fromServerSegment } from '../../../../editor/annot/segment/shape'
import type { AnnotShapeColor } from '../../../../editor/annot/shape/color'
import { annotShapeColorSchema, randomAnnotShapeColor } from '../../../../editor/annot/shape/color'
import type { AnnotPartial } from '../../../../editor/annot/shape/shape'
import { fromServerPiece } from '@/components/editor/annot/piece/server'

function toResult(shapes: AnnotPartial[], annot: AnnotationDetail): AnnotPartial[] {
  const colorTest = annotShapeColorSchema.safeParse(annot.data.colorName)
  const color: AnnotShapeColor = colorTest.success
    ? colorTest.data
    : randomAnnotShapeColor()
  const group = annot.data.uuid
  const equip = annot.equipmentClass

  switch (annot.dataType) {
    case 'Pipes': {
      const segments = (annot.data as Pipes).lineSegments
        .map(fromServerSegment({ color, group }))
      shapes.push(...segments)
      break
    }
    case 'Pieces': {
      const pieces = (annot.data as Pieces).pieces
        .map(server => fromServerPiece({ server, group, color, equip }))
      shapes.push(...pieces)
      break
    }
    default:
      throw new Error(`Unknown data type: "${annot.dataType}"`)
  }
  shapes.push()
  return shapes
}

export async function renderAnnots(props: {
  editor: Editor
  pageID: string
}): Promise<void> {
  const { editor, pageID } = props

  // Load page annots
  const annots = await listAnnotations(pageID)
  const shapes = annots.reduce(toResult, [])

  // Reset, not append, for both attrs and shapes
  editor.deleteShapes(editor.getCurrentPageShapes())
  editor.createShapes(shapes)
}
