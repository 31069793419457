import {
  parseServerError,
  useEquipmentAttributeTreeCreate,
  useEquipmentAttributeTrees,
  useEquipmentClasses,
} from '@aisekisan/anya-api'
import {
  Button,
  Title3,
  makeStyles,
  shorthands,
  tokens,
  useIntl,
} from '@aisekisan/bond'
import type { PropertyFormBody } from '@aisekisan/preset-property'
import {
  PropertyForm,
  getEquipmentAttributeInitialTree,
  parseTreeNode,
  serializeTreeNode,
} from '@aisekisan/preset-property'
import { Save20Regular } from '@fluentui/react-icons'
import { useForm } from 'react-hook-form'
import { EQUIP_CLASS } from '@/components/equipment-class'
import { useLocation } from '@/hooks/use-location'
import { useToast } from '@/hooks/use-toast'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    rowGap: tokens.spacingVerticalL,
    columnGap: tokens.spacingHorizontalL,
    boxSizing: 'border-box',
    flexWrap: 'wrap',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalL,
    ...shorthands.margin(0),
  },
})

export function CreateConstructionProperty(props: { constructionID: string }) {
  const { constructionID } = props

  const t = useT()
  const styles = useStyles()
  const { lang } = useIntl()

  const { queryParams, navigate } = useLocation()
  const equipmentClassID = queryParams.equipmentClass ? Number(queryParams.equipmentClass) : undefined

  const toast = useToast({
    title: 'Create equipment property successfully',
    intent: 'success',
  })

  const form = useForm<PropertyFormBody>({
    defaultValues: {
      equipmentClass: equipmentClassID ?? EQUIP_CLASS.OTHER,
      equipmentClassOther: '',
      tree: getEquipmentAttributeInitialTree(),
      treeType: 'material',
    },
  })

  const { data } = useEquipmentClasses()
  const equipmentClasses = (data ?? [])
    .filter(equipmentClass => equipmentClass.locale === lang)
    .map(({ equipmentClass, localed }) => {
      return { equipmentClass, name: localed }
    })

  const { data: trees } = useEquipmentAttributeTrees({ constructionID })
  const insulTree = trees?.find((tree) => {
    return tree.equipmentClass === EQUIP_CLASS.INSULATION && tree.treeType === 'material'
  })?.tree

  const create = useEquipmentAttributeTreeCreate({ constructionID })

  const onCreate = form.handleSubmit((values) => {
    const transformedTree = serializeTreeNode(values.tree)

    const equipmentAttributeTree = {
      equipmentClass: Number(values.equipmentClass),
      equipmentClassOther: values.equipmentClassOther,
      tree: transformedTree,
      treeType: values.treeType,
      constructionID,
    }

    create.mutate(equipmentAttributeTree, {
      onSuccess: () => {
        toast.notify()
        navigate(`/construction/${constructionID}`)
      },
    })
  })

  return (
    <>
      {toast.toast}
      <div className={styles.controls}>
        <Title3>
          <T id="preset.equipment.property.label" />
        </Title3>
        <Button
          appearance="primary"
          icon={<Save20Regular />}
          onClick={onCreate}
        >
          <T id="preset.equipment.property.tree.create" />
        </Button>
      </div>
      {create.isError && (
        <ErrorAlert
          title={t('preset.equipment.property.create.form.error.title')}
          help={t('all.error.review')}
          detail={parseServerError(create.error)}
          onClose={() => create.reset()}
        />
      )}
      <PropertyForm
        equipmentClasses={equipmentClasses}
        form={form}
        insulTree={insulTree ? parseTreeNode(insulTree) : null}
        isCreate
      />
    </>
  )
}
