import { Body1, makeStyles, tokens } from '@aisekisan/bond'
import type { ReactElement } from 'react'
import { T } from '../../libs/intl/t'
import { FlagJA } from '../../icons/FlagJA'
import { FlagEN } from '../../icons/FlagEN'
import type { Locale } from '../../type'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalXS,
  },
})

export function DisplayLocale(props: { locale: Locale }): ReactElement {
  const { locale } = props

  const styles = useStyles()

  if (locale === 'ja') {
    return (
      <div className={styles.container}>
        <FlagJA />
        <Body1>
          <T id="preset-property.locale.jp" />
        </Body1>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <FlagEN />
      <Body1>
        <T id="preset-property.locale.us" />
      </Body1>
    </div>
  )
}
