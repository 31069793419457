import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Field,
  Input,
  OverlaySpinner,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { useState } from 'react'
import { parseServerError, useCategoryDelete } from '@aisekisan/anya-api'
import type { CategoryDetail } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useLocation } from '@/hooks/use-location'

interface Props {
  category: CategoryDetail
  constructionID: string
  close: () => void
}

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalL,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
})

interface Props {
  category: CategoryDetail
  constructionID: string
  close: () => void
}

export function CategoryDelete(props: Props): JSX.Element {
  const { category, close, constructionID } = props
  const categoryID = category.id
  const categoryName = category.name

  const t = useT()
  const styles = useStyles()
  const { navigate } = useLocation()

  const [name, setName] = useState<string>('')
  const [error, setError] = useState<{ error: boolean, message: string } | null>(null)

  const deleteCategory = useCategoryDelete({ constructionID })

  return (
    <Dialog open onOpenChange={close}>
      <DialogSurface>
        <DialogBody>
          <OverlaySpinner visible={deleteCategory.isPending} appearance="primary" />
          <DialogTitle action={(
            <DialogTrigger action="close">
              <Button
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
                onClick={close}
              />
            </DialogTrigger>
          )}
          >
            <T id="category.delete.title" />
          </DialogTitle>
          <DialogContent>
            {deleteCategory.isError
              ? (
                <ErrorAlert
                  title={t('category.delete.error.title')}
                  help={<T id="all.error.retry" />}
                  detail={parseServerError(deleteCategory.error)}
                  onClose={() => deleteCategory.reset()}
                />
                )
              : null}
            <div className={styles.content}>
              <Body1>
                <T id="category.delete.helper" />
                <span>
                  <strong>
                    {' '}
                    {categoryName}
                  </strong>
                </span>
                ?
              </Body1>
              <Body1 className={styles.content}>
                <T id="category.delete.rules" />
                <ul style={{ margin: 0 }}>
                  <li>
                    <T id="category.delete.rules.1" />
                  </li>
                  <li>
                    <T id="category.delete.rules.2" />
                  </li>
                </ul>
                <T id="category.delete.rules.3" />
                <Field label={t('category.field.name')} validationState={error?.error ? 'error' : 'none'} validationMessage={error?.message}>
                  <Input size="large" appearance="filled-darker" onChange={e => setName(e.target.value)}></Input>
                </Field>
              </Body1>
            </div>

          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              onClick={() => {
                if (name === categoryName) {
                  setError(null)
                  deleteCategory.mutate(categoryID, {
                    onSuccess: () => {
                      close()
                      navigate(`/construction/${constructionID}`)
                    },
                  })
                }
                else {
                  setError({ error: true, message: `Category name must be same with '${categoryName}'` })
                }
              }}
            >
              <T id="category.action.delete" />
            </Button>
            <Button onClick={close}>
              <T id="dialog.action.cancel" />
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
