import { useForm } from 'react-hook-form'
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Input, OverlaySpinner, makeStyles, tokens } from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { useState } from 'react'
import { parseServerError, usePageReplace } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'

interface ReplacePageForm {
  file: File | null
  pageNumber: string
}

interface ReplacePageProps {
  pageID: string
  close: () => void
}

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
})

export function ReplacePage(props: ReplacePageProps): JSX.Element {
  const { pageID, close } = props

  const t = useT()
  const styles = useStyles()

  const [error, setError] = useState<{ error: boolean, message: string } | null>(null)

  const form = useForm<ReplacePageForm>({
    defaultValues: {
      file: null,
      pageNumber: '1',
    },
  })

  const replace = usePageReplace({ pageID })

  const onSubmit = form.handleSubmit((values: ReplacePageForm) => {
    const { file, pageNumber } = values

    if (file === null) {
      setError({ error: true, message: t('file.error.message') })
      throw new Error('file is null, validator fails')
    }

    if (error?.error)
      setError(null)

    const fileInput = Object.values(file)[0]
    replace.mutate({ file: fileInput, pageNumber }, { onSuccess: close })
  })

  return (
    <Dialog open={!!pageID} onOpenChange={close}>
      <DialogSurface>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <OverlaySpinner
              visible={replace.isPending}
              appearance="primary"
            />
            <DialogTitle
              action={(
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    onClick={close}
                  />
                </DialogTrigger>
              )}
            >
              <T id="page.replace.title" />
            </DialogTitle>
            <DialogContent className={styles.content}>
              {replace.isError
                ? (
                  <ErrorAlert
                    title={t('page.replace.error.title')}
                    help={t('all.error.review')}
                    detail={parseServerError(replace.error)}
                    onClose={() => replace.reset()}
                  />
                  )
                : null}
              <Field
                label={t('page.replace.field.file.label')}
                hint={t('page.replace.field.file.hint')}
                validationState={error?.error ? 'error' : 'none'}
                validationMessage={error?.message}
                className={styles.content}
              >
                <input
                  {...form.register('file')}
                  type="file"
                  multiple={false}
                />
              </Field>
              <Field
                label={t('page.replace.field.page')}
                hint={t('page.replace.field.page.hint')}
              >
                <Input
                  type="number"
                  defaultValue={form.getValues('pageNumber')}
                  onChange={e => form.setValue('pageNumber', e.target.value)}
                  size="large"
                  appearance="filled-darker"
                />
              </Field>
            </DialogContent>
            <DialogActions>
              <Button appearance="primary" type="submit">
                <T id="page.replace.submit" />
              </Button>
              <Button onClick={close}>
                <T id="dialog.action.cancel" />
              </Button>

            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )
}
