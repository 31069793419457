import type { SVGProps } from 'react'

export function FlagEN(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path d="M2.49902 4.49H17.499V14.49H2.49902V4.49Z" fill="#F78F8F" />
      <path
        d="M16.999 4.99V13.99H2.99902V4.99H16.999ZM17.999 3.99H1.99902V14.99H17.999V3.99Z"
        fill="#C74343"
      />
      <path
        d="M8 4.99H17V5.99H8V4.99ZM9 6.99H17V7.99H9V6.99ZM3 10.99H17V11.99H3V10.99ZM3 8.99H17V9.99H3V8.99ZM3 12.99H17V13.99H3V12.99Z"
        fill="white"
      />
      <path d="M2.5 4.5H8.5V10.49H2.5V4.5Z" fill="#8BB7F0" />
      <path d="M8 5V9.99H3V5H8ZM9 4H2V10.99H9V4Z" fill="#4E7AB5" />
      <path
        d="M3 5H4V6H3V5ZM5 5H6V6H5V5ZM7 5H8V6H7V5ZM4 6H5V7H4V6ZM6 6H7V7H6V6ZM3 7H4V8H3V7ZM5 7H6V8H5V7ZM7 7H8V8H7V7ZM4 8H5V9H4V8ZM6 8H7V9H6V8ZM3 9H4V10H3V9ZM5 9H6V10H5V9ZM7 9H8V10H7V9Z"
        fill="white"
      />
    </svg>
  )
}
