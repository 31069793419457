import { Body1, makeStyles, tokens } from '@aisekisan/bond'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  container: {
    width: '100%',
    color: tokens.colorNeutralForeground3,
    justifyContent: 'center',
    minHeight: '420px',
    display: 'flex',
    alignItems: 'center',
  },
})

export function NotFound(): JSX.Element {
  const styles = useStyles()
  return (
    <div className={styles.container}>
      <Body1>
        <T id="error.message" />
      </Body1>
    </div>
  )
}
