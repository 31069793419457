import type { TLGeoShape } from '@tldraw/tldraw'
import type { PipeLineSegmentVertical } from '@aisekisan/anya-api'
import type { AnnotShapeColor } from '../../../shape/color'
import type { SegmentMetaBase } from '../../base'
import { createSegmentVerticalPartial } from './create'
import type { ShapePartial } from '@/components/editor/editor/shape/util/base'

export type SegmentVerticalMeta = SegmentMetaBase & {
  segment: 'vertical'
  mm: number
  // Important: There is no "direction" here, because "shape.rotate" should be
  // the source of truth.
}

export type SegmentVerticalPartial = ShapePartial<
  TLGeoShape,
  SegmentVerticalMeta
>

export function fromServerSegmentVertical(props: {
  color: AnnotShapeColor
  group: string
  server: PipeLineSegmentVertical
}): SegmentVerticalPartial {
  const { color, group, server } = props
  const { direction, length, point } = server

  return createSegmentVerticalPartial({
    color,
    direction,
    meta: { group, mm: length },
    vec: { x: point.x, y: point.y },
  })
}
