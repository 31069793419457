import { OrgRole, useAccountInfo, useOrgMemberList } from '@aisekisan/anya-api';

export function useOrgRole(orgID: string) : OrgRole{
  const account = useAccountInfo().data
  const memberList = useOrgMemberList({ orgID }).data

  if (account === undefined)
    return 'member' as OrgRole

  if (memberList === undefined)
    return 'member' as OrgRole

  const role = memberList.find(member => member.email === account.email)?.role ?? 'member'
  return role
}