import type { ReactElement } from 'react'
import type { CustomShapeOptions, DataShapes } from '../type'
import { BoxForm } from './box'
import { CircleForm } from './circle'
import { CustomShapeForm } from './custom-shape'
import { ElectricPowerForm } from './electric-power'
import { RectangleForm } from './rectangle'
import { RefrigerantPipeForm } from './refrigerant-pipe'

interface Props {
  shapeOptions: CustomShapeOptions
  onUpdateDataShape: (value: DataShapes) => void
}

export function ShapeForm(props: Props): ReactElement | null {
  const { shapeOptions, onUpdateDataShape } = props

  switch (shapeOptions.shape) {
    case 'Rectangle':
      return (
        <RectangleForm
          rectangles={shapeOptions.rectangles}
          onUpdate={onUpdateDataShape}
        />
      )
    case 'Circle':
      return (
        <CircleForm
          circles={shapeOptions.circles}
          onUpdate={onUpdateDataShape}
        />
      )
    case 'BoxShape':
      return (
        <BoxForm
          boxs={shapeOptions.boxShapes}
          onUpdate={onUpdateDataShape}
        />
      )
    case 'RefrigerantPipeShape':
      return (
        <RefrigerantPipeForm
          refrigerantPipes={shapeOptions.refrigerantPipeShapes}
          onUpdate={onUpdateDataShape}
        />
      )
    case 'ElectricPower':
      return (
        <ElectricPowerForm
          electricPowers={shapeOptions.electricPowers}
          onUpdate={onUpdateDataShape}
        />
      )
    case 'CustomShape':
      return (
        <CustomShapeForm
          customShapes={shapeOptions.customShapes}
          onUpdate={onUpdateDataShape}
        />
      )
    default:
      return null
  }
}
