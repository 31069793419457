import { Button, Panel, makeStyles, tokens } from '@aisekisan/bond'
import {
  ArrowRight20Regular,
  Edit20Regular,
} from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useState } from 'react'
import type { OrgDetail } from '@aisekisan/anya-api'
import { useOrgDetail } from '@aisekisan/anya-api'
import { navigate } from 'wouter/use-browser-location'
import { OrgEdit } from '../information/edit'
import { OrgStatsOverview } from '../stats/overview'
import { OrgConstructions } from './constructions'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { useOrgRole } from '../member/role'

const useStyles = makeStyles({
  container: {
    maxWidth: '960px',
  },
  detail: {
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    columnGap: tokens.spacingHorizontalXL,
  },
  actionGroup: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalL,
  },
})

interface Props {
  org: OrgDetail
}

export function OrgCardOverview(props: Props): ReactElement | null {
  const { org } = props
  const orgID = org.id

  const styles = useStyles()
  const t = useT()

  const [edit, setEdit] = useState<boolean>(false)

  const detail = useOrgDetail({ orgID }).data

  const role = useOrgRole(orgID)

  if (typeof detail === 'undefined')
    return null

  return (
    <Panel
      className={styles.container}
      title={`${t('org.title')} ${detail.name}`}
      actions={(
        <div className={styles.actionGroup}>
          <Button
            appearance="primary"
            onClick={() => void navigate(`/org/${orgID}`)}
            icon={<ArrowRight20Regular />}
            iconPosition="after"
          >
            <T id="org.overview.organization.go" />
          </Button>
          {role === 'owner' ? <Button icon={<Edit20Regular />} onClick={() => setEdit(true)}>
            <T id="org.overview.organization.edit" />
          </Button> : null}
        </div>
      )}
    >
      <div className={styles.detail}>
        <OrgStatsOverview orgID={orgID} />
        <OrgConstructions orgID={org.id} />
      </div>
      {edit && <OrgEdit org={org} close={() => setEdit(false)} />}
    </Panel>
  )
}
