import type { BoxShapes, Circles, CustomShapes, ElectricPowers, Rectangles, RefrigerantPipeShapes, ShapeOptions, ShapeType, Unit } from '../../type'

export type DataShapes =
  | CustomShapes
  | Rectangles
  | Circles
  | RefrigerantPipeShapes
  | ElectricPowers
  | BoxShapes

export interface CustomShapeOptions {
  shape: ShapeType
  rectangles: Rectangles
  circles: Circles
  refrigerantPipeShapes: RefrigerantPipeShapes
  electricPowers: ElectricPowers
  boxShapes: BoxShapes
  customShapes: CustomShapes
  unit?: Unit
}

export function getCustomShapeOptionsInitial(): CustomShapeOptions {
  return {
    rectangles: [{ length: 0, width: 0 }],
    shape: 'Rectangle',
    boxShapes: [],
    circles: [],
    electricPowers: [],
    refrigerantPipeShapes: [],
    customShapes: [],
  }
}

export function parseCustomShapeOptions(shapeOptions: ShapeOptions): CustomShapeOptions {
  return {
    shape: shapeOptions?.kind ?? 'Rectangle',
    rectangles:
      shapeOptions?.kind === 'Rectangle'
        ? (shapeOptions.data as Rectangles)
        : [],
    circles:
      shapeOptions?.kind === 'Circle'
        ? (shapeOptions.data as Circles)
        : [],
    boxShapes:
      shapeOptions?.kind === 'BoxShape'
        ? (shapeOptions.data as BoxShapes)
        : [],
    electricPowers:
      shapeOptions?.kind === 'ElectricPower'
        ? (shapeOptions.data as ElectricPowers)
        : [],
    refrigerantPipeShapes:
      shapeOptions?.kind === 'RefrigerantPipeShape'
        ? (shapeOptions.data as RefrigerantPipeShapes)
        : [],
    customShapes:
      shapeOptions?.kind === 'CustomShape'
        ? (shapeOptions.data as CustomShapes)
        : [],
    unit: shapeOptions.unit as Unit,
  }
}

export function serializeCustomShapeOptions(shapeOptions: CustomShapeOptions): ShapeOptions {
  const shapeOption = { kind: shapeOptions.shape, unit: shapeOptions.unit }
  switch (shapeOptions.shape) {
    case 'BoxShape':
      return {
        ...shapeOption,
        data: shapeOptions.boxShapes,
      }
    case 'Rectangle':
      return {
        ...shapeOption,
        data: shapeOptions.rectangles,
      }
    case 'Circle':
      return {
        ...shapeOption,
        data: shapeOptions.circles,
      }
    case 'RefrigerantPipeShape':
      return {
        ...shapeOption,
        data: shapeOptions.refrigerantPipeShapes,
      }
    case 'CustomShape':
      return {
        ...shapeOption,
        data: shapeOptions.customShapes,
      }
    case 'ElectricPower':
      return {
        ...shapeOption,
        data: shapeOptions.electricPowers,
      }
    default:
      throw new Error(`Invalid shape ${shapeOptions.shape}`)
  }
}
