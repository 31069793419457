import { Body1, Button, Panel, makeStyles, tokens } from '@aisekisan/bond'
import {
  DocumentLightning24Regular,
  FlashAdd20Regular,
} from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { useOrgPresetList } from '@aisekisan/anya-api'
import type { OrgPresetDetail } from '@aisekisan/anya-api'
import { PresetCard } from '@aisekisan/preset-property'
import { OrgPresetCreate } from './create'
import { T } from '@/libs/intl/t'
import { EmptyContent } from '@/components/empty-content'
import { OrgPresetEdit } from '@/components/org/preset/edit'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: tokens.spacingHorizontalS,
    rowGap: tokens.spacingVerticalS,
    marginBottom: tokens.spacingVerticalL,
  },
})

export function OrgPresetList(props: { orgID: string }): ReactElement {
  const { orgID } = props

  const styles = useStyles()

  const [create, setCreate] = useState<boolean>(false)

  const orgPresetList = useOrgPresetList({ orgID }).data ?? []

  return (
    <Panel
      title={<T id="construction.preset.list.title" />}
      icon={<DocumentLightning24Regular />}
      actions={(
        <Button icon={<FlashAdd20Regular />} onClick={() => setCreate(true)}>
          <T id="construction.preset.create" />
        </Button>
      )}
    >
      {create && (
        <OrgPresetCreate
          close={() => setCreate(false)}
          orgID={orgID}
        />
      )}
      {orgPresetList.length > 0
        ? (
          <div className={styles.container}>
            {orgPresetList.map(orgPreset => (
              <OrgPresetCard key={orgPreset.id} preset={orgPreset} />
            ))}
          </div>
          )
        : <EmptyContent />}
      <Body1 style={{ color: tokens.colorNeutralForeground3 }}>
        <T id="org.preset.guideline" />
        <ul style={{ margin: 0 }}>
          <li>
            <T id="org.preset.guideline.1" />
          </li>
          <li>
            <T id="org.preset.guideline.2" />
          </li>
        </ul>
      </Body1>
    </Panel>
  )
}

function OrgPresetCard(props: { preset: OrgPresetDetail }): ReactElement {
  const { preset } = props

  const [editPreset, setEditPreset] = useState<boolean>(false)

  return (
    <>
      <PresetCard
        preset={preset}
        setEditPreset={setEditPreset}
        href={`/org-preset/${preset.id}`}
      />
      {editPreset && (
        <OrgPresetEdit
          preset={preset}
          close={() => setEditPreset(false)}
        />
      )}
    </>
  )
}
