import { tokens } from '@aisekisan/bond'
import { useOrgList } from '@aisekisan/anya-api'
import { OrgCardOverview } from './card'
import { ContainerWrapper } from '@/ContainerWrapper'

export function OrgOverviewInfo(): JSX.Element {
  const orgList = useOrgList().data ?? []

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        rowGap: tokens.spacingVerticalL,
        columnGap: tokens.spacingHorizontalL,
      }}
    >
      {orgList.map(org => (
        <ContainerWrapper key={org.id}>
          <OrgCardOverview org={org} />
        </ContainerWrapper>
      ))}
    </div>
  )
}
