import type { ReportHead, ReportRow } from '../type'
import { useT } from '@/libs/intl/useT'

interface Props {
  reportHeads: ReportHead[]
  reportRows: ReportRow[]
}

export function DefaultReportBody(props: Props) {
  const { reportHeads, reportRows } = props

  const t = useT()

  const cols = reportHeads.flatMap(({ pageID, modules }) => {
    if (modules.length === 0)
      return [{ id: pageID, quantity: 1 }]

    return modules.map((cmodule) => {
      if (!cmodule)
        return { id: `${pageID}/no_module`, quantity: 1 }
      const { id, quantity } = cmodule
      return { id: `${pageID}/${id}`, quantity }
    })
  })

  return (
    <>
      {reportRows.map((row) => {
        return (
          <tr key={row.id}>
            {/* Object */}
            <td>{row.equipClassName}</td>
            {/* Material */}
            <td align="center">
              {row.material1}
              {row.material2 && ` / ${row.material2}`}
            </td>
            {/* Type */}
            <td align="center">
              {row.equipType === 'NONFD'
                ? t('report.type.nonfd')
                : row.equipType === 'FD'
                  ? t('report.type.fd')
                  : row.equipType}
            </td>
            {/* Size */}
            <td align="center">{getDisplayReportSize(row)}</td>
            {/* CA */}
            <td align="center">{row.cArea}</td>
            {/* Insulation */}
            <td align="center">
              {row.insulation ?? t('report.insulation.non')}
            </td>
            {/* Unit */}
            <td align="center">
              {row.unit === 'piece'
                ? t('report.unit.piece')
                : row.unit}
            </td>
            {/* PageID & ModuleID */}
            {cols.map(col => (
              <td align="center" key={col.id}>
                {row[col.id]}
              </td>
            ),
            )}
            {/* Total */}
            <td align="center">
              {cols.reduce((acc, col) => {
                const total = row[col.id]
                return total ? acc += Number(total) * col.quantity : acc
              }, 0).toFixed(1)}
            </td>
          </tr>
        )
      })}
    </>
  )
}

function getDisplayReportSize(row: ReportRow): string {
  switch (row.shape) {
    case 'Circle':
    case 'Rectangle':
    case 'CustomShape':
    case 'RefrigerantPipeShape':
    case 'BoxShape':
      return row.size ? `${row.size} mm` : ''
    case 'ElectricPower':
    default:
      return `${row.size}`
  }
}
