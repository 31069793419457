import { Body1, makeStyles, tokens } from '@aisekisan/bond'
import type { ReactElement } from 'react'
import { IconJPY } from '../../icons/JYP'
import { IconUSD } from '../../icons/USD'
import { T } from '../../libs/intl/t'
import type { Market } from '../../type'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalXS,
  },
})

export function DisplayMarket(props: { market: Market }): ReactElement {
  const { market } = props

  const styles = useStyles()

  if (market === 'JAPAN') {
    return (
      <div className={styles.container}>
        <IconJPY />
        <Body1>
          <T id="preset-property.market.jp" />
        </Body1>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <IconUSD />
      <Body1>
        <T id="preset-property.market.us" />
      </Body1>
    </div>
  )
}
