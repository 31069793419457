import { degreesToRadians } from '@tldraw/tldraw'
import type { VerticalDirection } from '@aisekisan/anya-api'

export function toShapeRotation(direction: VerticalDirection): number {
  const degree = (() => {
    switch (direction) {
      case 'up': return 0
      case 'down': return 180
    }
  })()
  return degreesToRadians(degree)
}
