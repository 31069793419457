import type { InsulationOption } from '../../type'

export interface CustomInsulOption {
  material2: number
}

export interface CustomInsulOptions {
  [material1: string]: CustomInsulOption[]
}

export function getInsulOptionsInitial(): CustomInsulOption[] {
  return [{ material2: -1 }]
}

export function parseInsulOptions(insulOptions: InsulationOption[]): CustomInsulOptions {
  const customInsulOptions: CustomInsulOptions = {}
  insulOptions.forEach(({ material }) => {
    const [material1, material2] = material
    if (!customInsulOptions[material1])
      customInsulOptions[material1] = [{ material2 }]
    else
      customInsulOptions[material1].push({ material2 })
  })
  return customInsulOptions
}

export function serializeInsulOptions(insulOptions: CustomInsulOptions): InsulationOption[] {
  return Object.entries(insulOptions)
    .flatMap(([material1, options]) => {
      return options.map(({ material2 }) => {
        return { material: [Number(material1), material2] }
      })
    })
}
