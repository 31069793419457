import type { TLDefaultColorStyle } from '@tldraw/tldraw'
import { z } from 'zod'
import { randomChild } from '@/utils/array'

/**
 * This is narrower than tldraw's colors. We excluded the neutral (e.g., black
 * and white) and weak (e.g., light-blue and light-red) colors.
 */
export const annotShapeColorSchema = z.enum([
  'red',
  'orange',
  'green',
  'blue',
  'violet',
  'yellow',
  'light-blue',
  'light-red',
  'light-violet',
] as const satisfies TLDefaultColorStyle[])

export type AnnotShapeColor = z.infer<typeof annotShapeColorSchema>

interface ColorOption {
  value: AnnotShapeColor
}

const ANNOT_SHAPE_COLORS: ColorOption[] = [
  { value: 'red' },
  { value: 'orange' },
  { value: 'green' },
  { value: 'blue' },
  { value: 'violet' },
  { value: 'yellow' },
  { value: 'light-blue' },
  { value: 'light-red' },
  { value: 'light-violet' },
]

/**
 * This is intentionally narrow than TLDraw's colors. See comment at
 * isShapeColor to learn more.
 */
export function randomAnnotShapeColor() {
  return randomChild(ANNOT_SHAPE_COLORS.map(option => option.value))
}

/**
 * In tldraw opacity (base property) is very different than color (shape
 * property). However, in our context, they are better to be organised in one
 * place.
 */
export const ANNOT_SHAPE_OPACITY = 0.8
