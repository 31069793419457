import groupBy from 'lodash/groupBy'
import type { ReportData, ReportRow } from '../type'

const GROUPED_KEYS: (keyof ReportData)[] = [
  'material1',
  'material2',
  'cArea',
  'equipType',
  'moduleID',
  'insulation',
  'size',
  'shape',
  'unit',
  'equipClassName',
]

function getLength(values: ReportData[]): string {
  const length = values.reduce((acc, v) => {
    return acc + Number(v.value)
  }, 0)
  switch (values[0].unit) {
    case 'm':
      return (length / 1000).toFixed(1)
    case 'mm':
      return length.toFixed(1)
    case 'piece':
    default:
      return length.toString()
  }
}

export function groupReportData(annots: ReportData[]): ReportRow[] {
  const groupedByID = {
    ...groupBy(annots, 'pageID'),
    ...groupBy(annots, 'moduleID'),
  }

  const formatData = Object.entries(groupedByID).flatMap(([id, annots]) => {
    const groups = new Map<string, ReportData[]>()

    annots.forEach((annot) => {
      const key = GROUPED_KEYS.map(key => JSON.stringify(annot[key])).join('-')
      groups.set(key, [...(groups.get(key) ?? []), annot])
    })

    return [...groups.values()].map((values) => {
      const key = values[0].moduleID
        ? `${id}/${values[0].moduleID}`
        : `${id}/no_module`
      return { ...values[0], [key]: getLength(values) }
    })
  })

  const rows = new Map()
  formatData.forEach((data) => {
    const key = GROUPED_KEYS.map(item => JSON.stringify(data[item])).join('-')
    rows.set(key, { ...rows.get(key), ...data, length: {} })
  })

  return Array.from(rows.values())
}
