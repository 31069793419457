import type { SetState } from '@aisekisan/bond'
import { Button, Input, Label, OverlaySpinner, Text } from '@aisekisan/bond'
import { LockClosed16Regular } from '@fluentui/react-icons'
import { useForm } from 'react-hook-form'
import { parseServerError } from '@aisekisan/anya-api'
import { type AccountResetPasswordBody, useAccountResetPassword } from '@aisekisan/anya-api'
import { AccountLayout } from '../layout'
import { useStyles } from '../styles'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'

interface Props {
  token: string
  setSuccess: SetState<boolean>
}

type ResetPasswordBody = Omit<AccountResetPasswordBody, 'token'>

export function ResetPasswordForm(props: Props): JSX.Element {
  const { token, setSuccess } = props

  const t = useT()
  const styles = useStyles()

  const form = useForm<ResetPasswordBody>({
    defaultValues: { newPassword: '' },
  })

  const resetPassword = useAccountResetPassword()

  const submit = (body: ResetPasswordBody) => {
    resetPassword.mutate(
      { ...body, token },
      { onSuccess: () => void setSuccess(true) },
    )
  }

  return (
    <AccountLayout>
      <div className={styles.card}>
        <form onSubmit={form.handleSubmit(submit)} className={styles.flexCol}>
          <OverlaySpinner
            visible={resetPassword.isPending}
            appearance="primary"
          />
          <Text className={styles.title3}>
            <T id="reset-password.title" />
          </Text>
          {resetPassword.isError
            ? (
              <ErrorAlert
                title={t('reset-password.accept.form.error.title')}
                help={t('reset-password.accept.form.error.help')}
                detail={parseServerError(resetPassword.error)}
                onClose={() => resetPassword.reset()}
              />
              )
            : null}
          <Text className={styles.textBody}>
            {t('account.reset-password.description.1')}
          </Text>
          <Text className={styles.textBody}>
            {t('account.reset-password.description.2')}
          </Text>

          <div className={styles.field}>
            <Label htmlFor="email">{t('account.field.password')}</Label>

            <Input
              type="password"
              id="newPassword"
              {...form.register('newPassword')}
              contentBefore={<LockClosed16Regular />}
              required
              size="large"
              appearance="filled-darker"
            />
            <Text className={styles.hintText}>
              {t('account.field.password.helper')}
            </Text>
          </div>
          <div className={styles.fullWidth}>
            <Button appearance="primary" type="submit" size="large">
              <T id="reset-password.accept.form.submit" />
            </Button>
          </div>
        </form>
      </div>
    </AccountLayout>
  )
}
