import { Button, Panel, makeStyles, tokens } from '@aisekisan/bond'
import {
  Certificate16Regular,
  EditRegular,
  Info24Regular,
  Location16Regular,
  Mail16Regular,
  Phone16Regular,
} from '@fluentui/react-icons'
import { useState } from 'react'
import type { OrgDetail } from '@aisekisan/anya-api'
import { FieldInfo } from '../../field-info'
import { OrgEdit } from './edit'
import { T } from '@/libs/intl/t'
import { useOrgRole } from '../member/role'

const useStyles = makeStyles({
  content: {
    gridTemplateColumns: 'repeat(4, 1fr)',
    display: 'grid',
    columnGap: tokens.spacingHorizontalL,
  },
})

interface Props {
  org: OrgDetail
}

export function OrgInformation(props: Props) {
  const { org } = props

  const styles = useStyles()

  const [edit, setEdit] = useState<boolean>(false)

  const role = useOrgRole(org.id)

  return (
    <Panel
      title={<T id="construction.information.overview" />}
      icon={<Info24Regular />}
      actions={(
        <div>
          {role === 'owner' ? <Button icon={<EditRegular />} onClick={() => setEdit(true)}>
            <T id="construction.information.edit" />
          </Button> : null}
        </div>
      )}
    >
      <div className={styles.content}>
        <FieldInfo
          icon={<Location16Regular />}
          label={<T id="org.field.address" />}
          value={org.address}
        />
        <FieldInfo
          icon={<Mail16Regular />}
          label={<T id="org.field.email" />}
          value={org.email}
        />
        <FieldInfo
          icon={<Phone16Regular />}
          label={<T id="org.field.phone" />}
          value={org.phone}
        />
        <FieldInfo
          icon={<Certificate16Regular />}
          label={<T id="org.field.tax-code" />}
          value={org.taxCode}
        />
      </div>
      {edit && <OrgEdit org={org} close={() => setEdit(false)} />}
    </Panel>
  )
}
