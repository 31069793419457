import type { PipeLineSegment, PipeLineSegmentExtension, PipeLineSegmentFlat, PipeLineSegmentVertical } from '@aisekisan/anya-api'
import type { AnnotShapeColor } from '../shape/color'
import type { SegmentExtensionPartial } from './extension/shape/shape'
import { fromServerSegmentExtension } from './extension/shape/shape'
import type { SegmentFlatPartial } from './flat/shape'
import { fromServerSegmentFlat } from './flat/shape'
import type { SegmentVerticalPartial } from './vertical/shape/shape'
import { fromServerSegmentVertical } from './vertical/shape/shape'

export type SegmentPartial =
  | SegmentFlatPartial
  | SegmentVerticalPartial
  | SegmentExtensionPartial

export function fromServerSegment(props: {
  color: AnnotShapeColor
  group: string
}) {
  const { color, group } = props

  return (segment: PipeLineSegment): SegmentPartial => {
    switch (segment.type) {
      case 'Flat': {
        const server = segment.inner as PipeLineSegmentFlat
        return fromServerSegmentFlat({ color, group, server })
      }
      case 'Vertical': {
        const server = segment.inner as PipeLineSegmentVertical
        return fromServerSegmentVertical({ color, group, server })
      }
      case 'Extension': {
        const server = segment.inner as PipeLineSegmentExtension
        return fromServerSegmentExtension({ color, group, server })
      }
      case 'Extra':
        throw new Error('@TODO')
    }
  }
}
