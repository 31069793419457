// This dictionary is going to be merged with the app dictionary in IntlProvider.
// To avoid duplicated keys, the keys in this dictionary should be prefixed with 'preset-property.'.
export const INTL_DICT_EN = {
  'preset-property.action.cancel': 'Cancel',
  'preset-property.action.delete': 'Delete',
  'preset-property.action.edit': 'Edit info',
  'preset-property.action.required_field': 'This field is required',
  'preset-property.action.select-one': 'Select one',
  'preset-property.children.delete.cancel': 'Cancel',
  'preset-property.children.delete.help': 'Are you sure you want to delete this child property?',
  'preset-property.children.delete.submit': 'Delete',
  'preset-property.children.delete.title': 'Delete child property',
  'preset-property.children.delete': 'Delete',
  'preset-property.children.label': 'Children property',
  'preset-property.copy.equipment': 'Copy to another equipment',
  'preset-property.copy.help': 'Select the target equipment where you want to paste the copied property.',
  'preset-property.copy.submit': 'Copy',
  'preset-property.insul-size.delete.help': 'Are you sure you want to delete this insulation size property? This will delete all associated data.',
  'preset-property.insul-size.delete.submit': 'Delete',
  'preset-property.insul-size.delete': 'Delete insulation size property',
  'preset-property.insul-size.equip.hint': 'For different equipments, create a new insulation size property. Selections are final and cannot be changed.',
  'preset-property.insul-size.equip': 'Equipment',
  'preset-property.insul-size.from': 'From',
  'preset-property.insul-size.size': 'Size',
  'preset-property.insul-size.thickness': 'Thickness',
  'preset-property.insul-size.to': 'To',
  'preset-property.insul.cover.hint': 'For different cover materials, create a new insulation property. Selections are final and cannot be changed.',
  'preset-property.insul.cover': 'Cover material',
  'preset-property.insul.delete.help': 'Are you sure you want to delete this insulation property? This will delete all associated data.',
  'preset-property.insul.delete.submit': 'Delete',
  'preset-property.insul.delete': 'Delete insulation property',
  'preset-property.insul.inner': 'Inner material',
  'preset-property.locale.jp': 'Japanese',
  'preset-property.locale.us': 'U.S.English',
  'preset-property.market.jp': 'Japan',
  'preset-property.market.us': 'United States',
  'preset-property.node.delete.help.1': 'All generic properties',
  'preset-property.node.delete.help.2': 'All size properties',
  'preset-property.node.delete.help': 'Are you sure you want to delete this option? This will delete all associated data, including:',
  'preset-property.node.delete.submit': 'Delete option',
  'preset-property.node.delete': 'Delete option',
  'preset-property.node.menu.children': 'Generic property',
  'preset-property.node.menu.insul-size': 'Insulation size property',
  'preset-property.node.menu.insul': 'Insulation property',
  'preset-property.node.menu.size': 'Size property',
  'preset-property.node.menu': 'Create property',
  'preset-property.option.create.hint.1': 'Editors can only select from these options. Make sure to specify all necessary options for your construction project.',
  'preset-property.option.create.hint.2': 'To create a new property when an option is selected, click the “…” icon next to that option.',
  'preset-property.option.create': 'Create option',
  'preset-property.preset.create.submit': 'Create',
  'preset-property.preset.create': 'Create preset',
  'preset-property.preset.delete.helper.1.1': ' ? This action will remove all associated data, including:',
  'preset-property.preset.delete.helper.1': 'Are you sure you want to permanently delete the preset ',
  'preset-property.preset.delete.helper.2': 'New constructions cannot use this preset . However, this does not impact any existing constructions.',
  'preset-property.preset.delete.helper.3': 'This action cannot be undone. To proceed, please type the name of the preset to confirm deletion:',
  'preset-property.preset.delete.list.1': 'All custom equipments',
  'preset-property.preset.delete.list.2': 'All custom properties',
  'preset-property.preset.delete.submit': 'Delete preset',
  'preset-property.preset.delete.validation': 'Preset name',
  'preset-property.preset.delete': 'Delete preset',
  'preset-property.preset.description': 'Description',
  'preset-property.preset.label': 'Preset',
  'preset-property.preset.locale': 'Locale',
  'preset-property.preset.market': 'Market',
  'preset-property.preset.name': 'Name',
  'preset-property.preset.overview': 'Overview',
  'preset-property.preset.update.submit': 'Save changes',
  'preset-property.preset.update': 'Update preset',
  'preset-property.preset.view': 'View',
  'preset-property.property.delete.help': 'Are you sure you want to delete this property?',
  'preset-property.property.delete.submit': 'Delete',
  'preset-property.property.delete': 'Delete property',
  'preset-property.property.duplicate': 'Duplicate',
  'preset-property.property.equip-name.hint': 'Property equipment name can not be changed.',
  'preset-property.property.equip-name': 'Equipment name',
  'preset-property.property.equip.hint': 'Property equipment cannot be changed. However, you can copy this property to another equipment.',
  'preset-property.property.equip': 'Equipment',
  'preset-property.property.group': 'When selected',
  'preset-property.property.menu.children': 'Create generic property',
  'preset-property.property.menu.insul-size': 'Create insulation size property',
  'preset-property.property.menu.insul': 'Create insulation property',
  'preset-property.property.menu.size': 'Create size property',
  'preset-property.property.options': 'Options',
  'preset-property.property.overview': 'Overview',
  'preset-property.property.view': ' View dependent properties',
  'preset-property.property.when': 'When ',
  'preset-property.shape.box.height': 'Height',
  'preset-property.shape.box.length': 'Length',
  'preset-property.shape.box.width': 'Width',
  'preset-property.shape.box': 'Box Shape',
  'preset-property.shape.circle.diameter': 'Diameter',
  'preset-property.shape.circle': 'Circle',
  'preset-property.shape.custom.size': 'Size',
  'preset-property.shape.custom': 'Custom Shape',
  'preset-property.shape.delete': 'Delete',
  'preset-property.shape.duplicate': 'Duplicate',
  'preset-property.shape.electric-power.kW': 'Kilowatt',
  'preset-property.shape.electric-power': 'Electric Power',
  'preset-property.shape.rect.height': 'Height',
  'preset-property.shape.rect.width': 'Width',
  'preset-property.shape.rectangle': 'Rectangle',
  'preset-property.shape.refrigerant-pipe.liquid': 'Liquid pipe',
  'preset-property.shape.refrigerant-pipe.suction': 'Suction pipe',
  'preset-property.shape.refrigerant-pipe': 'Refrigerant Pipe Shape',
  'preset-property.size.delete.help': 'Are you sure you want to delete this size property? This will delete all associated data.',
  'preset-property.size.delete.submit': 'Delete size property',
  'preset-property.size.delete.title': 'Delete size property',
  'preset-property.size.label.hint': 'Label cannot be changed when the property type is “X”. This label is automatically translated.',
  'preset-property.size.label': 'Label',
  'preset-property.size.shape.hint': 'The size options need to follow this shape.',
  'preset-property.size.shape': 'Shape',
  'preset-property.size.type.hint': 'Property type cannot be changed.',
  'preset-property.size.type': 'Type',
  'preset-property.size.unit': 'Unit',
  'preset-property.size': 'Size',
  'preset-property.treeType.any': 'Custom',
  'preset-property.treeType.cArea': 'Construction area',
  'preset-property.treeType.material': 'Material',
  'preset-property.unit.cubic_meter': '㎥',
  'preset-property.unit.kilowatt': 'kW',
  'preset-property.unit.m': 'm',
  'preset-property.unit.mm': 'mm',
  'preset-property.unit.model': 'Model',
  'preset-property.unit.packet': 'Packet',
  'preset-property.unit.phi': 'Φ',
  'preset-property.unit.piece': 'Piece',
  'preset-property.unit.square_meter': '㎡',
}
