import {
  Button,
  OverlaySpinner,
  Title3,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import { Delete20Regular, Edit20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useState } from 'react'
import type { CategoryDetail } from '@aisekisan/anya-api'
import { useCategoryDetail, useCategoryList, useConstructionDetail, useOrgDetail } from '@aisekisan/anya-api'
import { AppPage } from '@/AppPage'
import { CategoryDetailComp } from '@/components/category/detail'
import { getCategoryPathLabel } from '@/components/category/utils'
import { useT } from '@/libs/intl/useT'
import { CategoryEdit } from '@/components/category/rename'
import { NotFound } from '@/components/not-found'
import { CategoryDelete } from '@/components/category/delete'
import { T } from '@/libs/intl/t'
import { useConstructionRole } from '@/components/construction/member/role'

const useStyles = makeStyles({
  title: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalL,
    alignItems: 'flex-start',
  },
  code: {
    color: tokens.colorNeutralForeground3,
  },
  container: {
    width: '100%',
  },
})

export function CategoryDetailPage(props: { id: string }): JSX.Element {
  const { id } = props

  const t = useT()
  const styles = useStyles()

  const [edit, setEdit] = useState<CategoryDetail | null>(null)
  const [deleteCategory, setDeleteCategory] = useState<boolean>(false)

  const detail = useCategoryDetail({ categoryID: id })
  const category = detail.data

  const list = useCategoryList({
    constructionID: detail.data?.constructionID ?? null,
  }).data ?? []
  const categoryPath = list.find(category => category.value.id === id)?.path

  const org = useOrgDetail({ orgID: category?.orgID ?? '' }).data

  const construction = useConstructionDetail({
    constructionID: category?.constructionID ?? '',
  }).data

  const constructionRole = useConstructionRole(category?.constructionID ?? '')

  const getCategoryTitle = (): ReactElement => {
    if (!category) {
      return (
        <Title3 className={styles.code}>
          <T id="category.label" />
        </Title3>
      )
    }

    return (
      <div className={styles.title}>
        <Title3 className={styles.code}>
          {getCategoryPathLabel(categoryPath ?? [])}
        </Title3>
        <Title3>{category?.name}</Title3>
        {constructionRole !== 'viewer' ? (
          <>
            <Button icon={<Edit20Regular />} onClick={() => setEdit(category ?? null)}><T id="category.information.edit" /></Button>
            <Button icon={<Delete20Regular />} onClick={() => setDeleteCategory(true)}><T id="category.action.delete" /></Button>
          </>) : null
        }
      </div>
    )
  }

  if (detail.isLoading)
    return <OverlaySpinner visible={detail.isLoading} />

  return (
    <AppPage
      breadcrumbs={category
        ? [
          {
            title: `${t('org.title')} ${org?.name}`,
            href: `/org/${org?.id}`,
          },
          {
            title: `${t('construction.title')} ${construction?.name}`,
            href: `/construction/${construction?.id}`,
          },
          {
            title: `${t('category.label')} ${category.name}`,
            href: '',
          },
        ]
        : []}
      title={getCategoryTitle()}
    >
      {detail.isError && <NotFound />}

      <div className={styles.container}>
        {category && construction
          && (
            <>
              {edit && <CategoryEdit category={category} close={() => setEdit(null)} />}
              {deleteCategory && <CategoryDelete close={() => setDeleteCategory(false)} constructionID={category.constructionID} category={category} />}
              <CategoryDetailComp category={category} construction={construction} />
            </>
          )}
      </div>
    </AppPage>
  )
}
