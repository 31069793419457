import type { TLShape } from '@tldraw/tldraw'
import type { AnnotMetaBase, AnnotShapeBase } from '../shape/base'
import { createAnnotMetaBase, isAnnotShapeBase } from '../shape/base'

export type SegmentMetaBase = AnnotMetaBase & {
  annot: 'segment'
}

type SegmentShapeBase = AnnotShapeBase & {
  meta: SegmentMetaBase
}

export function createSegmentMeta(props: {
  group: string
}): SegmentMetaBase {
  const { group } = props

  const annot = createAnnotMetaBase({ group })
  return { ...annot, annot: 'segment' }
}

export function isSegmentShapeBase(
  shape: TLShape,
): shape is SegmentShapeBase {
  if (!isAnnotShapeBase(shape))
    return false
  const test = shape as SegmentShapeBase
  return test.meta.annot === 'segment'
}
