import { Button, Caption1, Field, Input, Label, Select, Subtitle2, makeStyles, shorthands, tokens } from '@aisekisan/bond'
import { AddCircle20Regular, Delete20Regular, Ruler20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useState } from 'react'
import type { ShapeType, Unit } from '../../type'
import type { EquipmentAttributeTreeNode } from '../type'
import { useT } from '../../libs/intl/useT'
import { T } from '../../libs/intl/t'
import { ShapeForm } from './form/form'
import { addDataShape, updateDataShape } from './utils'
import { DeleteSizeProperty } from './delete'
import { SHAPES, UNIT } from './options'
import type { CustomShapeOptions, DataShapes } from './type'

const useStyles = makeStyles({
  children: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    backgroundColor: tokens.colorNeutralForegroundOnBrand,
    ...shorthands.padding(tokens.spacingVerticalL),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.spacingHorizontalS,
  },
  actionGroup: {
    alignSelf: 'end',
  },
  hint: {
    color: tokens.colorNeutralForeground3,
  },
})

interface Props {
  onAdd: (shape: CustomShapeOptions, node: EquipmentAttributeTreeNode) => void
  onRemove: () => void
  node: EquipmentAttributeTreeNode
}

export function SizeProperty(props: Props): ReactElement | null {
  const { node, onAdd, onRemove } = props

  const t = useT()
  const styles = useStyles()

  const [deleteProperty, setDeleteProperty] = useState<boolean>(false)

  if (!node.shapeOptions || node.shapeOptions.shape === 'InsulationThickness')
    return null

  const onChangeKind = (shapeKind: string) => {
    if (node.shapeOptions === undefined)
      return

    const shapeOpts = { ...node.shapeOptions, shape: shapeKind as ShapeType }
    onAdd(shapeOpts, node)
  }

  const onChangeUnit = (unit: string) => {
    if (node.shapeOptions === undefined)
      return

    const shapeOpts = { ...node.shapeOptions, unit: unit as Unit }
    onAdd(shapeOpts, node)
  }

  const onUpdateDataShape = (value: DataShapes) => {
    if (node.shapeOptions === undefined)
      return

    const shapeOpts = updateDataShape(node.shapeOptions, value)
    onAdd(shapeOpts, node)
  }

  const onAddDataShape = (shapeOptions: CustomShapeOptions | undefined): void => {
    if (shapeOptions === undefined)
      return

    const shapeOpts = addDataShape(shapeOptions)
    onAdd(shapeOpts, node)
  }

  return (
    <div className={styles.children}>
      <div className={styles.title}>
        <Ruler20Regular />
        <Subtitle2>
          <T id="preset-property.node.menu.size" />
        </Subtitle2>
      </div>
      <Field
        label={t('preset-property.size.label')}
        hint={t('preset-property.size.label.hint')}
      >
        <Input
          appearance="filled-darker"
          value={t('preset-property.insul-size.size')}
          readOnly
        />
      </Field>
      <Field
        label={t('preset-property.size.shape')}
        hint={t('preset-property.size.shape.hint')}
      >
        <Select
          defaultValue={node.shapeOptions.shape}
          onChange={e => onChangeKind(e.target.value)}
          appearance="filled-darker"
        >
          {SHAPES.map(shape => (
            <option value={shape.id} key={shape.id}>
              <T id={shape.label} />
            </option>
          ))}
        </Select>
      </Field>
      <Field label={t('preset-property.size.unit')}>
        <Select
          defaultValue={node.shapeOptions.unit ?? ''}
          onChange={e => onChangeUnit(e.target.value)}
          appearance="filled-darker"
        >
          <option value="" disabled>
            <T id="preset-property.action.select-one" />
          </option>
          {UNIT.map(unit => (
            <option value={unit.value} key={unit.value}>
              <T id={unit.label} />
            </option>
          ))}
        </Select>
      </Field>
      <Label>
        <T id="preset-property.property.options" />
      </Label>
      {node.shapeOptions && (
        <ShapeForm
          shapeOptions={node.shapeOptions}
          onUpdateDataShape={onUpdateDataShape}
        />
      )}
      <div>
        <Button
          icon={<AddCircle20Regular />}
          onClick={() => onAddDataShape(node.shapeOptions)}
        >
          <T id="preset-property.option.create" />
        </Button>
      </div>
      <Caption1 className={styles.hint}>
        <T id="preset-property.option.create.hint.1" />
      </Caption1>
      <div className={styles.actionGroup}>
        <Button icon={<Delete20Regular />} onClick={() => setDeleteProperty(true)}>
          <T id="preset-property.children.delete" />
        </Button>
      </div>
      {deleteProperty && <DeleteSizeProperty close={() => setDeleteProperty(false)} onDelete={onRemove} />}
    </div>
  )
}
