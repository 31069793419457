import type { ReactElement } from 'react'
import { useState } from 'react'
import { Body1, Button, Panel, makeStyles, shorthands, tokens } from '@aisekisan/bond'
import { People24Regular, PersonAddRegular } from '@fluentui/react-icons'
import { type OrgInvitationDetail, type OrgMemberDetail, useAccountInfo, useOrgMemberList } from '@aisekisan/anya-api'
import { OrgMemberRemove } from './remove'
import { OrgMemberTable } from './table'
import { InviteMemberCreate } from './invite'
import { InvitationRevoke } from './revoke-invitation'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  table: {
    marginBottom: tokens.spacingVerticalL,
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.padding(tokens.spacingHorizontalL),
    // Override shorthand padding
    paddingTop: 0,
  },
  help: {
    color: tokens.colorNeutralForeground3,
  },
})

interface Props {
  orgID: string
}

export function OrgMemberList(props: Props): ReactElement {
  const { orgID } = props

  const styles = useStyles()

  const [add, setAdd] = useState<boolean>(false)
  const [remove, setRemove] = useState<OrgMemberDetail | null>(null)
  const [revoke, setRevoke] = useState<OrgInvitationDetail | null>(null)

  const account = useAccountInfo().data
  const list = useOrgMemberList({ orgID }).data ?? []

  const role = list.find(member => member.email === account?.email)?.role ?? 'member'

  return (
    <>
      <Panel
        title={<T id="construction.member.list.title" />}
        icon={<People24Regular />}
        actions={(
          <Button
            icon={<PersonAddRegular />}
            onClick={() => setAdd(true)}
          >
            <T id="org.invitation.list.invite" />
          </Button>
        )}
      >
        <Panel className={styles.table}>
          <div>
            <OrgMemberTable
              locale={account?.locale ?? 'ja'}
              role={role}
              memberList={list}
              orgID={orgID}
              setRemove={setRemove}
              setRevokeInvitation={setRevoke}
            />
          </div>
        </Panel>
        <Body1 className={styles.help}>
          <T id="org.invitation.invite.guideline" />
          <ul style={{ margin: 0 }}>
            <li>
              <T id="org.invitation.invite.guideline.1" />
            </li>
            <li>
              <T id="org.invitation.invite.guideline.2" />
            </li>
          </ul>
        </Body1>
      </Panel>
      {add && (
        <InviteMemberCreate
          orgID={orgID}
          close={() => setAdd(false)}
          role={role}
        />
      )}
      {remove !== null && (
        <OrgMemberRemove
          member={remove}
          orgID={orgID}
          close={() => setRemove(null)}
        />
      )}
      {revoke && (
        <InvitationRevoke
          invitation={revoke}
          orgID={orgID}
          close={() => setRevoke(null)}
        />
      )}
    </>
  )
}
