import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { server } from '../server'

const KEY_ALL = ['construction']
const KEY_LIST = [...KEY_ALL, 'list']
const KEY_DETAIL = [...KEY_ALL, 'detail']

export function useConstructionList(params: { orgID: string | null }) {
  const { orgID } = params
  return useQuery({
    queryKey: [...KEY_LIST, orgID],
    queryFn: () => {
      if (orgID === null)
        throw new Error('orgID is null')
      return server.listConstructions(orgID, 0, 200)
    },
    enabled: orgID !== null,
  })
}

export function useConstructionDetail(params: { constructionID: string }) {
  const { constructionID } = params

  return useQuery({
    queryKey: [...KEY_DETAIL, constructionID],
    queryFn: () => server.getConstruction(constructionID),
    enabled: constructionID !== '',
  })
}

type ConstructionCreateBody = Omit<
  Parameters<typeof server.createConstruction>[0],
  'orgID'
>

export function useConstructionCreate(init: { orgID: string }) {
  const { orgID } = init
  const client = useQueryClient()

  return useMutation({
    mutationFn: (body: ConstructionCreateBody) => {
      return server.createConstruction({ orgID, ...body })
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: [...KEY_LIST, orgID] })
    },
  })
}

type ConstructionUpdateBody = Parameters<typeof server.updateConstruction>[1]

export function useConstructionUpdate(init: { constructionID: string }) {
  const { constructionID } = init
  const client = useQueryClient()

  return useMutation({
    mutationFn: (body: ConstructionUpdateBody) => {
      return server.updateConstruction(constructionID, body)
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: KEY_ALL })
    },
  })
}

export function useConstructionDelete() {
  const client = useQueryClient()

  return useMutation({
    mutationFn: (constructionID: string) => {
      return server.deleteConstruction(constructionID)
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: KEY_ALL })
    },
  })
}
