import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker?url'

GlobalWorkerOptions.workerSrc = pdfjsWorker

interface Params {
  canvas: HTMLCanvasElement
  url: string
}

export async function initPdf(params: Params): Promise<void> {
  const { canvas, url } = params

  const pdf = await getDocument({ url }).promise
  const page = await pdf.getPage(1)
  const viewport = page.getViewport({ scale: 8 })

  const canvasContext = canvas.getContext('2d')
  if (!canvasContext)
    throw new Error('Incorrect canvas context')

  canvas.height = viewport.height
  canvas.width = viewport.width

  const renderContext = { canvasContext, viewport }
  await page.render(renderContext).promise

  await pdf.cleanup()
  await pdf.destroy()
}
