import { OverlaySpinner } from '@aisekisan/bond'
import { useConstructionDetail, useEquipmentAttributeTreeDetail, useOrgDetail } from '@aisekisan/anya-api'
import { AppPage } from '@/AppPage'
import { useT } from '@/libs/intl/useT'
import { NotFound } from '@/components/not-found'
import { ConstructionEquipmentPropertyDetail } from '@/components/construction/property/detail'

export function ConstructionPropertyDetailPage(props: {
  treeID: string
}): JSX.Element {
  const { treeID } = props

  const t = useT()

  const { data: detail, isLoading, isError } = useEquipmentAttributeTreeDetail({ treeID })

  const { data: construction } = useConstructionDetail({ constructionID: detail?.constructionID ?? '' })

  const { data: org } = useOrgDetail({ orgID: detail?.orgID ?? '' })

  if (isLoading)
    return <OverlaySpinner visible />

  const breadcrumbs = [
    {
      title: `${t('org.title')} ${org?.name}`,
      href: `/org/${org?.id}`,
    },
    {
      title: `${t('construction.title')} ${construction?.name}`,
      href: `/construction/${construction?.id}`,
    },
    {
      title: `${t('preset.equipment.property.label')}`,
      href: ``,
    },
  ]

  return (
    <AppPage breadcrumbs={detail ? breadcrumbs : []}>
      {isError && <NotFound />}
      {detail && construction
        ? (
          <ConstructionEquipmentPropertyDetail
            equipmentAttributeTree={detail}
            constructionID={construction.id}
          />
          )
        : null}
    </AppPage>
  )
}
