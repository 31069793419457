import { Button, makeStyles, tokens, typographyStyles } from '@aisekisan/bond'

import { T } from '@/libs/intl/t'
import { useLocation } from '@/hooks/use-location'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  errorCode: {
    ...typographyStyles.largeTitle,
    color: tokens.colorNeutralForeground2BrandHover,
    letterSpacing: '4px',
    fontWeight: 'semibold',
  },
})
export function PageNotFound(): JSX.Element {
  const styles = useStyles()
  const { navigate } = useLocation()

  return (
    <div className={styles.root}>
      <p className={styles.errorCode}>
        <T id="page-not-found" />
      </p>
      <Button onClick={() => void navigate('/home')} appearance="primary">
        Back to the homepage
      </Button>
    </div>
  )
}
