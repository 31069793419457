import { OverlaySpinner, makeStyles, tokens } from '@aisekisan/bond'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import type { PageDetail } from '@aisekisan/anya-api'
import { useReportAnnotations } from '@aisekisan/anya-api'
import { ANNOTATION_TYPE } from '../utils'
import { PageCard } from './card'
import { EmptyContent } from '@/components/empty-content'

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: tokens.spacingHorizontalL,
    columnGap: tokens.spacingHorizontalL,
    position: 'relative',
  },
})

interface Props {
  pages: PageDetail[]
  constructionID: string
  categoryID: string
  equipmentClass: string
  annotation: string
  selectable: boolean
  selected: string[]
  setSelected: Dispatch<SetStateAction<string[]>>
}

export function PageCardList(props: Props) {
  const { equipmentClass, pages, constructionID, categoryID, annotation, selectable, selected, setSelected } = props

  const styles = useStyles()

  const [data, setData] = useState<PageDetail[]>(pages)

  const reportAnnotations = useReportAnnotations({
    constructionID,
    categoryID,
    equipmentClass: equipmentClass
      ? Number(equipmentClass)
      : undefined,
  })

  useEffect(() => {
    const pageWithAnnotationIds = (reportAnnotations.data ?? []).map(
      annotation => annotation.pageID,
    )
    switch (annotation) {
      case ANNOTATION_TYPE.WITH_ANNOTATIONS: {
        const pageWithAnnotation = pages.filter(page =>
          pageWithAnnotationIds.includes(page.id),
        )
        setData(pageWithAnnotation)
        break
      }
      case ANNOTATION_TYPE.WITHOUT_ANNOTATIONS: {
        const pageWithoutAnnotation = pages.filter(
          page => !pageWithAnnotationIds.includes(page.id),
        )
        setData(pageWithoutAnnotation)
        break
      }
      case ANNOTATION_TYPE.ALL:
      default: {
        setData(pages)
        break
      }
    }
  }, [annotation, pages, reportAnnotations.data])

  return (
    <div className={styles.container}>
      { reportAnnotations.isFetching
        ? <OverlaySpinner visible={reportAnnotations.isFetching} appearance="primary" />
        : (
          <div className={styles.container}>
            {data.length > 0
              ? (
                <div className={styles.container}>
                  {data.map(page => (
                    <PageCard
                      page={page}
                      key={page.id}
                      categoryID={categoryID}
                      setSelected={setSelected}
                      selected={selected}
                      selectable={selectable}
                    />
                  ))}
                </div>
                )
              : <EmptyContent />}
          </div>
          )}
    </div>
  )
}
