import { Button, Caption1, Panel, makeStyles, tokens } from '@aisekisan/bond'
import { EditRegular, Info24Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import type { GlobalPresetDetail, OrgPresetDetail } from '../../type'
import { T } from '../../libs/intl/t'
import { DisplayMarket } from './market'
import { DisplayLocale } from './locale'

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: '100px auto',
    rowGap: tokens.spacingVerticalL,
    alignItems: 'center',
  },
  label: {
    color: tokens.colorNeutralForeground3,
  },
})

interface Props {
  preset: OrgPresetDetail | GlobalPresetDetail
  setEditPreset: (editPreset: boolean) => void
}

export function PresetOverview(props: Props): ReactElement | null {
  const { preset, setEditPreset } = props

  const styles = useStyles()

  return (
    <Panel
      title={<T id="preset-property.preset.overview" />}
      icon={<Info24Regular />}
      actions={(
        <Button icon={<EditRegular />} onClick={() => setEditPreset(true)}>
          <T id="preset-property.action.edit" />
        </Button>
      )}
    >
      <div className={styles.container}>
        <Caption1 className={styles.label}>
          <T id="preset-property.preset.market" />
        </Caption1>
        <DisplayMarket market={preset.market} />

        <Caption1 className={styles.label}>
          <T id="preset-property.preset.locale" />
        </Caption1>
        <DisplayLocale locale={preset.locale} />

        <Caption1 className={styles.label}>
          <T id="preset-property.preset.description" />
        </Caption1>
        <span>{preset.description}</span>
      </div>
    </Panel>
  )
}
