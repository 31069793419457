import type { ReactElement } from 'react'
import type { ReportHead, ReportRow } from '../../type'
import { FIRE_REPORT_FIXED_COLS, getFireReportEmptyCols } from '../utils'

interface Props {
  rows: ReportRow[]
  reportHeads: ReportHead[]
  equipments: string
}

export function FirePieceReportRow(props: Props): ReactElement | null {
  const { rows, reportHeads, equipments } = props

  const totalCols = reportHeads.length > FIRE_REPORT_FIXED_COLS
    ? reportHeads.length
    : FIRE_REPORT_FIXED_COLS

  if (rows.length === 0) {
    return (
      <tr>
        {/* Equipments */}
        <td>{equipments}</td>
        {/* CA */}
        <td></td>
        {/* Length */}
        {Array.from(
          { length: totalCols },
          (_, index) => <td key={index}></td>,
        )}
        {/* Sub total */}
        <td></td>
        {/* Total */}
        <td></td>
      </tr>
    )
  }

  return (
    <>
      {rows.map((row, index) => {
        const lengths = rows.map((row) => {
          return reportHeads.map(head => getLength(row, head))
        })
        return (
          <tr key={row.id}>
            {/* Equipments */}
            {index === 0 && (
              <td rowSpan={rows.length}>
                {equipments}
              </td>
            )}
            {/* CA */}
            <td align="center">{row.cArea}</td>
            {/* Length */}
            {lengths[index].map((length, index) => {
              // Reports are read-only, so we can use index as key
              // eslint-disable-next-line react/no-array-index-key
              return <td key={index}>{length !== 0 ? length : ''}</td>
            })}
            {/* Empty cols */}
            {getFireReportEmptyCols(totalCols - reportHeads.length)}
            {/* Sub total */}
            <td align="center">
              {getTotal(lengths[index]).toFixed(1)}
            </td>
            {/* Total */}
            {index === 0 && (
              <td align="center" rowSpan={rows.length}>
                {getTotal(Object.values(lengths).flat()).toFixed(1)}
              </td>
            )}
          </tr>
        )
      })}
    </>
  )
}

function getLength(row: ReportRow, head: ReportHead): number {
  const { pageID, modules } = head
  if (modules.length === 0)
    return Number.parseFloat(row[pageID] ?? '0')
  return modules.reduce<number>((sum, cmodule) => {
    const key = cmodule ? `${pageID}/${cmodule.id}` : `${pageID}/no_module`
    return row[key] ? sum + Number.parseFloat(row[key]) : sum
  }, 0)
}

function getTotal(lengths: number[]): number {
  return lengths.reduce((acc, length) => acc + length, 0)
}
