import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { ConstructionRole } from '../generated'
import { server } from '../server'

const KEY = 'construction-member'

export function useConstructionMemberList(params: {
  constructionID: string
}) {
  const { constructionID } = params
  return useQuery({
    queryKey: [KEY, constructionID],
    queryFn: () => server.listMembersInConstruction(constructionID, 0, 200),
    enabled: constructionID !== ''
  })
}

export type ConstructionMemberAddBody = Parameters<
  typeof server.addMemberToConstruction
>[1]

export function useConstructionMemberAdd(params: {
  constructionID: string
}) {
  const { constructionID } = params
  const client = useQueryClient()

  return useMutation({
    mutationFn: (body: ConstructionMemberAddBody) => {
      return server.addMemberToConstruction(constructionID, body)
    },
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: [KEY, constructionID],
      })
    },
  })
}

export function useConstructionMemberRemove(params: {
  constructionID: string
}) {
  const { constructionID } = params
  const client = useQueryClient()

  return useMutation({
    mutationFn: (accountID: string) => {
      return server.removeMemberFromConstruction(constructionID, { accountID })
    },
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: [KEY, constructionID],
      })
    },
  })
}

export function useConstructionMemberUpdateRole(init: {
  constructionID: string
  accountID: string
}) {
  const { constructionID, accountID } = init
  const client = useQueryClient()

  return useMutation({
    mutationFn: (role: ConstructionRole) => {
      return server.updateRoleForMemberInConstruction(constructionID, {
        accountID,
        constructionRole: role,
      })
    },
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: [KEY, constructionID],
      })
    },
  })
}
