import { useForm } from 'react-hook-form'
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Input, OverlaySpinner, Select, makeStyles, tokens } from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import type { AccountInfo, AccountInfoUpdateBody } from '@aisekisan/anya-api'
import { useAccountInfoUpdate } from '@aisekisan/anya-api'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
})

interface Props {
  accountInfo: AccountInfo
  close: () => void
}

export function AccountInformationEdit(props: Props): JSX.Element {
  const { accountInfo, close } = props

  const t = useT()
  const styles = useStyles()

  const form = useForm <AccountInfoUpdateBody>({ defaultValues: accountInfo })

  const { formState: { errors } } = form

  const edit = useAccountInfoUpdate()

  const onSubmit = form.handleSubmit((values) => {
    edit.mutate(values, { onSuccess: close })
  })

  return (
    <Dialog open onOpenChange={close}>
      <DialogSurface>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <OverlaySpinner visible={edit.isPending} appearance="primary" />
            <DialogTitle
              action={(
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    onClick={close}
                  />
                </DialogTrigger>
              )}
            >
              <T id="account-info.update.title" />
            </DialogTitle>
            <DialogContent className={styles.content}>
              <Field
                label={t('account.field.first-name')}
                validationState={errors.firstName ? 'error' : 'none'}
                validationMessage={errors.firstName?.message}
              >
                <Input
                  {...form.register('firstName')}
                  appearance="filled-darker"
                  size="large"
                />
              </Field>
              <Field
                label={t('account.field.last-name')}
                validationState={errors.lastName ? 'error' : 'none'}
                validationMessage={errors.lastName?.message}
              >
                <Input
                  {...form.register('lastName')}
                  appearance="filled-darker"
                  size="large"
                />
              </Field>
              <Field
                label={t('account.field.first-name-kana')}
                validationState={errors.firstNameKana ? 'error' : 'none'}
                validationMessage={errors.firstNameKana?.message}
              >
                <Input
                  {...form.register('firstNameKana')}
                  appearance="filled-darker"
                  size="large"
                />
              </Field>
              <Field
                label={t('account.field.last-name-kana')}
                validationState={errors.lastNameKana ? 'error' : 'none'}
                validationMessage={errors.lastNameKana?.message}
              >
                <Input
                  {...form.register('lastNameKana')}
                  appearance="filled-darker"
                  size="large"
                />
              </Field>
              <Field
                label={t('account.field.email')}
              >
                <Input
                  value={accountInfo.email}
                  appearance="filled-darker"
                  disabled
                  size="large"
                />
              </Field>
              <Field
                label={t('account.field.company-name')}
                validationState={errors.companyName ? 'error' : 'none'}
                validationMessage={errors.companyName?.message}
              >
                <Input
                  {...form.register('companyName')}
                  appearance="filled-darker"
                  size="large"
                />
              </Field>
              <Field
                label={t('account.field.phone')}
                validationState={errors.phone ? 'error' : 'none'}
                validationMessage={errors.phone?.message}
              >
                <Input
                  type="number"
                  {...form.register('phone')}
                  appearance="filled-darker"
                  size="large"

                />
              </Field>
              <Field
                label={t('account.field.locale')}
                validationState={errors.locale ? 'error' : 'none'}
                validationMessage={errors.locale?.message}
              >
                <Select
                  {...form.register('locale')}
                  appearance="filled-darker"
                  size="large"
                >
                  <option value="en">English</option>
                  <option value="ja">Japanese</option>
                </Select>
              </Field>
            </DialogContent>
            <DialogActions>
              <Button appearance="primary" type="submit">
                <T id="account-info.update.submit" />
              </Button>
              <Button onClick={close}>
                <T id="dialog.action.cancel" />
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )
}
