import { Link, makeStyles } from '@aisekisan/bond'
import type { ReactElement } from 'react'
import type { ReportHead } from '../type'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  default: {
    textAlign: 'center',
    minWidth: '50px',
  },
  equip: {
    textAlign: 'center',
    minWidth: '140px',
  },
  material: {
    textAlign: 'center',
    minWidth: '150px',
  },
  insul: {
    textAlign: 'center',
    minWidth: '100px',
  },
  page: {
    textAlign: 'center',
    minWidth: '80px',
  },
  total: {
    textAlign: 'center',
    minWidth: '80px',
  },
  module: {
    textAlign: 'center',
    minWidth: '120px',
  },
})

const EDITOR_DOMAIN = import.meta.env.VITE_EDITOR_DOMAIN

interface Props {
  reportHeads: ReportHead[]
}

export function DefaultReportHeader(props: Props): ReactElement {
  const { reportHeads } = props
  const s = useStyles()

  return (
    <>
      <tr>
        <th rowSpan={2} className={s.equip}>
          <T id="report.header.object" />
        </th>
        <th rowSpan={2} className={s.material}>
          <T id="report.header.material" />
        </th>
        <th rowSpan={2} className={s.default}>
          <T id="report.header.type" />
        </th>
        <th rowSpan={2} className={s.default}>
          <T id="report.header.size" />
        </th>
        <th rowSpan={2} className={s.default}>
          <T id="report.header.construction_area" />
        </th>
        <th rowSpan={2} className={s.insul}>
          <T id="report.header.insulation" />
        </th>
        <th rowSpan={2} className={s.default}>
          <T id="report.header.unit" />
        </th>
        {/* Pages with annotations */}
        {reportHeads.map(({ pageID, pageName, modules }) => {
          return (
            <th
              key={pageID}
              colSpan={modules.length}
              rowSpan={modules.length > 0 ? 1 : 2}
              className={s.page}
            >
              <Link href={`//${EDITOR_DOMAIN}/?page=${pageID}`} target="_blank">
                <strong>{pageName}</strong>
              </Link>
            </th>
          )
        })}
        <th rowSpan={2} className={s.total}>
          <T id="report.header.total" />
        </th>
      </tr>
      {/* Page modules */}
      <tr>
        {reportHeads.flatMap(({ pageID, modules }) => {
          return modules.map((cmodule) => {
            if (!cmodule) {
              return (
                <th key={`${pageID}/no_module`} className={s.module}>
                  <T id="report.module.none" />
                  <span> (× 1)</span>
                </th>
              )
            }
            const { id, name, quantity } = cmodule
            return (
              <th key={`${pageID}/${id}`} className={s.module}>
                {`${name} (× ${quantity})`}
              </th>
            )
          })
        })}
      </tr>
    </>
  )
}
