import {
  Button,
  OverlaySpinner,
  Panel,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import {
  BuildingHome16Regular,
  EditRegular,
  Globe16Regular,
  Mail16Regular,
  Person16Regular,
  Person24Regular,
  Phone16Regular,
} from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { useAccountInfo } from '@aisekisan/anya-api'
import { NotFound } from '../not-found'
import { FieldInfo } from '../field-info'
import { AccountInformationEdit } from './edit'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  content: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    display: 'grid',
    columnGap: tokens.spacingHorizontalL,
    rowGap: tokens.spacingHorizontalL,
  },
})

export function AccountInformationDetail(): ReactElement {
  const styles = useStyles()

  const [edit, setEdit] = useState<boolean>(false)

  const { isError, isLoading, data: info } = useAccountInfo()

  return (
    <Panel
      title={<T id="account-info.title" />}
      icon={<Person24Regular />}
      actions={(
        <Button icon={<EditRegular />} onClick={() => setEdit(true)}>
          <T id="construction.information.edit" />
        </Button>
      )}
    >
      {isLoading && (
        <OverlaySpinner
          visible={isLoading}
          appearance="primary"
        />
      )}
      {isError && <NotFound />}
      {info && (
        <div className={styles.content}>
          <FieldInfo
            icon={<Person16Regular />}
            label={<T id="account.field.full-name" />}
            value={info.locale === 'en' ? `${info.firstName} ${info.lastName}` : `${info.lastName} ${info.firstName}`}
          />
          <FieldInfo
            icon={<Mail16Regular />}
            label={<T id="org.field.email" />}
            value={info.email}
          />
          <FieldInfo
            icon={<Phone16Regular />}
            label={<T id="org.field.phone" />}
            value={info.phone}
          />
          <FieldInfo
            icon={<Person16Regular />}
            label={<T id="account.field.full-name-kana" />}
            value={info.locale === 'en' ? `${info.firstNameKana} ${info.lastNameKana}` : `${info.lastNameKana} ${info.firstNameKana}`}
          />
          <FieldInfo
            icon={<BuildingHome16Regular />}
            label={<T id="account.field.company-name" />}
            value={info.companyName}
          />
          <FieldInfo
            icon={<Globe16Regular />}
            label={<T id="account.field.locale" />}
            value={info.locale === 'en' ? 'English' : 'Japanese'}
          />
        </div>
      )}
      {edit && info && (
        <AccountInformationEdit
          accountInfo={info}
          close={() => setEdit(false)}
        />
      )}
    </Panel>
  )
}
