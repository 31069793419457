import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Input, OverlaySpinner, Select, Textarea, makeStyles, tokens } from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { ReactElement, ReactNode } from 'react'
import { useT } from '../libs/intl/useT'
import { T } from '../libs/intl/t'
import type { PresetForm } from './type'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
})

interface Props {
  preset: PresetForm
  type: 'create' | 'update'
  isPending: boolean
  submit: (values: PresetForm) => void
  close: () => void
  errorAlert?: ReactNode
}

export function PresetFormDialog(props: Props): ReactElement {
  const { preset, type, isPending, submit, close, errorAlert } = props

  const t = useT()
  const styles = useStyles()

  const schema = z.object({
    description: z.string().min(1, { message: t('preset-property.action.required_field') }),
    locale: z.string().min(1, { message: t('preset-property.action.required_field') }),
    market: z.string().min(1, { message: t('preset-property.action.required_field') }),
    name: z.string().min(1, { message: t('preset-property.action.required_field') }),
  })

  const form = useForm<PresetForm>({
    defaultValues: preset,
    resolver: zodResolver(schema),
  })

  const { formState: { errors } } = form

  const onSubmit = form.handleSubmit((values: PresetForm) => {
    submit(values)
  })

  return (
    <Dialog open onOpenChange={close}>
      <DialogSurface>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <DialogTitle
              action={(
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    onClick={close}
                  />
                </DialogTrigger>
              )}
            >
              {type === 'create'
                ? <T id="preset-property.preset.create" />
                : <T id="preset-property.preset.update" />}
            </DialogTitle>
            <DialogContent className={styles.content}>
              {/* Loading */}
              <OverlaySpinner visible={isPending} appearance="primary" />

              {/* Error */}
              {errorAlert}

              <Field
                label={<T id="preset-property.preset.name" />}
                validationState={errors.name ? 'error' : 'none'}
                validationMessage={errors.name?.message}
              >
                <Input
                  size="large"
                  defaultValue={form.getValues('name')}
                  appearance="filled-darker"
                  {...form.register('name')}
                />
              </Field>
              <Field
                label={<T id="preset-property.preset.description" />}
                validationState={errors.description ? 'error' : 'none'}
                validationMessage={errors.description?.message}
              >
                <Textarea
                  size="large"
                  appearance="filled-darker"
                  rows={4}
                  {...form.register('description')}
                />
              </Field>
              <Field
                label={<T id="preset-property.preset.locale" />}
                validationState={errors.locale ? 'error' : 'none'}
                validationMessage={errors.locale?.message}
              >
                <Select
                  size="large"
                  appearance="filled-darker"
                  defaultValue={form.getValues('locale')}
                  {...form.register('locale')}
                >
                  <option value="en">
                    <T id="preset-property.locale.us" />
                  </option>
                  <option value="ja">
                    <T id="preset-property.locale.jp" />
                  </option>
                </Select>
              </Field>
              <Field
                label={t('preset-property.preset.market')}
                validationState={errors.market ? 'error' : 'none'}
                validationMessage={errors.market?.message}
              >
                <Select
                  size="large"
                  appearance="filled-darker"
                  defaultValue={form.getValues('market')}
                  {...form.register('market')}
                >
                  <option value="USA">
                    <T id="preset-property.market.us" />
                  </option>
                  <option value="JAPAN">
                    <T id="preset-property.market.jp" />
                  </option>
                </Select>
              </Field>
            </DialogContent>
            <DialogActions>
              <Button onClick={close}>
                <T id="preset-property.action.cancel" />
              </Button>
              <Button
                appearance="primary"
                type="submit"
              >
                {type === 'create'
                  ? <T id="preset-property.preset.create.submit" />
                  : <T id="preset-property.preset.update.submit" />}
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )
}
