import { Fragment, type ReactElement } from 'react'
import type { CategoryDetail, ConstructionDetail } from '@aisekisan/anya-api'
import { makeStyles, shorthands } from '@aisekisan/bond'
import type { ReportHead, ReportRow } from '../../type'
import { FirePipeReportOverview } from './overview'
import { FirePipeReportHeader } from './header'
import { FirePipeReportBody } from './body'

const useStyles = makeStyles({
  blank: {
    ...shorthands.border('none'),
  },
})

interface Props {
  construction: ConstructionDetail
  category: CategoryDetail
  reportHeads: ReportHead[]
  reportRows: ReportRow[]
}

export function FirePipeReport(props: Props): ReactElement | null {
  const { construction, category, reportHeads, reportRows } = props

  const s = useStyles()

  if (reportRows.length === 0)
    return null

  const groups = new Map<string, ReportRow[]>()

  reportRows.forEach((row) => {
    const key = [row.material1, row.material2].join('-')
    groups.set(key, [...(groups.get(key) ?? []), row])
  })

  return (
    <tbody>
      {[...groups.entries()].map(([id, rows]) => {
        return (
          <Fragment key={id}>
            <FirePipeReportOverview
              category={category}
              construction={construction}
            />
            <FirePipeReportHeader
              reportHeads={reportHeads}
              material={rows[0].material2}
            />
            <FirePipeReportBody
              reportHeads={reportHeads}
              reportRows={rows}
            />
            {/* Empty line between each groups */}
            <tr>
              <td className={s.blank}></td>
            </tr>
          </Fragment>
        )
      })}
    </tbody>
  )
}
