import {
  Body1,
  Panel,
  Switch,
  makeStyles,
  shorthands,
  tokens,
} from '@aisekisan/bond'
import type { ChangeEvent, ReactElement } from 'react'
import { useCallback, useState } from 'react'
import { LockClosed24Regular } from '@fluentui/react-icons'
import { useAccountOTPStatus, useDisableAccountOTP } from '@aisekisan/anya-api'
import { OTPEnable } from './otp-enable'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'

const useStyles = makeStyles({
  helper: {
    color: tokens.colorNeutralForeground3,
    ...shorthands.margin(0),
  },
  switchLabel: {
    marginLeft: '-8px',
  },
})

function TwoFASwitcher(): JSX.Element {
  const t = useT()
  const styles = useStyles()

  const [enableOtp, setEnableOtp] = useState<boolean>(false)

  const twoFAStatus = useAccountOTPStatus()
  const disableOtp = useDisableAccountOTP()

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const enabled = e.currentTarget.checked
      if (enabled)
        setEnableOtp(true)

      else disableOtp.mutate()
    },
    [disableOtp],
  )

  return (
    <div>
      <Switch
        label={t('account.manage.security.2FA')}
        labelPosition="before"
        onChange={onChange}
        className={styles.switchLabel}
        disabled={!twoFAStatus.isSuccess}
        checked={twoFAStatus.isSuccess && twoFAStatus.data.enabled}
      />
      {enableOtp && <OTPEnable close={() => setEnableOtp(false)} />}
    </div>
  )
}

export function SecurityInfo(): ReactElement {
  const styles = useStyles()

  return (
    <Panel
      title={<T id="account.manage.security.title" />}
      icon={<LockClosed24Regular />}
    >
      <Body1 className={styles.helper}>
        <T id="account.manage.security.helper" />
      </Body1>
      <TwoFASwitcher />
    </Panel>
  )
}
