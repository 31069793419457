import { Body1, makeStyles, shorthands } from '@aisekisan/bond'
import type { CategoryDetail, ConstructionDetail } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'
import { useFormatDate } from '@/utils/date'

const useStyles = makeStyles({
  overview: {
    ...shorthands.border('none'),
    ...shorthands.padding('5px'),
  },
  bold: {
    fontWeight: 'bold',
  },
})

interface Props {
  category: CategoryDetail
  construction: ConstructionDetail
}

export function FirePipeReportOverview(props: Props) {
  const { category, construction } = props

  const s = useStyles()
  const formatDate = useFormatDate('short-none')

  return (
    <tr>
      <td className={s.overview}>
        <Body1>
          {formatDate(new Date().toISOString())}
        </Body1>
      </td>
      <td className={s.overview} colSpan={2}>
        <Body1 className={s.bold}>
          <T id="report-fire.field.category" />
          {category.name}
        </Body1>
      </td>
      <td className={s.overview} colSpan={10}>
        <Body1 className={s.bold}>
          <T id="report-fire.field.construction" />
          {construction.name}
        </Body1>
      </td>
    </tr>
  )
}
