import type { ReactElement, ReactNode } from 'react'
import { useState } from 'react'
import { Button, Input, makeStyles, shorthands, tokens } from '@aisekisan/bond'
import { ListBarTreeOffset20Regular } from '@fluentui/react-icons'
import type { EquipmentAttributeTreeType, EquipmentClass } from '../../../type'
import type { EquipmentAttributeTreeNode } from '../../type'
import { DeleteNode } from './delete'
import { NodeMenu } from './menu'

const useStyles = makeStyles({
  node: {
    display: 'flex',
    flexGrow: 1,
    ...shorthands.margin(tokens.spacingHorizontalS),
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalS,
    flexGrow: 1,
    rowGap: tokens.spacingHorizontalS,
    boxSizing: 'border-box',
  },
})

interface NodeProps {
  root: EquipmentAttributeTreeNode
  node: EquipmentAttributeTreeNode
  equip: EquipmentClass
  treeType: EquipmentAttributeTreeType
  activeIDNodes: number[]
  readonly?: boolean
  children?: ReactNode
  onChange: (tree: EquipmentAttributeTreeNode) => void
  update: (value: string) => void
  remove: () => void
  setSelected: () => void
  duplicate: () => void
}

export function Node(props: NodeProps): ReactElement {
  const { root, node, equip, treeType, onChange, readonly, update, remove, setSelected, duplicate, activeIDNodes } = props

  const isViewDependentProps = node.children.length > 0
    || node.shapeOptions !== undefined
    || node.insulationOptions !== undefined

  const styles = useStyles()

  const [deleteNode, setDeleteNode] = useState<boolean>(false)

  return (
    <>
      <div className={styles.option}>
        <Input
          onChange={event => update(event.target.value)}
          appearance="filled-darker"
          value={node.value}
          style={{ flexGrow: 1, width: 'auto', minWidth: '178px' }}
          autoFocus={false}
          disabled={readonly}
        />
        <NodeMenu
          root={root}
          node={node}
          equip={equip}
          treeType={treeType}
          onChange={onChange}
          duplicate={duplicate}
          deleteNode={() => setDeleteNode(true)}
          selectNode={setSelected}
          isViewDependentProps={isViewDependentProps}
          readonly={readonly}
        />
        {isViewDependentProps && (
          <Button
            icon={<ListBarTreeOffset20Regular />}
            appearance={
              activeIDNodes.includes(node.id) ? 'primary' : 'secondary'
            }
            onClick={setSelected}
          />
        )}
      </div>
      {deleteNode && <DeleteNode close={() => setDeleteNode(false)} onDelete={remove} />}
    </>
  )
}
