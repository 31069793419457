import type { EquipmentAttributeTreeNodeServer } from '../type'
import type { EquipmentAttributeTreeNode } from './type'
import { parseInsulOptions, serializeInsulOptions } from './insul/type'
import { parseCustomShapeOptions, serializeCustomShapeOptions } from './size/type'
import { parseInsulThicknesses, serializeInsulThicknesses } from './insul-size/type'

export function getEquipmentAttributeInitialTree(): EquipmentAttributeTreeNode {
  return {
    children: [],
    id: 0,
    value: '',
  }
}

/**
 * This function transform tree node from server to client
 */
export function parseTreeNode(tree: EquipmentAttributeTreeNodeServer): EquipmentAttributeTreeNode {
  const customShapeOptions = tree.shapeOptions
    ? {
        ...parseCustomShapeOptions(tree.shapeOptions),
        insulShapes: parseInsulThicknesses(tree.shapeOptions),
      }
    : undefined

  const customInsulOptions = tree.insulationOptions
    ? parseInsulOptions(tree.insulationOptions)
    : undefined

  return {
    ...tree,
    children: tree.children.map(children => parseTreeNode(children)),
    shapeOptions: customShapeOptions,
    insulationOptions: customInsulOptions,
  }
}

/**
 * This function transform tree node from client to server
 */
export function serializeTreeNode(tree: EquipmentAttributeTreeNode): EquipmentAttributeTreeNodeServer {
  const transformedNode: EquipmentAttributeTreeNodeServer = {
    id: tree.id,
    value: tree.value,
    children: tree.children.map(serializeTreeNode),
  }

  if (tree.shapeOptions) {
    if (tree.shapeOptions.shape === 'InsulationThickness')
      transformedNode.shapeOptions = serializeInsulThicknesses(tree.shapeOptions.insulShapes)
    else
      transformedNode.shapeOptions = serializeCustomShapeOptions(tree.shapeOptions)
  }

  if (tree.insulationOptions)
    transformedNode.insulationOptions = serializeInsulOptions(tree.insulationOptions)

  return transformedNode
}
