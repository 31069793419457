import { OpenAPI } from '@aisekisan/anya-api'

const HOST = import.meta.env.VITE_API_HOST

if (!HOST)
  throw new Error('API_HOST not found in .env')

OpenAPI.BASE = HOST
OpenAPI.WITH_CREDENTIALS = true
OpenAPI.CREDENTIALS = 'include'
