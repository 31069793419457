import type { Editor } from '@tldraw/tldraw'
import type { DocumentMeta } from '@/components/editor/editor/document/type'
import { documentMetaSchema } from '@/components/editor/editor/document/type'

interface Params {
  editor: Editor
  scale: number
}

export function scalePdf(params: Params): void {
  const { editor, scale } = params
  const prev = documentMetaSchema.parse(editor.getDocumentSettings().meta)
  const next: DocumentMeta = { ...prev, scale }
  editor.updateDocumentSettings({ meta: next })
}
