import {
  useOrgMemberList,
  useOrgPresetList,
  useOrgStats,
} from '@aisekisan/anya-api'
import { makeStyles, tokens } from '@aisekisan/bond'
import {
  Building20Regular,
  Database20Regular,
  DocumentLightning20Regular,
  DocumentOnePageMultiple20Regular,
  People20Regular,
} from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'
import { FieldInfo } from '@/components/field-info'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalXXL,
  },
})

interface Props {
  orgID: string
}

export function OrgStatsOverview(props: Props): ReactElement | null {
  const { orgID } = props

  const s = useStyles()
  const t = useT()

  const stats = useOrgStats({ orgID }).data
  const members = useOrgMemberList({ orgID }).data ?? []
  const orgPresetList = useOrgPresetList({ orgID }).data ?? []

  if (stats === undefined)
    return null

  return (
    <div className={s.container}>
      <FieldInfo
        label={<T id="org.stats.constructions" />}
        value={`${stats.numConstruction} / ${stats.maxNumConstruction} ${t('org.stats.constUnit')}`}
        icon={<Building20Regular />}
      />

      <FieldInfo
        label={`${t('org.preset.title')}`}
        value={`${orgPresetList.length.toString()} ${t('org.preset.title').toLowerCase()}`}
        icon={<DocumentLightning20Regular />}
      />
      <FieldInfo
        label={`${t('org.member.title')}`}
        value={`${members.length.toString()} ${t('org.member.unit')}`}
        icon={<People20Regular />}
      />

      <FieldInfo
        label={<T id="org.stats.page" />}
        value={`${stats.pageUsed} / ${stats.pagePoolCapacity} ${t('org.stats.pageUnit')}`}
        icon={<DocumentOnePageMultiple20Regular />}
      />

      <FieldInfo
        label={<T id="org.stats.storage" />}
        value={`${stats.storageUsed.toLocaleString()} / ${stats.storageCapacity} GB`}
        icon={<Database20Regular />}
      />
    </div>
  )
}
