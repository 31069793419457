import type { VecLike } from '@tldraw/tldraw'
import { Box, createShapeId } from '@tldraw/tldraw'
import type { VerticalDirection } from '@aisekisan/anya-api'
import type { AnnotShapeColor } from '../../../shape/color'
import { ANNOT_SHAPE_OPACITY } from '../../../shape/color'
import { toShapeRotation } from '../../../shape/rotation'
import { createSegmentMeta } from '../../base'
import type { SegmentVerticalMeta, SegmentVerticalPartial } from './shape'

const CONSTANT_BASE = {
  opacity: ANNOT_SHAPE_OPACITY,
} satisfies Omit<SegmentVerticalPartial, 'id' | 'meta' | 'type'>

const CONSTANT_PROPS = {
  geo: 'triangle',
  w: 10,
  h: 10,
  dash: 'solid',
  size: 's',
} satisfies SegmentVerticalPartial['props']

interface MetaProps {
  group: string
  mm: number
}

function createMeta(props: MetaProps): SegmentVerticalMeta {
  const { group, mm } = props

  const base = createSegmentMeta({ group })
  return { ...base, segment: 'vertical', mm }
}

/**
 * Create a new partial vertical segment out of a point
 */
export function createSegmentVerticalPartial(params: {
  color: AnnotShapeColor
  direction: VerticalDirection
  meta: MetaProps
  vec: VecLike
}): SegmentVerticalPartial {
  const { vec, direction, meta, color } = params

  const { w, h } = CONSTANT_PROPS
  const box = Box.FromCenter(vec, { x: w, y: h })

  return {
    ...CONSTANT_BASE,
    rotation: toShapeRotation(direction),
    id: createShapeId(),
    type: 'geo',
    x: box.x,
    y: box.y,
    props: { ...CONSTANT_PROPS, color },
    meta: createMeta(meta) as any,
  }
}
