import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { server } from '../server'

function getKey(params: { constructionID: string | null }) {
  const { constructionID } = params
  return ['construction-module', constructionID, 'list']
}

export function useConstructionModuleList(params: {
  constructionID: string | null
}) {
  const { constructionID } = params
  return useQuery({
    queryKey: getKey({ constructionID }),
    queryFn: () => {
      if (constructionID === null)
        throw new Error('constructionID is null')
      return server.listConstructionModules(constructionID)
    },
    enabled: constructionID !== null,
  })
}

export type ConstructionModuleCreateBody = Omit<
  Parameters<typeof server.createConstructionModule>[0],
  'constructionID'
>

export function useConstructionModuleCreate(params: {
  constructionID: string
}) {
  const { constructionID } = params
  const client = useQueryClient()

  return useMutation({
    mutationFn: (body: ConstructionModuleCreateBody) => {
      return server.createConstructionModule({ constructionID, ...body })
    },
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getKey({ constructionID }),
      })
    },
  })
}

type ConstructionModuleEditBody = Parameters<typeof server.updateConstructionModule>[1]

export function useConstructionModuleUpdate(params: {
  constructionID: string
}) {
  const { constructionID } = params
  const client = useQueryClient()

  return useMutation({
    mutationFn: (props: { constructionModuleId: string, requestBody: ConstructionModuleEditBody }) => {
      const { constructionModuleId, requestBody } = props
      return server.updateConstructionModule(constructionModuleId, requestBody)
    },
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getKey({ constructionID }),
      })
    },
  })
}
