import { Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@aisekisan/bond'
import { CloudArrowDown20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import type { ExcelFileFormat } from './download'
import { T } from '@/libs/intl/t'

interface ExportButtonProps {
  download: (format: ExcelFileFormat) => void
  disabled?: boolean
}

export function ReportExport(props: ExportButtonProps): ReactElement {
  const { download, disabled } = props

  return (
    <Menu positioning="below-end">
      <MenuTrigger disableButtonEnhancement>
        <MenuButton
          appearance="primary"
          icon={<CloudArrowDown20Regular />}
          disabled={disabled}
        >
          <T id="report.download" />
        </MenuButton>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          <MenuItem onClick={() => download('xlsx')}>
            <T id="report.download.format_excel" />
          </MenuItem>
          <MenuItem onClick={() => download('csv')}>
            <T id="report.download.format_csv" />
          </MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
