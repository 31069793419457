import { useGlobalPresetDetail, useOrgPresetDetail } from '@aisekisan/anya-api'
import { PresetCard } from '@aisekisan/preset-property'
import type { ReactElement } from 'react'

function OrgPresetCard(props: { orgPresetID: string, target: '_blank' | '_self' }): ReactElement | null {
  const { orgPresetID, target = '_self' } = props

  const { data: preset } = useOrgPresetDetail({ orgPresetID })

  if (!preset)
    return null

  return (
    <PresetCard preset={preset} href={`/org-preset/${orgPresetID}`} target={target} />
  )
}

function GlobalPresetCard(props: { globalPresetID: string, }): ReactElement | null {
  const { globalPresetID } = props

  const { data: preset } = useGlobalPresetDetail({ globalPresetID })

  if (!preset)
    return null

  return (
    <PresetCard preset={preset} />
  )
}

export function PresetThumbnailCard(props: { option: string }): JSX.Element | null {
  const { option } = props
  const [id, type] = option.split('/')

  if (type === 'orgPreset')
    return <OrgPresetCard orgPresetID={id} target={'_blank'} />

  else if (type === 'globalPreset')
    return <GlobalPresetCard globalPresetID={id} />

  return null
}
