import { Button, Panel, makeStyles, tokens } from '@aisekisan/bond'
import { Form24Regular, FormNew20Regular } from '@fluentui/react-icons'
import _groupBy from 'lodash/groupBy'
import type { ReactElement } from 'react'
import { useEquipmentAttributeTrees } from '@aisekisan/anya-api'
import { ConstructionEquipmentPropertyOverview } from './overview'
import { T } from '@/libs/intl/t'
import { EmptyContent } from '@/components/empty-content'
import { useLocation } from '@/hooks/use-location'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: tokens.spacingHorizontalL,
    rowGap: tokens.spacingVerticalL,
  },
})

interface Props {
  constructionID: string
}

export function ConstructionEquipmentPropertyList(props: Props): ReactElement {
  const { constructionID } = props

  const styles = useStyles()
  const { navigate } = useLocation()

  const { data: list } = useEquipmentAttributeTrees({ constructionID })
  const groups = Object.entries(
    _groupBy(list, 'equipmentClass'),
  )

  return (
    <Panel
      title={<T id="preset.equipment.properties" />}
      icon={<Form24Regular />}
      actions={(
        <Button
          icon={<FormNew20Regular />}
          onClick={() => {
            navigate(`/construction-property/${constructionID}/create`)
          }}
        >
          <T id="preset.equipment.property.create" />
        </Button>
      )}
    >
      <div className={styles.container}>
        {list && list.length > 0
          ? (
            <>
              {groups.map(([equipmentClass, properties]) => (
                <ConstructionEquipmentPropertyOverview
                  key={equipmentClass}
                  equipmentClass={equipmentClass}
                  properties={properties}
                  constructionID={constructionID}
                />
              ))}
            </>
            )
          : <EmptyContent />}
      </div>
    </Panel>
  )
}
