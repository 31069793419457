import {
  Body1,
  Button,
  Input,
  Label,
  Link,
  OverlaySpinner,
  Text,
  tokens,
  useIntl,
} from '@aisekisan/bond'
import { Eye20Regular, EyeOff20Regular, LockClosed20Regular, Mail20Regular } from '@fluentui/react-icons'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ApiError, parseServerError, useAccountSignIn } from '@aisekisan/anya-api'
import { AccountLayout } from '../layout'
import { useStyles } from '../styles'
import { SignInOTP } from './otp'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'
import { useLocation } from '@/hooks/use-location'

interface Values {
  email: string
  password: string
}

export function SignInForm(): JSX.Element {
  const t = useT()
  const styles = useStyles()
  const { navigate } = useLocation()
  const { lang } = useIntl()
  const [requireOTP, setRequireOTP] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const form = useForm<Values>({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const signIn = useAccountSignIn()
  const [error, setError] = useState<ApiError | null>(null)

  const onSubmit = (body: Values) => {
    const { email, password } = body
    signIn.mutate(
      { email, password },
      {
        onSuccess: (data) => {
          if (typeof data === 'object' && data.totp)
            setRequireOTP(true)
          else
            navigate('/home')
        },
        onError: (error) => {
          if (error instanceof ApiError)
            setError(error)
        },
      },
    )
  }

  const NavFooter = (
    <div className={styles.navigateFlex}>
      <div>
        <Body1 color={tokens.colorNeutralForeground3}>
          {lang === 'en' ? <T id="sign-in.trouble_sign_in" /> : null}
          {' '}
          <Link href="/reset-password" inline>
            <T id="sign-in.reset" />
          </Link>
        </Body1>
      </div>
    </div>
  )

  const getErrorMessage = () => {
    if (error === null)
      return
    switch (error.status) {
      case 423:
        return t('sign-in.error-423.help')
      case 404:
        return t('sign-in.error-404.help')
      case 401:
        return t(
          'sign-in.error-401.help {{.remaining}}',
          { remaining: error.body.remaining_attempt },
        )
      default:
        return t('sign-in.self.error.help')
    }
  }

  return (
    <AccountLayout navigate={NavFooter}>
      <div className={styles.card}>
        <OverlaySpinner visible={signIn.isPending} appearance="primary" />

        {!requireOTP
          ? (
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className={styles.flexCol}
              >
                <Text className={styles.title3}>
                  <T id="Welcome back" />
                </Text>
                {signIn.isError && (
                  <ErrorAlert
                    title={t('sign-in.self.error.title')}
                    help={getErrorMessage()}
                    detail={parseServerError(signIn.error)}
                    onClose={() => signIn.reset()}
                  />
                )}
                <div className={styles.field}>
                  <Label htmlFor="email">{t('account.field.email')}</Label>
                  <Input
                    type="email"
                    id="email"
                    {...form.register('email')}
                    placeholder="name@gmail.com"
                    required
                    contentBefore={<Mail20Regular />}
                    size="large"
                    appearance="filled-darker"
                  />
                </div>
                <div className={styles.field}>
                  <Label htmlFor="password">{t('account.field.password')}</Label>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    required
                    {...form.register('password')}
                    contentBefore={<LockClosed20Regular />}
                    size="large"
                    appearance="filled-darker"
                    contentAfter={(
                      <Button
                        appearance="subtle"
                        icon={showPassword ? <Eye20Regular /> : <EyeOff20Regular />}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  />
                </div>

                <div className={styles.fullWidth}>
                  <Button type="submit" appearance="primary" size="large">
                    <T id="sign-in.submit" />
                  </Button>
                </div>
              </form>
            )
          : (
              <SignInOTP />
            )}
      </div>
    </AccountLayout>
  )
}
