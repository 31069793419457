import type {
  IntlLang,
} from '@aisekisan/bond'
import {
  Caption1,
  ToggleButton,
  makeStyles,
  tokens,
  useIntl,
} from '@aisekisan/bond'
import { useState } from 'react'
import { IntlFlagEn } from './flags/en'
import { IntlFlagJa } from './flags/ja'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalS,
  },
})
export function ToggleIntlSelect(): JSX.Element {
  const { lang, setLang } = useIntl()

  const [language, setLanguage] = useState<IntlLang>(lang)

  const toggleSetLang = (value: IntlLang) => {
    setLanguage(value)
    setLang(value)
  }

  const styles = useStyles()
  return (
    <div className={styles.container}>
      <ToggleButton
        checked={language === 'en'}
        icon={<IntlFlagEn />}
        onClick={() => void toggleSetLang('en')}
        size="small"
        appearance={language === 'en' ? 'outline' : 'subtle'}
      >
        <Caption1>English</Caption1>
      </ToggleButton>
      <ToggleButton
        checked={language === 'ja'}
        icon={<IntlFlagJa />}
        onClick={() => void toggleSetLang('ja')}
        size="small"
        appearance={language === 'ja' ? 'outline' : 'subtle'}
      >
        <Caption1>日本語</Caption1>
      </ToggleButton>
    </div>
  )
}
