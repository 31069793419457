import { z } from 'zod'

export const documentMetaSchema = z.object({
  scale: z.union([
    z.number(),
    // "scale" is set after the editor is created (see "document/scale") so it
    // technically could be "undefined"  before that.
    // @TODO: See if we can init the editor with an initial meta value so "size"
    // would always be a number.
    z.undefined(),
  ]),
})

export type DocumentMeta = z.infer<typeof documentMetaSchema>
