import { useMutation, useQuery } from '@tanstack/react-query'
import type { Market } from '../generated'
import { server } from '../server'

const KEY_ALL = ['construction-preset']
const KEY_LIST = [...KEY_ALL, 'list']

export function useGlobalPresetList(params: { market?: Market }) {
  const { market } = params

  return useQuery({
    queryKey: [...KEY_LIST, market],
    queryFn: () => {
      return server.listGlobalPresets(market)
    },
  })
}

export function useOrgPresetBind() {
  return useMutation({
    mutationFn: ({
      constructionID,
      orgPresetId,
    }: {
      constructionID: string
      orgPresetId: string
    }) => {
      return server.bindOrgPreset(orgPresetId, constructionID)
    },
  })
}

export function useGlobalPresetBind() {
  return useMutation({
    mutationFn: ({ globalPresetId, constructionID }: {
      constructionID: string
      globalPresetId: string
    }) => {
      return server.bindGlobalPreset(globalPresetId, constructionID)
    },
  })
}
