import { Body1, Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, ProgressBar, makeStyles, tokens } from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useEffect, useState } from 'react'
import { jsPDF as JSPDF } from 'jspdf'
import { Canvas } from './canvas/canvas'
import { T } from '@/libs/intl/t'
import type { tFn } from '@/libs/intl/useT'
import { useT } from '@/libs/intl/useT'
import { DEFAULT_PDF_RENDER_ERROR, type PdfImage, type PdfRenderError, type PdfRenderState } from '@/components/category/page/export/utils'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalL,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
})

interface Props {
  id: string
  close: () => void
}

export function PrintPage(props: Props): ReactElement {
  const { id, close } = props

  const t = useT()
  const styles = useStyles()

  const [state, setState] = useState<PdfRenderState>({ mode: 'idle' })
  const [error, setError] = useState<PdfRenderError>(DEFAULT_PDF_RENDER_ERROR)
  const [images, setImages] = useState<PdfImage[]>([])

  useEffect(() => {
    if (images.length !== 1)
      return

    exportPDF('export', images[0])
    setState({ mode: 'idle' })
    close()
  }, [images, close])

  return (
    <Dialog open={!!id} onOpenChange={close}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle action={(
            <DialogTrigger action="close">
              <Button
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
                onClick={close}
              />
            </DialogTrigger>
          )}
          >
            <T id="page.print.title" />
          </DialogTitle>
          <DialogContent>
            <div className={styles.content}>
              <Body1>
                <T id="page.print.helper.1" />
              </Body1>
              <Body1>
                <T id="page.print.helper.2" />
              </Body1>

              {state.mode === 'generating' && (
                <Canvas
                  id={id}
                  setPdfImages={setImages}
                  setPdfRenderError={setError}
                />
              )}

              <Field
                validationMessage={getProgressBarMsg(state, error, t)}
                validationState={error.failedCount !== 0 ? 'error' : 'none'}
              >
                <ProgressBar
                  thickness="large"
                  value={getProgressBarValue(state, error)}
                  max={1}
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              disabled={state.mode !== 'idle' || error.failedCount !== 0}
              onClick={() => setState({ mode: 'generating' })}
            >
              <T id="page.print.submit" />
            </Button>
            <Button onClick={close}>
              <T id="page.delete.cancel" />
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}

function getProgressBarMsg(state: PdfRenderState, error: PdfRenderError, t: tFn): string {
  if (error.failedCount !== 0)
    return error.messages?.at(0) || t('page.print.failed')

  switch (state.mode) {
    case 'idle':
      return t('page.print.helper.3')
    case 'generating':
      return t('page.print.helper.creating_pdf')
    case 'generated':
      throw new Error('Invalid state')
  }
}

function getProgressBarValue(state: PdfRenderState, error: PdfRenderError): number {
  if (error.failedCount !== 0)
    return 0

  switch (state.mode) {
    case 'idle':
      return 0
    case 'generating':
      return 0.8
    case 'generated':
      throw new Error('Invalid state')
  }
}

function exportPDF(name: string, pdfImage: PdfImage): void {
  const { image, direction } = pdfImage

  if (image === '')
    return

  const pdf = new JSPDF({
    orientation: direction,
    format: 'a4',
    compress: true,
  })

  const imgProps = pdf.getImageProperties(image)
  const width = pdf.internal.pageSize.getWidth()
  const height = (imgProps.height * width) / imgProps.width

  pdf.addImage({
    imageData: image,
    x: 0,
    y: 0,
    width,
    height,
    compression: 'MEDIUM',
    format: 'PNG',
  })

  /** Download pdf */
  pdf.save(`${name}.pdf`)
}
