import {
  ToastBody,
  ToastFooter,
  ToastTitle,
  Toaster,
  Toast as UIToast,
  useId,
  useToastController,
} from '@aisekisan/bond'

interface ToastProps {
  title: React.ReactNode
  body?: React.ReactNode
  footer?: React.ReactNode
  intent?: 'info' | 'success' | 'warning' | 'error'
}

export function useToast(props: ToastProps) {
  const { title, body, footer, intent = 'success' } = props
  const toasterId = useId('toaster')

  const { dispatchToast } = useToastController(toasterId)
  const notify = () =>
    dispatchToast(
      <UIToast>
        <ToastTitle>{title}</ToastTitle>
        {body && <ToastBody>{body}</ToastBody>}
        {footer && <ToastFooter>{footer}</ToastFooter>}
      </UIToast>,
      { intent },
    )

  const Toast = <Toaster toasterId={toasterId} />

  return {
    notify,
    toast: Toast,
  }
}
