import {
  Field,
  Input,
  Panel,
  Select,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import type { Dispatch, ReactElement, SetStateAction } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import type { EquipmentAttributeTreeNode, SelectedNode } from '../type'
import { PropertyTree } from '../tree/tree'
import { findPathIDToNode } from '../tree/utils'
import { TREE_TYPES } from '../tree/tree-type'
import { useT } from '../../libs/intl/useT'
import { T } from '../../libs/intl/t'
import { EQUIP_CLASS } from '../../equipment-class'
import type { EquipmentClassDetail } from '../../type'
import type { PropertyFormBody } from './type'

const useStyles = makeStyles({
  container: {
    width: '320px',
    backgroundColor: tokens.colorNeutralBackground1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
})

interface Props {
  equipmentClasses: EquipmentClassDetail[]
  form: UseFormReturn<PropertyFormBody>
  onChange: (tree: EquipmentAttributeTreeNode) => void
  setSelected: Dispatch<SetStateAction<SelectedNode | null>>
  selected: SelectedNode | null
  isCreate?: boolean
}

export function PropertyOverview(props: Props): ReactElement {
  const {
    equipmentClasses,
    form,
    setSelected,
    onChange,
    selected,
    isCreate,
  } = props

  const tree = form.watch('tree')
  const equip = form.watch('equipmentClass')
  const treeType = form.watch('treeType')

  const activeIDNodes = selected !== null
    ? (findPathIDToNode(tree, selected.node.id) ?? []).filter(
        (_node, index) => index % 2 === 1,
      ) ?? []
    : []

  const t = useT()
  const styles = useStyles()

  return (
    <Panel
      className={styles.container}
      title={t('preset-property.property.overview')}
    >
      <form className={styles.form}>
        <Field
          label={t('preset-property.property.equip')}
          hint={t('preset-property.property.equip.hint')}
        >
          <Select
            appearance="filled-darker"
            {...form.register('equipmentClass')}
            value={form.getValues('equipmentClass')}
            disabled={!isCreate}
          >
            {(equipmentClasses.sort((a, b) => a.name.localeCompare(b.name)) ?? []).map(({ equipmentClass, name }) => (
              <option value={equipmentClass} key={equipmentClass}>
                {name}
              </option>
            ))}
          </Select>
        </Field>
        {Number(equip) === EQUIP_CLASS.OTHER && (
          <Field
            label={t('preset-property.property.equip-name')}
            hint={t('preset-property.property.equip-name.hint')}
          >
            <Input
              appearance="filled-darker"
              {...form.register('equipmentClassOther')}
              defaultValue={form.getValues('equipmentClassOther')}
              disabled={!isCreate}
            />
          </Field>
        )}
        <Field
          label={t('preset-property.size.type')}
          hint={t('preset-property.size.type.hint')}
        >
          <Select
            appearance="filled-darker"
            {...form.register('treeType')}
            disabled={!isCreate}
          >
            {TREE_TYPES.map(({ id, label }) => (
              <option value={id} key={id}>
                <T id={label} />
              </option>
            ))}
          </Select>
        </Field>
        <PropertyTree
          root={tree}
          tree={tree}
          equip={Number(equip)}
          treeType={treeType}
          activeIDNodes={activeIDNodes}
          onChange={onChange}
          setSelected={setSelected}
        />
      </form>
    </Panel>
  )
}
