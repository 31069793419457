import { Button, Caption1, Field, Label, Select, Subtitle2, makeStyles, shorthands, tokens } from '@aisekisan/bond'
import { AddCircle20Regular, Delete20Regular, Ruler20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useState } from 'react'
import type { EquipmentAttributeTreeNode } from '../type'
import { useT } from '../../libs/intl/useT'
import { T } from '../../libs/intl/t'
import { EQUIP_CLASS } from '../../equipment-class'
import type { EquipmentClassDetail } from '../../type'
import { DeleteInsulSizeProperty } from './delete'
import { InsulSizeForm } from './form'
import type { CustomInsulThickness } from './type'
import { getInsulThicknessInitial } from './type'

const useStyles = makeStyles({
  children: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    backgroundColor: tokens.colorNeutralForegroundOnBrand,
    ...shorthands.padding(tokens.spacingVerticalL),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.spacingHorizontalS,
  },
  actionGroup: {
    alignSelf: 'end',
  },
  hint: {
    color: tokens.colorNeutralForeground3,
  },
})

interface Props {
  equipmentClasses: EquipmentClassDetail[]
  equip: string
  insulShapes: CustomInsulThickness[]
  node: EquipmentAttributeTreeNode
  onChange: (node: EquipmentAttributeTreeNode) => void
}

export function InsulSizeProperty(props: Props): ReactElement | null {
  const { equipmentClasses, equip, insulShapes, node, onChange } = props

  const t = useT()
  const styles = useStyles()

  const [deleteProperty, setDeleteProperty] = useState<boolean>(false)

  if (!node.shapeOptions || node.shapeOptions.shape !== 'InsulationThickness')
    return null

  const onUpdateDataShape = (shapes: CustomInsulThickness[]) => {
    const next = { ...node }
    if (!next.shapeOptions?.insulShapes)
      return

    next.shapeOptions.insulShapes[equip] = shapes
    onChange(next)
  }

  const onAddDataShape = (): void => {
    const next = { ...node }
    if (!next.shapeOptions?.insulShapes)
      return

    next.shapeOptions.insulShapes[equip] = [
      ...insulShapes,
      getInsulThicknessInitial(),
    ]
    onChange(next)
  }

  const onUpdateEquipmentClass = (value: number): void => {
    const next = { ...node }
    if (Number(equip) !== EQUIP_CLASS.OTHER || !next.shapeOptions?.insulShapes)
      return

    delete next.shapeOptions.insulShapes[equip]
    next.shapeOptions.insulShapes[value] = insulShapes
    onChange(next)
  }

  const onRemove = () => {
    const next = { ...node }
    if (!next.shapeOptions?.insulShapes)
      return

    if (Object.keys(next.shapeOptions.insulShapes).length === 1)
      next.shapeOptions.insulShapes = {}
    else
      delete next.shapeOptions.insulShapes[equip]

    onChange(next)
  }

  return (
    <div className={styles.children}>
      <div className={styles.title}>
        <Ruler20Regular />
        <Subtitle2>
          <T id="preset-property.node.menu.insul-size" />
        </Subtitle2>
      </div>
      <Field
        label={t('preset-property.insul-size.equip')}
        hint={t('preset-property.insul-size.equip.hint')}
      >
        <Select
          appearance="filled-darker"
          value={equip}
          onChange={(_, data) => {
            onUpdateEquipmentClass(Number(data.value))
          }}
          disabled={equip !== '0'}
        >
          {(equipmentClasses ?? []).map(({ equipmentClass, name }) => (
            <option value={equipmentClass} key={equipmentClass}>
              {name}
            </option>
          ))}
        </Select>
      </Field>
      <Label>
        <T id="preset-property.property.options" />
      </Label>
      <InsulSizeForm
        insulShapes={insulShapes}
        onUpdate={onUpdateDataShape}
      />
      <div>
        <Button
          icon={<AddCircle20Regular />}
          onClick={() => onAddDataShape()}
        >
          <T id="preset-property.option.create" />
        </Button>
      </div>
      <Caption1 className={styles.hint}>
        <T id="preset-property.option.create.hint.1" />
      </Caption1>
      <div className={styles.actionGroup}>
        <Button icon={<Delete20Regular />} onClick={() => setDeleteProperty(true)}>
          <T id="preset-property.children.delete" />
        </Button>
      </div>
      {deleteProperty && (
        <DeleteInsulSizeProperty
          close={() => setDeleteProperty(false)}
          onDelete={onRemove}
        />
      )}
    </div>
  )
}
