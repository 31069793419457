import { Button, Caption1, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, OverlaySpinner, Select, makeStyles, tokens } from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { useState } from 'react'
import { EQUIP_CLASS } from '../equipment-class'
import { T } from '../libs/intl/t'
import { useT } from '../libs/intl/useT'
import type { EquipmentClassDetail } from '../type'

const useStyles = makeStyles({
  helper: {
    color: tokens.colorNeutralForeground3,
  },
})

interface Props {
  equipmentClasses: EquipmentClassDetail[]
  isPending: boolean
  copy: (equipmentClass: number) => void
  close: () => void
}

export function CopyProperty(props: Props): JSX.Element {
  const { equipmentClasses, isPending, copy, close } = props

  const t = useT()
  const styles = useStyles()

  const [equipmentClass, setEquipmentClass] = useState<number>(EQUIP_CLASS.OTHER)

  return (
    <Dialog open onOpenChange={close}>
      <DialogSurface>
        <DialogBody>
          <OverlaySpinner
            visible={isPending}
            appearance="primary"
          />
          <DialogTitle action={(
            <DialogTrigger action="close">
              <Button
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
                onClick={close}
              />
            </DialogTrigger>
          )}
          >
            <T id="preset-property.copy.equipment" />
          </DialogTitle>
          <DialogContent>
            <Field label={t('preset-property.property.equip')}>
              <Select
                appearance="filled-darker"
                size="large"
                onChange={(e) => {
                  setEquipmentClass(Number(e.target.value))
                }}
              >
                {(equipmentClasses ?? []).map(({ equipmentClass, name }) => (
                  <option key={equipmentClass} value={equipmentClass}>
                    {name}
                  </option>
                ))}
              </Select>
            </Field>
            <Caption1 className={styles.helper}><T id="preset-property.copy.help" /></Caption1>
          </DialogContent>
          <DialogActions>
            <Button onClick={close}>
              <T id="preset-property.action.cancel" />
            </Button>
            <Button
              appearance="primary"
              onClick={() => copy(equipmentClass)}
            >
              <T id="preset-property.copy.submit" />
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
