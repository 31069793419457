import type { EquipmentAttributeTree } from '@aisekisan/anya-api'
import { parseServerError, useEquipmentAttributeTreeDelete } from '@aisekisan/anya-api'
import { DeleteProperty } from '@aisekisan/preset-property'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'
import { useLocation } from '@/hooks/use-location'

interface Props {
  property: EquipmentAttributeTree
  close: () => void
}

export function DeleteConstructionProperty(props: Props): JSX.Element {
  const { property, close } = props
  const { id: treeID, constructionID } = property

  const t = useT()
  const { navigate } = useLocation()

  const deleteTree = useEquipmentAttributeTreeDelete({ constructionID })

  return (
    <>
      <DeleteProperty
        isPending={deleteTree.isPending}
        deleteTree={() => {
          deleteTree.mutate(
            { treeID },
            {
              onSuccess: () => {
                close()
                navigate(`/construction/${constructionID}`)
              },
            },
          )
        }}
        close={close}
      />
      {deleteTree.isError
        ? (
          <ErrorAlert
            title={t('preset.equipment.property.delete.error')}
            help={t('all.error.review')}
            detail={parseServerError(deleteTree.error)}
            onClose={() => deleteTree.reset()}
          />
          )
        : null}
    </>
  )
}
