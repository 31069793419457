import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbDivider,
  BreadcrumbItem,
  Button,
  Text,
  Toolbar,
  ToolbarDivider,
  Tooltip,
  makeStyles,
  shorthands,
  tokens,
} from '@aisekisan/bond'
import { Info24Regular } from '@fluentui/react-icons'
import { Fragment } from 'react'
import { Link } from 'wouter'
import { AISekisan } from '../../icons/AISekisan'
import type { AppHeaderProps } from './type'
import { IntlSelect } from '@/provider/intl/select'
import { AuthInformation } from '@/components/account/auth-information'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { AppNotifyVersion } from './notify/version'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '24px',
    position: 'relative',
    boxShadow: tokens.shadow4,
    alignItems: 'center',
    ...shorthands.padding(tokens.spacingHorizontalM),
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    width: '80px',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...shorthands.textDecoration('none'),
    color: tokens.colorNeutralForeground1,
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    rowGap: '24px',
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: tokens.spacingHorizontalS,
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginLeft: tokens.spacingHorizontalL,
  },
})

export function AppHeader(props: AppHeaderProps) {
  const { breadcrumbs } = props
  const styles = useStyles()
  const t = useT()
  return (
    <div className={styles.root}>
      <div className={styles.leftSection}>
        <Link className={styles.logo} href="/home">
          <AISekisan />
          <Text weight="semibold"><T id="app.name.sekisan" /></Text>
        </Link>
        {breadcrumbs && (
          <Breadcrumb aria-label="Breadcrumb" className={styles.breadcrumb}>
            <BreadcrumbDivider />

            {breadcrumbs.map((breadcrumb, index) => (
              <Fragment key={breadcrumb.href}>
                <BreadcrumbItem>
                  <BreadcrumbButton href={breadcrumb.href} as="a">
                    {breadcrumb.title}
                  </BreadcrumbButton>
                </BreadcrumbItem>
                {index < breadcrumbs.length - 1 && <BreadcrumbDivider />}
              </Fragment>
            ))}
          </Breadcrumb>
        )}
      </div>
      <div className={styles.rightSection}>

        <Toolbar aria-label="Default" {...props}>
          <AppNotifyVersion />
          <AuthInformation />
          <ToolbarDivider />
          <IntlSelect />
          <ToolbarDivider />
          <Tooltip content={t('app.header.user-guide')} relationship="description" positioning="below-start">
            <Button
              appearance="transparent"
              icon={<Info24Regular />}
              as="a"
              href="https://docs.aisekisan.com"
              target="_blank"
            />
          </Tooltip>

        </Toolbar>
      </div>
    </div>
  )
}
