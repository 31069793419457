import { Button, Field, Input, Label, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, makeStyles, tokens } from '@aisekisan/bond'
import { Copy20Regular, Delete20Regular, MoreHorizontal20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useT } from '../../libs/intl/useT'
import { T } from '../../libs/intl/t'
import type { CustomInsulThickness } from './type'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalXXS,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalS,
    flexWrap: 'wrap',
    alignItems: 'flex-end',
  },
  field: {
    width: '104px',
  },
  thickness: {
    width: '216px',
  },
})

interface Props {
  insulShapes: CustomInsulThickness[]
  onUpdate: (shapes: CustomInsulThickness[]) => void
}

export function InsulSizeForm(props: Props): ReactElement {
  const { insulShapes, onUpdate } = props

  const t = useT()
  const styles = useStyles()

  const onUpdateProp = (index: number, shape: CustomInsulThickness) => {
    const next = [...insulShapes]
    next[index] = shape
    onUpdate(next)
  }

  const onRemoveProp = (index: number) => {
    const next = [...insulShapes]
    next.splice(index, 1)
    onUpdate(next)
  }

  const onDuplicateProp = (shape: CustomInsulThickness) => {
    const next = [...insulShapes]
    next.push(shape)
    onUpdate(next)
  }

  return (
    <div className={styles.container}>
      {insulShapes.map((shape, index) => (
        <div className={styles.wrapper} key={index}>
          <div className={styles.item}>
            <Field label={t('preset-property.insul-size.thickness')}>
              <Input
                type="number"
                value={shape.thickness ? shape.thickness.toString() : ''}
                onChange={(_, data) => {
                  onUpdateProp(index, { ...shape, thickness: Number(data.value) })
                }}
                appearance="filled-darker"
                className={styles.thickness}
              />
            </Field>
            <div>
              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <Button icon={<MoreHorizontal20Regular />} />
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    <MenuItem
                      icon={<Delete20Regular />}
                      onClick={() => onRemoveProp(index)}
                    >
                      <T id="preset-property.shape.delete" />
                    </MenuItem>
                    <MenuItem
                      icon={<Copy20Regular />}
                      onClick={() => onDuplicateProp({ ...shape })}
                    >
                      <T id="preset-property.shape.duplicate" />
                    </MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
            </div>
          </div>
          <Label>
            <T id="preset-property.insul-size.size" />
          </Label>
          <div className={styles.item}>
            <Field>
              <Input
                placeholder={t('preset-property.insul-size.from')}
                type="number"
                value={shape.from ? shape.from.toString() : ''}
                onChange={(_, data) => {
                  onUpdateProp(index, { ...shape, from: Number(data.value) })
                }}
                appearance="filled-darker"
                className={styles.field}
              />
            </Field>
            <Field>
              <Input
                placeholder={t('preset-property.insul-size.to')}
                type="number"
                value={shape.to ? shape.to.toString() : ''}
                onChange={(_, data) => {
                  onUpdateProp(index, { ...shape, to: Number(data.value) })
                }}
                appearance="filled-darker"
                className={styles.field}
              />
            </Field>
          </div>
        </div>
      ))}
    </div>
  )
}
