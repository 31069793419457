import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Input, makeStyles, shorthands, tokens } from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { parseServerError, useConstructionModuleUpdate } from '@aisekisan/anya-api'
import type { ConstructionModuleDetail } from '@aisekisan/anya-api'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  container: {
    minWidth: '100%',
  },
  body: {
    width: '30%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap(0, '10px'),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
})

interface ConstructionModuleUpdateForm {
  name: string
  quantity: number | undefined
}

export function ConstructionModuleUpdate(props: { cModule: ConstructionModuleDetail, constructionID: string, close: () => void }): JSX.Element {
  const { cModule, constructionID, close } = props

  const t = useT()
  const styles = useStyles()

  const schema = z.object({
    name: z.string().min(1, { message: t('form.error.required_field') }),
    quantity: z.number({ invalid_type_error: t('form.error.required_field'), required_error: t('form.error.required_field') }).nonnegative(),
  })

  const form = useForm<ConstructionModuleUpdateForm>({
    defaultValues: cModule,
    resolver: zodResolver(schema),
  })

  const { formState: { errors } } = form

  const update = useConstructionModuleUpdate({ constructionID })

  return (
    <Dialog open onOpenChange={close}>
      <DialogSurface className={styles.body}>
        <form
          className={styles.form}
          onSubmit={form.handleSubmit((body) => {
            const { name, quantity } = body
            const payload = {
              constructionModuleId: cModule.id,
              requestBody: {
                name,
                quantity: quantity ?? 1,
              },
            }
            update.mutate(payload, { onSuccess: close })
          })}
        >
          <DialogBody>
            <DialogTitle
              action={(
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    onClick={close}
                  />
                </DialogTrigger>
              )}
            >
              <T id="annotation.module.edit.title" />
            </DialogTitle>
            <DialogContent className={styles.content}>
              {update.isError
                ? (
                  <ErrorAlert
                    title={t('construction.module.edit.error')}
                    help={t('all.error.review')}
                    detail={parseServerError(update.error)}
                    onClose={() => update.reset()}
                  />
                  )
                : null}
              <Field
                label={<T id="construction.module.field.name" />}
                validationState={errors.name ? 'error' : 'none'}
                validationMessage={errors.name?.message}
              >
                <Input
                  defaultValue={form.getValues('name')}
                  {...form.register('name')}
                  size="large"
                  appearance="filled-darker"
                />
              </Field>
              <Field
                label={<T id="construction.module.field.quantity" />}
                validationState={errors.quantity ? 'error' : 'none'}
                validationMessage={errors.quantity?.message}
              >
                <Input
                  defaultValue={form.getValues('quantity')?.toString()}
                  {...form.register('quantity', {
                    valueAsNumber: true,
                  })}
                  type="number"
                  appearance="filled-darker"
                  size="large"
                />
              </Field>
            </DialogContent>
            <DialogActions>
              <Button
                {...{ fullWidth: true, large: true, type: 'submit' }}
                disabled={update.isPending}
                appearance="primary"
              >
                <T id="annotation.module.edit.submit" />
              </Button>
              <Button onClick={close}>
                <T id="dialog.action.cancel" />
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )
}
