import type {
  IntlLang,
  MenuProps,
} from '@aisekisan/bond'
import {
  Menu,
  MenuItemRadio,
  MenuList,
  MenuPopover,
  MenuTrigger,
  ToolbarButton,
  useIntl,
} from '@aisekisan/bond'
import { Globe24Regular } from '@fluentui/react-icons'
import { useState } from 'react'
import { IntlFlagEn } from './flags/en'
import { IntlFlagJa } from './flags/ja'

export function IntlSelect(): JSX.Element {
  const { lang, setLang } = useIntl()

  const [checkedValues, setCheckedValues] = useState<Record<string, string[]>>({
    language: [lang],
  })

  const onChange: MenuProps['onCheckedValueChange'] = (
    _e,
    { name, checkedItems },
  ) => {
    setCheckedValues((s) => {
      return s ? { ...s, [name]: checkedItems } : { [name]: checkedItems }
    })
    setLang(checkedItems[0] as IntlLang)
  }

  return (
    <Menu
      hasCheckmarks
      checkedValues={checkedValues}
      onCheckedValueChange={onChange}
    >
      <MenuTrigger>
        <ToolbarButton aria-label="More" icon={<Globe24Regular />} />
      </MenuTrigger>

      <MenuPopover style={{ overflow: 'hidden' }}>
        <MenuList>
          <MenuItemRadio icon={<IntlFlagEn />} name="language" value="en">
            English
          </MenuItemRadio>
          <MenuItemRadio icon={<IntlFlagJa />} name="language" value="ja">
            日本語
          </MenuItemRadio>
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
