import { Button, Caption1, Field, Input, Label, makeStyles, tokens } from '@aisekisan/bond'
import { AddCircle20Regular } from '@fluentui/react-icons'
import type { Dispatch, ReactElement, SetStateAction } from 'react'
import type { EquipmentAttributeTreeType, EquipmentClass } from '../../type'
import type { EquipmentAttributeTreeNode, SelectedNode } from '../type'
import { T } from '../../libs/intl/t'
import { useT } from '../../libs/intl/useT'
import { cloneAndReplaceIDs, getLatestID, getSelectedLevel } from './utils'
import { Node } from './node/node'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  hint: {
    color: tokens.colorNeutralForeground3,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalS,
  },
})

interface Props {
  root: EquipmentAttributeTreeNode
  tree: EquipmentAttributeTreeNode
  equip: EquipmentClass
  treeType: EquipmentAttributeTreeType
  activeIDNodes: number[]
  readonly?: boolean
  setSelected: Dispatch<SetStateAction<SelectedNode | null>>
  onChange: (tree: EquipmentAttributeTreeNode) => void
}

export function PropertyTree(props: Props): ReactElement | null {
  const { onChange, tree, readonly, setSelected, root, activeIDNodes, equip, treeType } = props

  const styles = useStyles()
  const t = useT()

  const add = (value: string, node: EquipmentAttributeTreeNode) => {
    node.children.push({ id: getLatestID(root) + 1, value, children: [] })
    onChange(tree)
  }

  const update = (value: string, node: EquipmentAttributeTreeNode) => {
    node.value = value
    onChange(tree)
  }

  const remove = (id: number, parentNode: EquipmentAttributeTreeNode) => {
    const removedID = parentNode.children.findIndex(node => node.id === id)
    parentNode.children.splice(removedID, 1)

    if (activeIDNodes.includes(id)) {
      const level = getSelectedLevel(root, parentNode.id, 0)
      setSelected({ node: parentNode, level })
    }

    onChange(tree)
  }

  const duplicate = (
    node: EquipmentAttributeTreeNode,
    parentNode: EquipmentAttributeTreeNode,
  ) => {
    const clonedNode = cloneAndReplaceIDs(node, getLatestID(root) + 1)
    parentNode.children.push(clonedNode)
    onChange(tree)
  }

  if (readonly && tree.children.length === 0)
    return null

  return (
    <div className={styles.container}>
      <Field
        label={t('preset-property.size.label')}
        hint={t('preset-property.size.label.hint')}
      >
        <Input
          onChange={event => update(event.target.value, tree)}
          appearance="filled-darker"
          value={tree.value}
        />
      </Field>

      <Label>
        <T id="preset-property.property.options" />
      </Label>
      <div className={styles.wrapper}>
        {tree.children.map(node => (
          <Node
            key={node.id}
            root={root}
            node={node}
            equip={equip}
            treeType={treeType}
            activeIDNodes={activeIDNodes}
            onChange={onChange}
            duplicate={() => duplicate(node, tree)}
            update={value => update(value, node)}
            remove={() => remove(node.id, tree)}
            setSelected={() => {
              const level = getSelectedLevel(root, node.id, 0)
              setSelected({ node, level })
            }}
          />
        ))}
      </div>

      <div>
        <Button icon={<AddCircle20Regular />} onClick={() => add('', tree)}>
          <T id="preset-property.option.create" />
        </Button>
      </div>
      <Caption1 className={styles.hint}>
        <T id="preset-property.option.create.hint.1" />
      </Caption1>
      <Caption1 className={styles.hint}>
        <T id="preset-property.option.create.hint.2" />
      </Caption1>
    </div>
  )
}
