import { Body1, Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, ProgressBar, makeStyles, tokens } from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { jsPDF as JSPDF } from 'jspdf'
import { Canvas } from './canvas/canvas'
import type { DIRECTION, PdfImage, PdfRenderError, PdfRenderState } from './utils'
import { DEFAULT_PDF_RENDER_ERROR, PAPER_DETAILS } from './utils'
import { T } from '@/libs/intl/t'
import type { tFn } from '@/libs/intl/useT'
import { useT } from '@/libs/intl/useT'

interface Props {
  selectedPages: string[]
  close: () => void
}

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalL,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
})

export function PrintPages(props: Props): JSX.Element {
  const { close, selectedPages } = props
  const total = selectedPages.length

  const t = useT()
  const styles = useStyles()

  const [state, setState] = useState<PdfRenderState>({ mode: 'idle' })
  const [error, setError] = useState<PdfRenderError>(DEFAULT_PDF_RENDER_ERROR)
  const [images, setImages] = useState<PdfImage[]>([])

  useEffect(() => {
    if (images.length === total - error.failedCount)
      exportAllPDF(images, setState, close)
  }, [close, images, error.failedCount, total])

  return (
    <Dialog open onOpenChange={close}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle action={(
            <DialogTrigger action="close">
              <Button
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
                onClick={close}
              />
            </DialogTrigger>
          )}
          >
            <T id="page.print.title" />
          </DialogTitle>
          <DialogContent>
            <div className={styles.content}>
              <Body1>
                <T id="page.print.category.helper.1" />
              </Body1>
              <Body1>
                <T id="page.print.helper.2" />
              </Body1>

              {state.mode === 'generating' && selectedPages.map(page => (
                <Canvas
                  id={page}
                  key={page}
                  setPdfImages={setImages}
                  setPdfRenderError={setError}
                />
              ))}

              <Field
                validationMessage={getProgressBarMsg(state, error, total, t)}
                validationState="none"
              >
                <ProgressBar
                  thickness="large"
                  value={getProgressBarValue(state, error, images.length, total)}
                  max={total !== 0 ? total : 1}
                />
              </Field>
            </div>

          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              disabled={state.mode !== 'idle' || error.failedCount !== 0}
              onClick={() => setState({ mode: 'generating' })}
            >
              <T id="page.print.submit" />
            </Button>
            <Button onClick={close}>
              <T id="page.delete.cancel" />
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}

function getProgressBarMsg(state: PdfRenderState, error: PdfRenderError, total: number, t: tFn): string {
  const pdfPage = state.mode === 'generated' ? state.pdfPage : 0

  switch (state.mode) {
    case 'idle':
      return t('page.print.helper.3')
    case 'generating':
    case 'generated':
      if (error.failedCount !== 0) {
        return [
          `${t('page.print.helper.creating_pdf')} ${t('page.print.page.label')} ${pdfPage}/${total - error.failedCount}`,
          `${t('page.print.failed')}: ${error.failedCount}`,
        ].join(' - ')
      }
      return `${t('page.print.helper.creating_pdf')} ${t('page.print.page.label')}: ${pdfPage}/${total}`
  }
}

function getProgressBarValue(state: PdfRenderState, error: PdfRenderError, currentPage: number, total: number) {
  const rate = (total - error.failedCount) / ((total - error.failedCount) * 2)

  let valueProgress = 0
  switch (state.mode) {
    case 'idle':
      return 0
    case 'generating':
      valueProgress = currentPage === 0 ? 0.1 : valueProgress + 0.8 * rate * currentPage
      return currentPage === 0 ? 0.1 : valueProgress + 0.8 * rate * currentPage
    case 'generated':
      valueProgress = Math.min(valueProgress + 0.8 * rate * (state.pdfPage + 1), total)
      return Math.min(valueProgress + 0.8 * rate * (state.pdfPage + 1), total)
  }
}

async function exportAllPDF(
  images: { image: string, direction: DIRECTION }[],
  setPdfRenderState: Dispatch<SetStateAction<PdfRenderState>>,
  close: () => void,
): Promise<void> {
  /** Create new pdf */
  const pdf = new JSPDF({
    format: 'a4',
    compress: true,
    unit: 'pt',
  })

  let index = 0
  for (const imgData of images) {
    index = index + 1
    setPdfRenderState({ mode: 'generated', pdfPage: index })

    if (imgData.image === '')
      continue

    await new Promise(resolve => window.setTimeout(resolve, 1000))
    const imgProps = pdf.getImageProperties(imgData.image)
    const width = imgData.direction === 'landscape' ? PAPER_DETAILS.A4.height : PAPER_DETAILS.A4.width
    const height = imgData.direction === 'landscape' ? (imgProps.height * width) / imgProps.width : pdf.internal.pageSize.getHeight()

    pdf.addPage('a4', imgData.direction === 'landscape' ? 'landscape' : 'portrait')

    pdf.addImage({
      imageData: imgData.image,
      format: 'PNG',
      x: 0,
      y: 0,
      width,
      height,
    })
  }

  if (pdf.getNumberOfPages() > 1) {
    /** Remove first blank page */
    pdf.deletePage(1)
    /** Download pdf */
    pdf.save('export.pdf')
    setPdfRenderState({ mode: 'idle' })
    close()
  }
}
