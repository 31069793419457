import type { IntlLang } from '@aisekisan/bond'
import { INTL_LANGS, useIntl } from '@aisekisan/bond'

const OPTIONS = {
  'long-short': {
    dateStyle: 'long',
    timeStyle: 'short',
  },
  'short-short': {
    dateStyle: 'short',
    timeStyle: 'short',
  },
  'short-none': {
    dateStyle: 'short',
  },
} satisfies Record<string, Intl.DateTimeFormatOptions>

type Format = keyof typeof OPTIONS

const FORMATS: Record<Format, Record<IntlLang, Intl.DateTimeFormat>> = (() => {
  const result: any = {}
  Object.entries(OPTIONS).forEach((entry) => {
    const [format, option] = entry
    const foo: any = {}
    INTL_LANGS.forEach((lang) => {
      foo[lang] = new Intl.DateTimeFormat(lang, option)
    })
    result[format] = foo
  })
  return result
})()

function formatDate(options: { format: Format, lang: IntlLang }, utc: string): string {
  const { format, lang } = options
  let text = utc
  if (text.length === 'YYYY-MM-DDTHH:mm:ss'.length)
    text = `${text}.000Z`
  const date = new Date(text)
  text = FORMATS[format][lang].format(date)
  return text
}

export function useFormatDate(format: Format) {
  const { lang } = useIntl()
  const fn = formatDate.bind(null, { format, lang })
  return fn
}
