import type { VecLike } from '@tldraw/tldraw'
import { Box, createShapeId } from '@tldraw/tldraw'
import type { AnnotShapeColor } from '../../../shape/color'
import { ANNOT_SHAPE_OPACITY } from '../../../shape/color'
import { createSegmentMeta } from '../../base'
import type { SegmentExtensionMeta, SegmentExtensionPartial } from './shape'

const CONSTANT_BASE = {
  opacity: ANNOT_SHAPE_OPACITY,
} satisfies Omit<SegmentExtensionPartial, 'id' | 'meta' | 'type'>

const CONSTANT_PROPS = {
  geo: 'rectangle',
  w: 10,
  h: 10,
  dash: 'solid',
  size: 's',
} satisfies SegmentExtensionPartial['props']

interface MetaProps {
  group: string
  mm: number
}

function createMeta(props: MetaProps): SegmentExtensionMeta {
  const { group, mm } = props

  return { ...(createSegmentMeta({ group })), segment: 'extension', mm }
}

export function createSegmentExtensionPartial(params: {
  vec: VecLike
  meta: MetaProps
  color: AnnotShapeColor
}): SegmentExtensionPartial {
  const { vec, color, meta } = params

  const { w, h } = CONSTANT_PROPS
  const box = Box.FromCenter(vec, { x: w, y: h })

  return {
    ...CONSTANT_BASE,
    id: createShapeId(),
    type: 'geo',
    x: box.x,
    y: box.y,
    props: { ...CONSTANT_PROPS, color },
    meta: createMeta(meta) as any,
  }
}
