import type { SpinnerProps } from '@fluentui/react-components'
import { Spinner, makeStyles } from '@fluentui/react-components'

const useStyles = makeStyles({
  spinnerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  overlayBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(245, 245, 244, 0.5)',
    zIndex: -1,
  },
  spinner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

type Props = SpinnerProps & {
  visible: boolean
}

export function OverlaySpinner(props: Props): JSX.Element | null {
  const { visible, ...rest } = props

  const styles = useStyles()

  if (!visible)
    return null

  return (
    <div className={styles.spinnerContainer}>
      <div className={styles.overlayBackground}></div>
      <div className={styles.spinner}>
        <Spinner {...rest} />
      </div>
    </div>
  )
}
