import { OverlaySpinner, makeStyles, tokens } from '@aisekisan/bond'
import { useOrgDetail } from '@aisekisan/anya-api'
import { AppPage } from '@/AppPage'
import { ContainerWrapper } from '@/ContainerWrapper'
import { OrgConstructionList } from '@/components/org/construction/list'
import { OrgInformation } from '@/components/org/information/detail'
import { OrgMemberList } from '@/components/org/member/list'
import { OrgPresetList } from '@/components/org/preset/list'
import { useT } from '@/libs/intl/useT'
import { NotFound } from '@/components/not-found'
import { OrgStatsPanel } from '@/components/org/stats/panel'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalL,
  },
})
export function OrgDetailPage(props: { id: string }): JSX.Element {
  const { id: orgID } = props

  const org = useOrgDetail({ orgID })

  const data = org.data
  const t = useT()
  const styles = useStyles()

  if (org.isLoading)
    return <OverlaySpinner visible={org.isLoading} />
  return (
    <AppPage
      title={`${t('org.title')} ${org.data?.name ?? ''}`}
      breadcrumbs={data
        ? [
            {
              title: `${t('org.title')} ${data.name}`,
              href: `/org/${data.id}`,
            },
          ]
        : []}
    >
      {org.isError && <NotFound />}
      {data
      && (
        <div className={styles.wrapper}>
          <ContainerWrapper>
            <OrgInformation org={org.data} />
            <OrgStatsPanel orgID={orgID} />
            <OrgConstructionList orgID={orgID} />
          </ContainerWrapper>
          <ContainerWrapper>
            <OrgPresetList orgID={orgID} />
            <OrgMemberList orgID={orgID} />
          </ContainerWrapper>
        </div>
      )}

    </AppPage>
  )
}
