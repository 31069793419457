import type {
  ComboboxProps,
} from '@aisekisan/bond'
import {
  Combobox,
  Field,
  Option,
  makeStyles,
  tokens,
  useIntl,
} from '@aisekisan/bond'
import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import { useEquipmentClasses } from '@aisekisan/anya-api'
import { ANNOTATION_TYPE } from '../utils'
import { useT } from '@/libs/intl/useT'

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: tokens.spacingHorizontalL,
    columnGap: tokens.spacingHorizontalL,
  },
})

export interface PageFilter {
  equipmentClass: string
  annotation: string
}

interface FilterProps {
  filter: PageFilter
  setFilter: Dispatch<SetStateAction<PageFilter>>
}

interface ComboboxOption {
  value: string
  label: string
}

interface CustomComboboxProps {
  label: string
  options: ComboboxOption[]
  defaultValue: string
  onChange: (value: string) => void
  clearable?: boolean
}

function ControlledCombobox(props: CustomComboboxProps): JSX.Element {
  const { label, options, defaultValue, onChange, clearable } = props

  const [selectedOptions, setSelectedOptions] = useState<string[]>([
    defaultValue,
  ])

  const onOptionSelect: ComboboxProps['onOptionSelect'] = (_ev, data) => {
    setSelectedOptions(data.selectedOptions)
    onChange(data.selectedOptions[0])
  }

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  return (
    <Field label={label}>
      <Combobox
        value={
          options.find(option => option.value === defaultValue)?.label ?? ''
        }
        selectedOptions={selectedOptions}
        onInput={onInput}
        onOptionSelect={onOptionSelect}
        clearable={clearable ?? false}
      >
        {options.map(option => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Combobox>
    </Field>
  )
}
export function FilterPages(props: FilterProps): JSX.Element {
  const { filter, setFilter } = props

  const t = useT()
  const styles = useStyles()
  const { lang } = useIntl()

  const equipmentClasses = useEquipmentClasses().data ?? []

  const ANNOTATION_OPTIONS = [
    {
      label: t('category.list.filter.all_pages'),
      value: 'all',
    },
    {
      label: t('category.list.filter.with_annotations'),
      value: 'with-annotations',
    },
    {
      label: t('category.list.filter.without_annotations'),
      value: 'without-annotations',
    },
  ]

  return (
    <div className={styles.container}>
      <ControlledCombobox
        label={t('category.list.report.annotations')}
        options={ANNOTATION_OPTIONS}
        defaultValue={filter.annotation}
        onChange={(value: string) => {
          if (value !== ANNOTATION_TYPE.WITH_ANNOTATIONS)
            setFilter({ ...filter, annotation: value, equipmentClass: '' })
          else setFilter({ ...filter, annotation: value })
        }}
      />
      {filter.annotation === ANNOTATION_TYPE.WITH_ANNOTATIONS
        ? (
          <ControlledCombobox
            label={t('category.list.filter.equipment')}
            options={equipmentClasses
              .filter(equipmentClass => equipmentClass.locale === lang)
              .map(equipmentClass => ({
                value: equipmentClass.equipmentClass.toString(),
                label: equipmentClass.localed,
              }))}
            defaultValue={filter.equipmentClass}
            onChange={(value: string) => {
              setFilter({ ...filter, equipmentClass: value })
            }}
            clearable
          />
          )
        : null}
    </div>
  )
}
