import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import type { UseFormReturn } from 'react-hook-form'
import type { ReactElement } from 'react'
import type { ConstructionFormValues } from './type'
import { ConstructionName } from './name'
import { ConstructionCode } from './code'
import { ConstructionPreset } from './preset'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  dialog: {
    minWidth: '736px',
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: tokens.spacingHorizontalL,
    rowGap: tokens.spacingHorizontalL,
  },
})

interface Props {
  form: UseFormReturn<ConstructionFormValues>
  orgID: string
  children: React.ReactNode
  close: () => void
  submit: (values: ConstructionFormValues) => void
  isCreate?: boolean
}

export function ConstructionFormDialog(props: Props): ReactElement {
  const { form, orgID, children, close, submit, isCreate = false } = props

  const styles = useStyles()

  const onSubmit = form.handleSubmit((values: ConstructionFormValues) => {
    submit(values)
  })

  return (
    <Dialog open onOpenChange={close}>
      <DialogSurface className={styles.dialog}>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <DialogTitle
              action={(
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    onClick={close}
                  />
                </DialogTrigger>
              )}
            >
              {isCreate
                ? <T id="construction.create.title" />
                : <T id="construction.update.title" />}
            </DialogTitle>

            <DialogContent>
              {children}
              <div className={styles.flex}>
                <div className={isCreate ? styles.grid : styles.flex}>
                  <ConstructionName form={form} />
                  <ConstructionCode form={form} />
                </div>
                {isCreate && <ConstructionPreset orgID={orgID} form={form} />}
              </div>
            </DialogContent>

            <DialogActions>
              <Button
                appearance="primary"
                type="submit"
              >
                {isCreate
                  ? <T id="construction.create.submit" />
                  : <T id="construction.update.submit" />}
              </Button>
              <Button onClick={close}>
                <T id="dialog.action.cancel" />
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )
}
