import { useQuery } from '@tanstack/react-query'
import { server } from '../server'
import type {
  OrgReportingTemplateCategory,
  OrgReportingTemplateDetail,
} from '../generated'

type ReportMap = Record<OrgReportingTemplateCategory, OrgReportingTemplateDetail>

export function useOrgReportTemplates(params: { orgID: string }) {
  const { orgID } = params
  return useQuery({
    queryKey: ['report-templates', orgID],
    queryFn: () => server.listOrgReportingTemplates(orgID),
    // Only get templates that have both Summary and CSV format
    select: (data): OrgReportingTemplateDetail[] => {
      const map = new Map<string, ReportMap>()

      data.forEach((item) => {
        const key = `${item.templateName}-${item.template}`
        const value = map.get(key) ?? {} as ReportMap
        map.set(key, { ...value, [item.templateCategory]: item })
      })

      return [...map.values()]
        .filter(item => !!item.Summary && !!item.CSV)
        .map(item => item.Summary)
    },
  })
}
