import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { OmitCaptcha } from '../captcha'
import type { OrgInvitationStatus, OrgRole } from '../generated'
import { server } from '../server'

const KEY = 'org-invitation'

export function useOrgInvitationList(params: {
  orgID: string
  status: OrgInvitationStatus
  role: OrgRole
}) {
  const { orgID, status, role } = params
  return useQuery({
    queryKey: [KEY, orgID],
    queryFn: () => server.listOrgInvitations(orgID, status, 0, 200),
    enabled: role === 'owner',
  })
}

export type OrgInvitationCreateBody = OmitCaptcha<
  Parameters<typeof server.orgInvite>[1]
>

export function useOrgInvitationCreate(init: { orgID: string }) {
  const { orgID } = init
  const client = useQueryClient()

  return useMutation({
    mutationFn: async (body: OrgInvitationCreateBody) => {
      const { email, locale, role } = body
      return server.orgInvite(orgID, { email, locale, role })
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: [KEY, orgID] })
    },
  })
}

export function useOrgInvitationDelete(init: { orgID: string }) {
  const { orgID } = init
  const client = useQueryClient()

  return useMutation({
    mutationFn: (body: Parameters<typeof server.deleteOrgInvitation>[0]) => {
      return server.deleteOrgInvitation(body)
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: [KEY, orgID] })
    },
  })
}
