import { INTL_DICT_EN } from './libs/intl/dicts/en'
import { INTL_DICT_JA } from './libs/intl/dicts/ja'

export const PRESET_PROPERTY_DICT = {
  EN: INTL_DICT_EN,
  JA: INTL_DICT_JA,
}

export * from './preset'
export * from './property'

export { getEquipmentIcon } from './equipment-class'
