import { useQuery } from '@tanstack/react-query'
import type { EquipmentClass } from '../generated'
import { server } from '../server'

interface ConstructionID { constructionID: string }
interface ReportAnnotationRequest {
  constructionID: string
  categoryID?: string
  pageID?: string
  equipmentClass?: EquipmentClass
  equipmentClassOther?: string
}

const getAllQueryKey = () => ['annotation']

export function getReportAnnotationKey(params: ConstructionID) {
  return [
    ...getAllQueryKey(),
    'report',
    params.constructionID,
  ]
}

export function useReportAnnotations(params: ReportAnnotationRequest) {
  const { constructionID, categoryID, pageID, equipmentClass } = params
  return useQuery({
    queryFn: () =>
      server.reportAnnotations(
        constructionID,
        categoryID,
        pageID,
        equipmentClass,
      ),
    queryKey: getReportAnnotationKey({ constructionID }),
    enabled: constructionID !== '',
  })
}
