import { useForm } from 'react-hook-form'
import { Body1, Button, Input, Label, Text } from '@aisekisan/bond'
import { Key20Regular } from '@fluentui/react-icons'
import { parseServerError, useAccountSignInOTP } from '@aisekisan/anya-api'
import { useStyles } from '../styles'
import { T } from '@/libs/intl/t'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'
import { useLocation } from '@/hooks/use-location'

export function SignInOTP(): JSX.Element {
  const t = useT()
  const styles = useStyles()
  const { navigate } = useLocation()

  const form = useForm<{ passcode: string }>({
    defaultValues: {
      passcode: '',
    },
  })

  const signInOTP = useAccountSignInOTP()

  const onSubmit = (values: { passcode: string }) => {
    signInOTP.mutate(
      { passcode: values.passcode },
      { onSuccess: () => void navigate('/') },
    )
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className={styles.flexCol}>
      <Text className={styles.title3}>
        <T id="sign-in.otp.verification.title" />
      </Text>
      {signInOTP.isError && (
        <ErrorAlert
          title={t('sign-in.otp.error.title')}
          help={t('sign-in.otp.error.help')}
          detail={parseServerError(signInOTP.error)}
          onClose={() => signInOTP.reset()}
        />
      )}
      <Body1 className={styles.textBody}>
        <T id="sign-in.otp.help" />
      </Body1>
      <div className={styles.field}>
        <Label htmlFor="passcode">{t('sign-in.otp.field.otp')}</Label>
        <Input
          type="number"
          id="passcode"
          maxLength={6}
          {...form.register('passcode')}
          placeholder="123456"
          required
          contentBefore={<Key20Regular />}
          size="large"
          appearance="filled-darker"
        />
      </div>

      <div className={styles.fullWidth}>
        <Button type="submit" appearance="primary" size="large">
          <T id="sign-in.submit" />
        </Button>
      </div>
    </form>
  )
}
