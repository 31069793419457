import type { EquipmentClass } from '@aisekisan/anya-api'

export const EQUIP_CLASS = {
  OTHER: 0,
  ROUND_DUCT: 1,
  VENTCAP: 2,
  INSULATION: 3,
  REFRIGERANT_PIPE: 4,
  DRAIN_PIPE: 5,
  RECTANGLE_DUCT: 6,
} as const satisfies Record<string, EquipmentClass>
