import { Button, Text, useIntl } from '@aisekisan/bond'
import { useForm } from 'react-hook-form'
import type { SetState } from '@aisekisan/bond'
import { parseServerError, useAccountAcceptInvitation } from '@aisekisan/anya-api'
import type { AccountAcceptInvitationBody, AccountInvitationDetail, Locale } from '@aisekisan/anya-api'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { AccountLayout } from '../../layout'
import { SignUpFields } from '../fields'
import { useStyles } from '../../styles'
import type { SignUpBody } from '../../type'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'

interface Props {
  token: string
  invitation: AccountInvitationDetail
  setSuccess: SetState<boolean>
  locale: Locale
}

const DEFAULT_VALUES = {
  companyName: '',
  firstNameKana: '',
  lastNameKana: '',
  middleName: '',
  middleNameKana: '',
  phone: '',
  password: '',
  firstName: '',
  lastName: '',
} satisfies Partial<AccountAcceptInvitationBody>

export function SignUpAcceptForm(props: Props): JSX.Element {
  const { token, invitation, setSuccess, locale } = props

  const t = useT()
  const styles = useStyles()
  const { lang } = useIntl()

  const schema = z.object({
    password: z.string().min(10, { message: t('account.field.password.helper') }),
    companyName: z.string().optional(),
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
    firstNameKana: z.string().optional(),
    lastNameKana: z.string().optional(),
    locale: z.string().optional(),
    middleName: z.string().optional(),
    middleNameKana: z.string().optional(),
    phone: z.string().optional(),
    token: z.string(),
  })

  const form = useForm<SignUpBody>({
    defaultValues: {
      ...DEFAULT_VALUES,
      email: invitation.email,
      ...{ locale, token },
    },
    resolver: zodResolver(schema),
  })

  const accept = useAccountAcceptInvitation()

  const submit = (body: SignUpBody) => {
    accept.mutate(
      { ...body, locale: lang, token, email: invitation.email },
      { onSuccess: () => void setSuccess(true) },
    )
  }

  return (
    <AccountLayout>
      <div className={styles.card}>
        <form onSubmit={form.handleSubmit(submit)} className={styles.flexCol}>
          <Text className={styles.title3}>
            <T
              id="sign-up.accept.form.title {{.orgName}}"
              params={{ orgName: `${invitation.orgName}` }}
            />
          </Text>
          <SignUpFields form={form} emailReadOnly />
          {accept.isError
            ? (
              <ErrorAlert
                title={t('sign-up.accept.form.error.title')}
                help={t('sign-up.accept.form.error.help')}
                detail={parseServerError(accept.error)}
                onClose={() => accept.reset()}
              />
              )
            : null}

          <div className={styles.fullWidth}>
            <Button type="submit" appearance="primary" size="large">
              <T id="sign-up.accept.form.submit" />
            </Button>
          </div>
        </form>
      </div>
    </AccountLayout>
  )
}
