import type { RouteProps } from 'wouter'
import { SignUpAcceptPage } from './pages/public/accept-sign-up'
import { ResetPasswordPage } from './pages/public/reset-password'
import { SignInPage } from './pages/public/sign-in'

export const PUBLIC_ROUTES: RouteProps[] = [
  {
    path: '/sign-in',
    component: SignInPage,
  },
  { path: '/reset-password', component: ResetPasswordPage },
  { path: '/accept-invite', component: SignUpAcceptPage },
]
