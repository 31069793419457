import type {
  MenuProps,
} from '@aisekisan/bond'
import {
  Menu,
  MenuButton,
  MenuItemRadio,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from '@aisekisan/bond'
import { KeyRegular, PersonRegular } from '@fluentui/react-icons'
import { useState } from 'react'
import type { OrgRole } from '@aisekisan/anya-api'
import { useOrgMemberUpdateRole } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'

interface Props {
  orgID: string
  accountID: string
  role: OrgRole
}

export function UpdateRoleSelect(props: Props) {
  const { orgID, accountID, role } = props

  const [updateRole, setUpdateRole] = useState<Record<string, string[]>>({
    role: [role],
  })

  const update = useOrgMemberUpdateRole({ orgID })

  const onChange: MenuProps['onCheckedValueChange'] = (
    _e,
    { name, checkedItems },
  ) => {
    setUpdateRole((s) => {
      return s ? { ...s, [name]: checkedItems } : { [name]: checkedItems }
    })
    update.mutate({
      accountID,
      orgRole: checkedItems[0] as OrgRole,
    })
  }

  return (
    <Menu onCheckedValueChange={onChange} checkedValues={updateRole}>
      <MenuTrigger>
        <MenuButton
          appearance="transparent"
          icon={role === 'member' ? <PersonRegular /> : <KeyRegular />}
          style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: 'normal',
          }}
        >
          {role === 'owner' ? <T id="org.role.owner.title" /> : <T id="org.role.member.title" />}
        </MenuButton>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          <MenuItemRadio icon={<PersonRegular />} name="role" value="member">
            <T id="org.role.member.title" />
          </MenuItemRadio>
          <MenuItemRadio icon={<KeyRegular />} name="role" value="owner">
            <T id="org.role.owner.title" />
          </MenuItemRadio>
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
