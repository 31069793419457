export const ATTR_EQUIP_VALUES = {
  ROUND_DUCT: 1,
  VENT_CAP: 2,
  INSULATION: 3,
  REFRIGERANT_PIPE: 4,
  DRAIN_PIPE: 5,
  SANITARY_PIPE: 7,
  FIRE_PROTECTION_PIPE: 76,
  SPRINKLER_PIPE: 77,
  FP_DRAIN_PIPE: 78,
  INDOOR_FIRE_HYDRANT_PIPE: 79,
  OUTDOOR_FIRE_HYDRANT_PIPE: 80,
  FIRE_HYDRANT_CONNECTION_PIPE: 81,
  SPRINKLER_CONNECTION_PIPE: 82,
  FOAM_FIRE_EXTINGUISHING_PIPE: 83,
  SPRINKLER_HEAD: 84,
  CX_HEAD_III: 85,
  FOAM_HEAD_YFH59: 86,
  FOAM_HEAD_NORMAL: 87,
  SPRINKLER_HEAD_50: 88,
  FIRE_EXTINGUISHER: 89,
  FIRE_HYDRANT: 90,
  PILLAR_HYDRANTS: 91,
  SENSOR: 92,
  GAS_LEAK_DETECTOR: 93,
} satisfies Record<string, number>

export const ATTR_EQUIP_FIRE_PIECES = [
  ATTR_EQUIP_VALUES.SPRINKLER_HEAD,
  ATTR_EQUIP_VALUES.CX_HEAD_III,
  ATTR_EQUIP_VALUES.FOAM_HEAD_YFH59,
  ATTR_EQUIP_VALUES.FOAM_HEAD_NORMAL,
  ATTR_EQUIP_VALUES.SPRINKLER_HEAD_50,
  ATTR_EQUIP_VALUES.FIRE_EXTINGUISHER,
  ATTR_EQUIP_VALUES.FIRE_HYDRANT,
  ATTR_EQUIP_VALUES.PILLAR_HYDRANTS,
  ATTR_EQUIP_VALUES.SENSOR,
  ATTR_EQUIP_VALUES.GAS_LEAK_DETECTOR,
]
