import type { REPORT_TEMPLATES } from '@aisekisan/bond'
import {
  Menu,
  MenuButton,
  MenuItemRadio,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from '@aisekisan/bond'
import { DocumentTable20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import type { OrgReportingTemplateDetail } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'

interface ExportButtonProps {
  templates: OrgReportingTemplateDetail[]
  templateID: REPORT_TEMPLATES
  setTemplateID: (templateID: REPORT_TEMPLATES) => void
}

export function ReportTemplate(props: ExportButtonProps): ReactElement {
  const { templates, templateID, setTemplateID } = props

  return (
    <Menu
      positioning="below-end"
      checkedValues={{ type: [templateID] }}
      onCheckedValueChange={(_, data) => {
        return setTemplateID(data.checkedItems[0] as REPORT_TEMPLATES)
      }}
    >
      <MenuTrigger disableButtonEnhancement>
        <MenuButton icon={<DocumentTable20Regular />}>
          <T id="report.template" />
        </MenuButton>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {templates.map(({ id, templateName, template }) => {
            return (
              <MenuItemRadio
                key={id}
                name="type"
                value={template}
              >
                {templateName}
              </MenuItemRadio>
            )
          })}
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
