import { Fragment } from 'react/jsx-runtime'
import groupBy from 'lodash/groupBy'
import type { ReportHead, ReportRow } from '../../type'
import {
  FIRE_REPORT_FIXED_COLS,
  FIRE_REPORT_FIXED_ROWS,
  getFireReportEmptyCols,
  getFireReportEmptyRows,
} from '../utils'

interface Props {
  reportHeads: ReportHead[]
  reportRows: ReportRow[]
}

export function FirePipeReportBody(props: Props) {
  const { reportHeads, reportRows } = props

  const groupedBySize = Object.entries(groupBy(reportRows, 'size'))

  const totalCols = reportHeads.length > FIRE_REPORT_FIXED_COLS
    ? reportHeads.length
    : FIRE_REPORT_FIXED_COLS

  const totalRows = groupedBySize.length > FIRE_REPORT_FIXED_ROWS
    ? groupedBySize.length
    : FIRE_REPORT_FIXED_ROWS

  return (
    <>
      {groupedBySize.map(([id, rows]) => {
        const lengths = rows.map((row) => {
          return reportHeads.map(head => getLength(row, head))
        })
        return (
          <Fragment key={id}>
            {rows.map((row, index) => {
              return (
                <tr key={row.id}>
                  {/* Size */}
                  {index === 0 && (
                    <td align="center" rowSpan={rows.length}>
                      {`${row.size}A`}
                    </td>
                  )}
                  {/* CA */}
                  <td align="center">{row.cArea}</td>
                  {/* Length */}
                  {lengths[index].map((length, index) => {
                    // Reports are read-only, so we can use index as key
                    // eslint-disable-next-line react/no-array-index-key
                    return <td key={index}>{length !== 0 ? length : ''}</td>
                  })}
                  {/* Empty cols */}
                  {getFireReportEmptyCols(totalCols - reportHeads.length)}
                  {/* Sub total */}
                  <td align="center">
                    {getTotal(lengths[index]).toFixed(1)}
                  </td>
                  {/* Total */}
                  {index === 0 && (
                    <td align="center" rowSpan={rows.length}>
                      {getTotal(Object.values(lengths).flat()).toFixed(1)}
                    </td>
                  )}
                </tr>
              )
            })}
          </Fragment>
        )
      })}
      {getFireReportEmptyRows(totalRows - groupedBySize.length, totalCols + 4)}
    </>
  )
}

function getLength(row: ReportRow, head: ReportHead): number {
  const { pageID, modules } = head
  if (modules.length === 0)
    return Number.parseFloat(row[pageID] ?? '0')

  return modules.reduce<number>((sum, cmodule) => {
    const key = cmodule ? `${pageID}/${cmodule.id}` : `${pageID}/no_module`
    return row[key] ? sum + Number.parseFloat(row[key]) : sum
  }, 0)
}

function getTotal(lengths: number[]): number {
  return lengths.reduce((acc, length) => acc + length, 0)
}
