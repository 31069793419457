import type {
  ReactNode,
} from 'react'
import {
  useEffect,
  useMemo,
  useState,
} from 'react'
import type { Translation } from './context'
import { IntlContext } from './context'
import type { IntlLang } from './lang'
import { isIntlLang } from './lang'

function getInitial(): IntlLang {
  const stored = window.localStorage.getItem('lang')
  if (stored === null)
    return 'ja'
  if (isIntlLang(stored))
    return stored
  return 'ja'
}

interface Dict {
  lang: IntlLang
  dict: Translation
}

export function IntlProvider(props: {
  translations: Dict[]
  children: ReactNode
}): JSX.Element {
  const { children, translations } = props

  const [lang, setLang] = useState<IntlLang>(getInitial)

  useEffect(() => {
    window.localStorage.setItem('lang', lang)
  }, [lang])

  const dict = translations.find(t => t.lang === lang)?.dict
  if (dict === undefined)
    throw new Error(`Missing dict for "${lang}"`)

  const value = useMemo(() => ({ lang, setLang, dict }), [lang, dict])

  return (
    <IntlContext.Provider value={value}>
      {children}
    </IntlContext.Provider>
  )
}
