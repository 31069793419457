import type { VecLike } from '@tldraw/tldraw'
import { Vec, createShapeId, getIndices } from '@tldraw/tldraw'
import type { AnnotShapeColor } from '../../shape/color'
import { ANNOT_SHAPE_OPACITY } from '../../shape/color'
import { createSegmentMeta } from '../base'
import type { SegmentFlatMeta, SegmentFlatPartial, SegmentFlatShape } from './shape'

function createMeta(props: {
  group: string
}): SegmentFlatMeta {
  const { group } = props

  return { ...(createSegmentMeta({ group })), segment: 'flat' }
}

const CONSTANT_BASE = {
  opacity: ANNOT_SHAPE_OPACITY,
  // "Partial" requires identification fields such as "id", but we don't need
  // to provide them here.
} satisfies Omit<SegmentFlatPartial, 'id' | 'meta' | 'type'>

const CONSTANT_PROPS = {
  dash: 'solid',
  size: 's',
} satisfies SegmentFlatPartial['props']

/**
 * Create a new paritial flat segment out of 2 points
 */
export function createSegmentFlatPartial(params: {
  start: VecLike
  end: VecLike
  group: string
  color: AnnotShapeColor
}): SegmentFlatPartial {
  const { color, start: absStart, end: absEnd, group } = params

  // We follow tldraw's pattern here that the "start" handle starts at 0, 0.
  // (the actual "start" point is the "line.x" and ".y".)
  const [startID, endID] = getIndices(2)
  const relEnd = Vec.From(absEnd).sub(absStart)
  const points: SegmentFlatShape['props']['points'] = {
    [startID]: { id: startID, index: startID, x: 0, y: 0 },
    [endID]: { id: endID, index: endID, x: relEnd.x, y: relEnd.y },
  }

  const flat: SegmentFlatPartial = {
    ...CONSTANT_BASE,
    id: createShapeId(),
    type: 'line',
    x: absStart.x,
    y: absStart.y,
    props: { ...CONSTANT_PROPS, color, points },
    meta: createMeta({ group }) as any,
  }
  return flat
}
