import { Field, Input, Text } from '@aisekisan/bond'
import { LockClosed20Regular, Mail20Regular } from '@fluentui/react-icons'
import type { UseFormReturn } from 'react-hook-form'
import { useStyles } from '../styles'
import type { SignUpBody } from '../type'
import { useT } from '@/libs/intl/useT'

interface Props {
  form: UseFormReturn<SignUpBody>
  emailReadOnly: boolean
}

export function SignUpFields(props: Props): JSX.Element {
  const { form, emailReadOnly } = props

  const { formState: { errors } } = form

  const t = useT()
  const styles = useStyles()

  return (
    <div className={styles.flexCol}>
      <div className={styles.field}>
        <Field
          label={t('account.field.email')}
          validationState={errors.email ? 'error' : 'none'}
          validationMessage={errors.email?.message}
        >
          <Input
            type="email"
            id="email"
            {...form.register('email')}
            placeholder="name@gmail.com"
            readOnly={emailReadOnly}
            contentBefore={<Mail20Regular />}
            size="large"
            appearance="filled-darker"
            defaultValue={form.getValues('email')}
          />
        </Field>
      </div>
      <div className={styles.field}>
        <Field
          label={t('account.field.password')}
          validationState={errors.password ? 'error' : 'none'}
          validationMessage={errors.password?.message}
        >
          <Input
            type="password"
            id="password"
            required
            {...form.register('password')}
            contentBefore={<LockClosed20Regular />}
            size="large"
            appearance="filled-darker"
          />

          <Text className={styles.hintText}>
            {t('account.field.password.helper')}
          </Text>
        </Field>
      </div>
      <div className={styles.field}>
        <Field
          label={t('account.field.last-name')}
          validationState={errors.lastName ? 'error' : 'none'}
          validationMessage={errors.lastName?.message}
        >
          <Input
            id="lastName"
            required
            {...form.register('lastName')}
            size="large"
            appearance="filled-darker"
          />
        </Field>
      </div>
      <div className={styles.field}>
        <Field
          label={t('account.field.first-name')}
          validationState={errors.firstName ? 'error' : 'none'}
          validationMessage={errors.firstName?.message}
        >
          <Input
            id="firstName"
            required
            {...form.register('firstName')}
            size="large"
            appearance="filled-darker"
          />
        </Field>
      </div>
    </div>
  )
}
