import { makeStyles, tokens } from '@aisekisan/bond'
import { useOrgPresetDetail } from '@aisekisan/anya-api'
import { AppPage } from '@/AppPage'
import { CreateOrgProperty } from '@/components/org/property/create'
import { useT } from '@/libs/intl/useT'

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
    columnGap: tokens.spacingHorizontalL,
    overflowX: 'auto',
    boxSizing: 'border-box',
  },
})

export function OrgPropertyCreatePage(props: { orgPresetID: string }): JSX.Element {
  const { orgPresetID } = props

  const t = useT()
  const styles = useStyles()

  const preset = useOrgPresetDetail({ orgPresetID }).data

  return (
    <AppPage
      breadcrumbs={[
        {
          title: preset?.name ?? '',
          href: `/org-preset/${preset?.id}`,
        },
        {
          title: t('org.preset.property.create'),
          href: ``,
        },

      ]}
    >
      <div className={styles.wrapper}>
        <CreateOrgProperty orgPresetID={orgPresetID} />
      </div>
    </AppPage>
  )
}
