import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { server } from '../server'

const KEY = 'org-member'

export function useOrgMemberList(params: { orgID: string }) {
  const { orgID } = params

  return useQuery({
    queryKey: [KEY, orgID],
    queryFn: () => server.listMembersInOrg(orgID, 0, 200),
  })
}

export function useOrgMemberRemove(init: { orgID: string }) {
  const { orgID } = init
  const client = useQueryClient()

  return useMutation({
    mutationFn: (body: { accountID: string }) => {
      const { accountID } = body
      return server.removeMemberFromOrg(orgID, { accountID })
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: [KEY, orgID] })
    },
  })
}

export function useOrgMemberUpdateRole(init: { orgID: string }) {
  const { orgID } = init
  const client = useQueryClient()

  type P = Parameters<typeof server.updateRoleForMemberInOrg>[1]
  return useMutation({
    mutationFn: (params: P) => {
      return server.updateRoleForMemberInOrg(orgID, params)
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: [KEY, orgID] })
    },
  })
}
