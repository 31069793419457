import type { Editor } from '@tldraw/tldraw'
import { TldrawEditor } from '@tldraw/tldraw'
import { useEffect, useRef, useState } from 'react'
import type { Dispatch, ReactElement, SetStateAction } from 'react'
import { makeStyles } from '@aisekisan/bond'
import { usePageDetail } from '@aisekisan/anya-api'
import { initPdf } from './init'
import { renderAnnots } from './render'
import { scalePdf } from './scale'
import { exportImage } from './export'
import type { PdfImage, PdfRenderError } from '@/components/category/page/export/utils'
import { shapeUtils } from '@/components/editor/editor/shape/util/shape'

const useStyles = makeStyles({
  editor: {
    display: 'none',
  },
})

interface Props {
  id: string
  setPdfRenderError: Dispatch<SetStateAction<PdfRenderError>>
  setPdfImages: Dispatch<SetStateAction<PdfImage[]>>
}

export function Canvas(props: Props): ReactElement {
  const { id, setPdfRenderError, setPdfImages } = props

  const styles = useStyles()

  const { data: pageDetail, error } = usePageDetail({ pageID: id })

  const [editor, setEditor] = useState<Editor>()

  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const canvas = canvasRef.current

  useEffect(() => {
    if (!error)
      return

    setPdfRenderError(({ failedCount, messages }) => {
      return {
        failedCount: failedCount + 1,
        messages: [...messages, error.message],
      }
    })
  }, [error, setPdfRenderError])

  useEffect(() => {
    if (!canvas || !pageDetail || !editor)
      return

    const timeout = setTimeout(async () => {
      const { scale, signedURL: url } = pageDetail

      await initPdf({ canvas, url })
      scalePdf({ editor, scale })
      await renderAnnots({ editor, pageID: id })
      await exportImage({ editor, canvas, url, setPdfImages })
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [canvas, editor, id, pageDetail, setPdfImages])

  return (
    <TldrawEditor
      onMount={setEditor}
      shapeUtils={shapeUtils}
      className={styles.editor}
    >
      <canvas ref={canvasRef} />
    </TldrawEditor>
  )
}
