import { Button, Caption1, Field, Label, Select, Subtitle2, makeStyles, shorthands, tokens } from '@aisekisan/bond'
import { AddCircle20Regular, Delete20Regular, Ruler20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useState } from 'react'
import type { EquipmentAttributeTreeNode } from '../type'
import { T } from '../../libs/intl/t'
import { useT } from '../../libs/intl/useT'
import { InsulForm } from './form'
import { DeleteInsulProperty } from './delete'
import type { CustomInsulOption } from './type'
import { getInsulOptionsInitial } from './type'

const useStyles = makeStyles({
  children: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    backgroundColor: tokens.colorNeutralForegroundOnBrand,
    ...shorthands.padding(tokens.spacingVerticalL),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.spacingHorizontalS,
  },
  actionGroup: {
    alignSelf: 'end',
  },
  hint: {
    color: tokens.colorNeutralForeground3,
  },
})

interface Props {
  insulTree: EquipmentAttributeTreeNode | null
  material1: string
  insulOptions: CustomInsulOption[]
  node: EquipmentAttributeTreeNode
  onChange: (node: EquipmentAttributeTreeNode) => void
}

export function InsulProperty(props: Props): ReactElement {
  const { node, insulTree, material1, insulOptions, onChange } = props

  const t = useT()
  const styles = useStyles()

  const [deleteProperty, setDeleteProperty] = useState<boolean>(false)

  const onUpdateCover = (value: string) => {
    if (material1 !== '-1')
      return

    delete node.insulationOptions?.[material1]
    node.insulationOptions = {
      ...node.insulationOptions,
      [value]: insulOptions,
    }
    onChange(node)
  }

  const onUpdate = (options: CustomInsulOption[]) => {
    node.insulationOptions = {
      ...node.insulationOptions,
      [material1]: options,
    }
    onChange(node)
  }

  const onAddOption = () => {
    insulOptions.push(getInsulOptionsInitial()[0])
    onUpdate(insulOptions)
  }

  const onRemove = () => {
    if (node.insulationOptions === undefined)
      return

    if (Object.keys(node.insulationOptions).length === 1)
      node.insulationOptions = undefined
    else
      delete node.insulationOptions[material1]

    onChange(node)
  }

  return (
    <div className={styles.children}>
      <div className={styles.title}>
        <Ruler20Regular />
        <Subtitle2>
          <T id="preset-property.node.menu.insul" />
        </Subtitle2>
      </div>
      <Field
        label={t('preset-property.insul.cover')}
        hint={t('preset-property.insul.cover.hint')}
      >
        <Select
          appearance="filled-darker"
          value={material1}
          onChange={event => onUpdateCover(event.target.value)}
          disabled={material1 !== '-1'}
        >
          <option value={-1} disabled>
            <T id="preset-property.action.select-one" />
          </option>
          {insulTree?.children?.map(({ id, value }) => (
            <option key={id} value={id} disabled={node.insulationOptions?.[id] !== undefined}>
              {value}
            </option>
          ))}
        </Select>
      </Field>
      <Label>
        <T id="preset-property.property.options" />
      </Label>
      <InsulForm
        insulTree={insulTree}
        material1={material1}
        options={insulOptions}
        onUpdate={onUpdate}
      />
      <div>
        <Button
          icon={<AddCircle20Regular />}
          onClick={onAddOption}
        >
          <T id="preset-property.option.create" />
        </Button>
      </div>
      <Caption1 className={styles.hint}>
        <T id="preset-property.option.create.hint.1" />
      </Caption1>
      <div className={styles.actionGroup}>
        <Button
          icon={<Delete20Regular />}
          onClick={() => setDeleteProperty(true)}
        >
          <T id="preset-property.children.delete" />
        </Button>
      </div>
      {deleteProperty && (
        <DeleteInsulProperty
          close={() => setDeleteProperty(false)}
          onDelete={onRemove}
        />
      )}
    </div>
  )
}
