import type { Slot } from '@aisekisan/bond'
import { ChevronCircleDown20Regular, Map20Regular, SquareHint20Regular } from '@fluentui/react-icons'
import type { EquipmentAttributeTreeType } from '../../type'
import type { IntlDictId } from '../../libs/intl/dicts/type'

interface TreeType {
  id: EquipmentAttributeTreeType
  label: IntlDictId
  icon: Slot<'span'>
}

export const TREE_TYPES: TreeType[] = [
  {
    id: 'material',
    label: 'preset-property.treeType.material',
    icon: <Map20Regular />,
  },
  {
    id: 'construction_area',
    label: 'preset-property.treeType.cArea',
    icon: <SquareHint20Regular />,
  },
  {
    id: 'any',
    label: 'preset-property.treeType.any',
    icon: <ChevronCircleDown20Regular />,
  },
]

export function getTreeType(treeType: string) {
  return TREE_TYPES.find(type => type.id === treeType)
}
