import { Fragment } from 'react/jsx-runtime'
import groupBy from 'lodash/groupBy'
import type { ReactElement } from 'react'
import type { ReportHead, ReportRow } from '../../type'
import { FirePieceReportRow } from './row'

const GROUPED_KEYS = [
  'equipClassName',
  'material1',
  'material2',
  'size',
]

interface Props {
  reportHeads: ReportHead[]
  reportRows: ReportRow[]
}

/**
 * We want to render the report in a nested way, so we need to group the rows
 * by the keys in `GROUPED_KEYS` before render the rows.
 * The nested structure is expected to be like this:
 * - equipClassName
 *   - material1
 *     - material2
 *       - size
 *
 * For equipClassName, material1, and material2, we will render an empty row
 * with the value of the key in the first column. For size, we will render the
 * detail rows.
 */
export function FirePieceReportBody(props: Props): ReactElement | null {
  const { reportHeads, reportRows } = props

  const getNestedRows = (rows: ReportRow[], keys: string[]): ReactElement | null => {
    if (keys.length === 0)
      return null

    const [key, ...rest] = keys
    return (
      <>
        {Object.entries(groupBy(rows, key)).map(([id, groups]) => {
          const nextKey = rest.at(0)
          const emptyGroups = nextKey
            ? groups.filter(group => group[nextKey] === '')
            : []
          const nonEmptyGroups = nextKey
            ? groups.filter(group => group[nextKey] !== '')
            : groups

          return (
            <Fragment key={id}>
              {keys.length > 1
                ? (
                  <>
                    <FirePieceReportRow
                      equipments={id}
                      rows={emptyGroups}
                      reportHeads={reportHeads}
                    />
                    {getNestedRows(nonEmptyGroups, rest)}
                  </>
                  )
                : (
                  <FirePieceReportRow
                    equipments={rows[0][key]}
                    rows={rows}
                    reportHeads={reportHeads}
                  />
                  )}

              {/* Empty line between each equipment */}
              {key === 'equipClassName' && (
                <FirePieceReportRow
                  equipments=""
                  rows={[]}
                  reportHeads={reportHeads}
                />
              )}
            </Fragment>
          )
        })}
      </>
    )
  }

  return getNestedRows(reportRows, GROUPED_KEYS)
}
