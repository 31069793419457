import type { SVGProps } from 'react'

export function IconJPY(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5 2.5C8.58075 2.5 7.6705 2.68106 6.82122 3.03284C5.97194 3.38463 5.20026 3.90024 4.55025 4.55025C3.90024 5.20026 3.38463 5.97194 3.03284 6.82122C2.68106 7.6705 2.5 8.58075 2.5 9.5C2.5 10.4193 2.68106 11.3295 3.03284 12.1788C3.38463 13.0281 3.90024 13.7997 4.55025 14.4497C5.20026 15.0998 5.97194 15.6154 6.82122 15.9672C7.6705 16.3189 8.58075 16.5 9.5 16.5C10.4193 16.5 11.3295 16.3189 12.1788 15.9672C13.0281 15.6154 13.7997 15.0998 14.4497 14.4497C15.0998 13.7997 15.6154 13.0281 15.9672 12.1788C16.3189 11.3295 16.5 10.4193 16.5 9.5C16.5 8.58075 16.3189 7.6705 15.9672 6.82122C15.6154 5.97194 15.0998 5.20026 14.4497 4.55025C13.7997 3.90024 13.0281 3.38463 12.1788 3.03284C11.3295 2.68106 10.4193 2.5 9.5 2.5Z"
        fill="#F78F8F"
      />
      <path
        d="M9.5 3C13.084 3 16 5.916 16 9.5C16 13.084 13.084 16 9.5 16C5.916 16 3 13.084 3 9.5C3 5.916 5.916 3 9.5 3ZM9.5 2C5.358 2 2 5.358 2 9.5C2 13.642 5.358 17 9.5 17C13.642 17 17 13.642 17 9.5C17 5.358 13.642 2 9.5 2Z"
        fill="#C74343"
      />
      <path
        d="M12.215 5.75L10.358 9.151H11.771V9.993H10.091V10.95H11.77V11.792H10.091V13.252H8.89797V11.792H7.16697V10.95H8.89897V9.993H7.16697V9.151H8.60597L6.78497 5.75H8.09297L9.30097 8.334C9.39197 8.529 9.46697 8.716 9.52597 8.894H9.54697C9.58897 8.754 9.66697 8.558 9.78197 8.303L10.981 5.75H12.215Z"
        fill="white"
      />
    </svg>
  )
}
