import { Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@aisekisan/bond'
import { ChevronCircleDown20Regular, ChevronDown12Regular, Ruler20Regular, WindowBulletListAddRegular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import type { EquipmentAttributeTreeType, EquipmentClass } from '../../type'
import type { EquipmentAttributeTreeNode } from '../type'
import { getLatestID } from '../tree/utils'
import { getDataShapeOptionsInitial } from '../type'
import { getInsulThicknessInitial } from '../insul-size/type'
import { getInsulOptionsInitial } from '../insul/type'
import { EQUIP_CLASS } from '../../equipment-class'
import { T } from '../../libs/intl/t'

interface Props {
  root: EquipmentAttributeTreeNode
  node: EquipmentAttributeTreeNode
  equip: EquipmentClass
  treeType: EquipmentAttributeTreeType
  onChange: (node: EquipmentAttributeTreeNode) => void
}

export function PropertyMenu(props: Props): ReactElement {
  const { root, node, equip, treeType, onChange } = props

  const addProperty = () => {
    node.children.push({ id: getLatestID(root) + 1, value: '', children: [] })
    onChange(node)
  }

  const addSizeProperty = () => {
    node.shapeOptions = getDataShapeOptionsInitial()
    onChange(node)
  }

  const addInsulSizeProperty = () => {
    node.shapeOptions = node.shapeOptions ?? getDataShapeOptionsInitial()
    node.shapeOptions.shape = 'InsulationThickness'
    node.shapeOptions.insulShapes = {
      ...node.shapeOptions.insulShapes,
      [EQUIP_CLASS.OTHER]: [getInsulThicknessInitial()],
    }
    onChange(node)
  }

  const addInsulationProperty = () => {
    node.insulationOptions = { ...node.insulationOptions, '-1': getInsulOptionsInitial() }
    onChange(node)
  }

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <MenuButton
          icon={<WindowBulletListAddRegular />}
          menuIcon={<ChevronDown12Regular />}
        >
          <T id="preset-property.node.menu" />
        </MenuButton>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {treeType === 'material' && equip !== EQUIP_CLASS.INSULATION && (
            <MenuItem
              icon={<Ruler20Regular />}
              onClick={() => addSizeProperty()}
              disabled={node.shapeOptions !== undefined}
            >
              <T id="preset-property.node.menu.size" />
            </MenuItem>
          )}
          {treeType === 'material' && equip === EQUIP_CLASS.INSULATION && (
            <MenuItem
              icon={<Ruler20Regular />}
              onClick={() => addInsulSizeProperty()}
            >
              <T id="preset-property.node.menu.insul-size" />
            </MenuItem>
          )}
          {treeType === 'construction_area' && (
            <MenuItem
              icon={<Ruler20Regular />}
              onClick={() => addInsulationProperty()}
            >
              <T id="preset-property.node.menu.insul" />
            </MenuItem>
          )}
          <MenuItem
            icon={<ChevronCircleDown20Regular />}
            onClick={() => addProperty()}
          >
            <T id="preset-property.node.menu.children" />
          </MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
