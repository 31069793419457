import { useOrgMemberList, useOrgPresetList, useOrgStats } from '@aisekisan/anya-api'
import { Panel, makeStyles, tokens } from '@aisekisan/bond'
import {
  Building20Regular,
  CloudArrowDown20Regular,
  DataUsage24Regular,
  Database20Regular,
  DocumentLightning20Regular,
  DocumentOnePageAdd20Regular,
  DocumentOnePageMultiple20Regular,
  People20Regular,
  Predictions20Regular,
  Star20Regular,
} from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'
import { FieldInfo } from '@/components/field-info'

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    rowGap: tokens.spacingVerticalL,
    columnGap: tokens.spacingHorizontalL,
  },
})

interface Props {
  orgID: string
}

export function OrgStatsPanel(props: Props): ReactElement | null {
  const { orgID } = props

  const s = useStyles()
  const t = useT()

  const stats = useOrgStats({ orgID }).data
  const members = useOrgMemberList({ orgID }).data ?? []
  const orgPresetList = useOrgPresetList({ orgID }).data ?? []

  if (stats === undefined)
    return null

  return (
    <Panel
      title={<T id="org.stats.title" />}
      icon={<DataUsage24Regular />}
    >
      <div className={s.container}>
        <FieldInfo
          label={<T id="org.stats.pageUsed" />}
          value={`${stats.pageUsed} / ${stats.pagePoolCapacity} ${t('org.stats.pageUnit')}`}
          icon={<DocumentOnePageMultiple20Regular />}
        />

        <FieldInfo
          label={`${t('org.stats.maxOverPage')}`}
          value={`${stats.numOverPage} / ${stats.maxOverPage} ${t('org.stats.pageUnit')}`}
          icon={<DocumentOnePageAdd20Regular />}
        />

        <FieldInfo
          label={<T id="org.stats.storageUsed" />}
          value={`${stats.storageUsed.toLocaleString()} / ${stats.storageCapacity} GB`}
          icon={<Database20Regular />}
        />

        <FieldInfo
          label={<T id="org.stats.numConstruction" />}
          value={`${stats.numConstruction} / ${stats.maxNumConstruction} ${t('org.stats.constUnit')}`}
          icon={<Building20Regular />}
        />

        <FieldInfo
          label={`${t('org.preset.title')}`}
          value={`${orgPresetList.length.toString()} ${t('org.preset.title').toLowerCase()}`}
          icon={<DocumentLightning20Regular />}
        />

        <FieldInfo
          label={`${t('org.member.title')}`}
          value={`${members.length.toString()} ${t('org.member.unit')}`}
          icon={<People20Regular />}
        />

        {stats.numSeat !== 0 && (
          <FieldInfo
            label={<T id="org.stats.numSeat" />}
            value={`${stats.numSeat}`}
            icon={<Star20Regular />}
          />
        )}

        <FieldInfo
          label={<T id="org.stats.numAdditionalDownload" />}
          value={`${stats.numAdditionalDownload}`}
          icon={<CloudArrowDown20Regular />}
        />

        <FieldInfo
          label={<T id="org.stats.numAdditionalAskAI" />}
          value={`${stats.numAdditionalAskAI}`}
          icon={<Predictions20Regular />}
        />
      </div>
    </Panel>
  )
}
