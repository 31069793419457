import type { Piece } from '@aisekisan/anya-api'
import { DefaultColorStyle, shapeIdValidator } from '@tldraw/tldraw'
import { ATTR_EQUIP_FIRE_PIECES } from '../equip'
import { createPieceBoxShape } from './box/create'
import { createPieceCheckShape } from './check/create'
import type { PiecePartial } from './shape'

export function fromServerPiece(props: {
  server: Piece
  group: string
  equip: number
  color: string
}): PiecePartial {
  const { server, group, equip, color } = props

  const { x, y } = server.point
  // Default size when there's no metadata
  // @TODO: This must be the same with the manual tool?
  const w = server.metadata?.bbox?.w ?? 10
  const h = server.metadata?.bbox?.h ?? 10

  if (ATTR_EQUIP_FIRE_PIECES.includes(equip)) {
    const box = createPieceBoxShape({
      box: { x, y, w, h },
      id: shapeIdValidator.validate(server.uuid),
      color: DefaultColorStyle.validate(color),
      group,
    })

    return box
  }

  const piece = createPieceCheckShape({
    box: { x, y, w, h },
    id: shapeIdValidator.validate(server.uuid),
    color: DefaultColorStyle.validate(color),
    group,
  })

  return piece
}
