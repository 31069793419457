import { OverlaySpinner } from '@aisekisan/bond'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { parseServerError, useConstructionUpdate } from '@aisekisan/anya-api'
import type { ConstructionDetail } from '@aisekisan/anya-api'
import type { ReactElement } from 'react'
import { ConstructionFormDialog } from './form/form'
import type { ConstructionFormValues } from './form/type'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'

interface Props {
  construction: ConstructionDetail
  close: () => void
}

export function ConstructionEdit(props: Props): ReactElement {
  const { construction, close } = props

  const t = useT()

  const schema = z.object({
    name: z.string().min(1, { message: t('form.error.required_field') }),
    code: z.string().min(1, { message: t('form.error.required_field') }),
  })

  const form = useForm<ConstructionFormValues>({
    defaultValues: {
      name: construction.name,
      code: construction.code,
    },
    resolver: zodResolver(schema),
  })

  const update = useConstructionUpdate({ constructionID: construction.id })

  const onSubmit = (values: ConstructionFormValues) => {
    update.mutate(values, { onSuccess: close })
  }

  return (
    <ConstructionFormDialog form={form} close={close} submit={onSubmit} orgID={construction.orgID}>
      <OverlaySpinner visible={update.isPending} appearance="primary" />
      {update.isError
        ? (
          <ErrorAlert
            title={t('construction.update.error')}
            help={t('all.error.review')}
            detail={parseServerError(update.error)}
            onClose={() => update.reset()}
          />
          )
        : null}
    </ConstructionFormDialog>
  )
}
