import type { ReactNode } from 'react'
import { makeStyles, tokens } from '@aisekisan/bond'

interface Props {
  children: ReactNode
}

const useStyles = makeStyles({
  container: {
    width: '960px',
    maxWidth: '960px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
    columnGap: tokens.spacingHorizontalL,
    flexGrow: 1,
  },
})

export function ContainerWrapper(props: Props): JSX.Element {
  const { children } = props
  const styles = useStyles()
  return <div className={styles.container}>{children}</div>
}
