import { StartPage } from './Start'
import { AppRouter } from './router'
import './styles.css'
import './server'

function App(): JSX.Element {
  return (
    <StartPage>
      <AppRouter />
    </StartPage>
  )
}

export default App
