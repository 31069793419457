import type { Dictionary } from 'lodash'
import lodashGroupBy from 'lodash/groupBy'

interface Item {
  id: string
  parent?: string
}

export interface WithTreePath<T extends Item> { path: number[], value: T }

export function addTreePath<T extends Item>(items: T[]): WithTreePath<T>[] {
  const result: WithTreePath<T>[] = []
  const children: Dictionary<T[] | undefined> = lodashGroupBy(
    items,
    item => item.parent ?? '_none',
  )

  const push = (prev: number[]) => {
    return (item: T, index: number): void => {
      const next = [...prev, index]
      result.push({ path: next, value: item })
      children[item.id]?.forEach(push(next))
    }
  }

  children._none?.forEach(push([]))
  return result
}
