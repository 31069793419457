import { useLocation as useWouterLocation } from 'wouter'

export function useLocation() {
  const [location, navigate] = useWouterLocation()

  const queryParams = Object.fromEntries(
    new URLSearchParams(window.location.search),
  )

  return { location, navigate, queryParams }
}
