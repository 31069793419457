import type { PageDetail } from '@aisekisan/anya-api'
import { usePageDelete, useReportAnnotations } from '@aisekisan/anya-api'
import {
  Button,
  OverlaySpinner,
  Panel,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import {
  Delete20Regular,
  DocumentAdd20Regular,
  DocumentMultiple20Regular,
  Print20Regular,
} from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useEffect, useState } from 'react'
import { AddPages } from './add-pages'
import { PageCardList } from './card-list'
import { PrintPages } from './export/print-pages'
import type { PageFilter } from './filter'
import { FilterPages } from './filter'
import { T } from '@/libs/intl/t'
import { EmptyContent } from '@/components/empty-content'

const useStyles = makeStyles({
  container: {
    marginTop: tokens.spacingHorizontalL,
    minHeight: '300px',
    display: 'flex',
    rowGap: tokens.spacingHorizontalL,
    columnGap: tokens.spacingHorizontalL,
  },
  actions: {
    display: 'flex',
    columnGap: tokens.spacingHorizontalL,
  },
})

type Mode = 'print' | 'delete' | 'idle'

interface Props {
  categoryID: string
  constructionID: string
  pages: PageDetail[]
}

export function PageList(props: Props): ReactElement | null {
  const { categoryID, constructionID, pages } = props

  const styles = useStyles()

  const [filter, setFilter] = useState<PageFilter>({
    equipmentClass: '',
    annotation: 'all',
  })
  const [addPages, setAddPages] = useState<boolean>(false)
  const [printPageIDs, setPrintPageIDs] = useState<string[]>([])
  const [printDialog, setPrintDialog] = useState<boolean>(false)
  const [selected, setSelected] = useState<string[]>([])
  const [mode, setMode] = useState<Mode>('idle')

  const reportAnnotations = useReportAnnotations({
    constructionID,
    categoryID,
    equipmentClass: undefined,
  })

  const pageDelete = usePageDelete({ categoryID, constructionID })

  const onDeletePage = () => {
    if (selected.length > 0)
      pageDelete.mutate(selected, { onSuccess: () => setMode('idle') })
  }

  useEffect(() => {
    const pageWithAnnotationIds = (reportAnnotations.data ?? []).map(
      annotation => annotation.pageID,
    )

    const pageWithAnnotation = pages.filter(page =>
      pageWithAnnotationIds.includes(page.id),
    ).map(page => page.id)

    if (pageWithAnnotation.length > 0)
      setPrintPageIDs(pageWithAnnotation)
  }, [pages, reportAnnotations.data])

  const getActions = () => {
    if (mode === 'idle') {
      return (
        <div className={styles.actions}>
          <Button
            appearance="primary"
            icon={<Print20Regular />}
            onClick={() => setPrintDialog(true)}
          >
            <T id="page.print.pages.title" />
          </Button>
          <Button
            icon={<DocumentAdd20Regular />}
            onClick={() => setAddPages(true)}
          >
            <T id="page.upload.title" />
          </Button>
          <Button
            icon={<Delete20Regular />}
            onClick={() => setMode('delete')}
          >
            <T id="page.delete.multiple.title" />
          </Button>
        </div>
      )
    }
    if (mode === 'delete') {
      return (
        <div className={styles.actions}>
          <Button
            appearance="primary"
            icon={<Delete20Regular />}
            onClick={onDeletePage}
          >
            <T id="page.delete.multiple.title" />
          </Button>
          <Button
            onClick={() => setMode('idle')}
          >
            <T id="dialog.action.cancel" />
          </Button>
        </div>
      )
    }
    return null
  }

  return (
    <Panel
      title={<T id="page.list.title" />}
      icon={<DocumentMultiple20Regular />}
      actions={getActions()}
    >
      {printDialog && (
        <PrintPages
          selectedPages={printPageIDs}
          close={() => {
            setPrintDialog(false)
            setMode('idle')
          }}
        />
      )}
      {addPages
        ? <AddPages categoryID={categoryID} close={() => setAddPages(false)} />
        : null}
      <FilterPages
        filter={filter}
        setFilter={setFilter}
      />
      <div className={styles.container}>
        <OverlaySpinner
          visible={pageDelete.isPending}
          appearance="primary"
        />
        {pages.length > 0
          ? (
            <PageCardList
              annotation={filter.annotation}
              equipmentClass={filter.equipmentClass}
              pages={pages}
              constructionID={constructionID}
              categoryID={categoryID}
              key={filter.equipmentClass}
              selectable={mode === 'delete' || mode === 'print'}
              setSelected={setSelected}
              selected={selected}
            />
            )
          : (
            <EmptyContent />
            )}
      </div>
    </Panel>
  )
}
