import { Button, Panel, makeStyles, shorthands, tokens } from '@aisekisan/bond'
import { AddRegular, Box24Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useState } from 'react'
import type { ConstructionModuleDetail } from '@aisekisan/anya-api'
import { CreateCModule } from './create'
import { ConstructionModuleUpdate } from './update'
import { ConstructionModuleTable } from './table'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  table: {
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.padding(tokens.spacingHorizontalL),
    // Override shorthand padding
    paddingTop: 0,
  },
})

interface Props {
  constructionID: string
}

export function ConstructionModuleList(props: Props): ReactElement {
  const { constructionID } = props

  const styles = useStyles()

  const [update, setUpdate] = useState<ConstructionModuleDetail | null>(null)
  const [create, setCreate] = useState<boolean>(false)

  return (
    <Panel
      title={<T id="annotation.field.module" />}
      icon={<Box24Regular />}
      actions={(
        <Button icon={<AddRegular />} onClick={() => setCreate(true)}>
          <T id="annotation.module.create.title" />
        </Button>
      )}
    >
      {create && (
        <CreateCModule
          constructionID={constructionID}
          close={() => setCreate(false)}
        />
      )}
      {update !== null && (
        <ConstructionModuleUpdate
          constructionID={constructionID}
          cModule={update}
          close={() => setUpdate(null)}
        />
      )}
      <Panel className={styles.table}>
        <ConstructionModuleTable
          constructionID={constructionID}
          setUpdate={setUpdate}
        />
      </Panel>
    </Panel>
  )
}
