import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Input, makeStyles, tokens } from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import type { CategoryDetail } from '@aisekisan/anya-api'
import { useCategoryRename } from '@aisekisan/anya-api'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
})

interface Props {
  category: CategoryDetail
  close: () => void
}

export function CategoryEdit(props: Props): JSX.Element {
  const { category, close } = props

  const t = useT()
  const styles = useStyles()

  const schema = z.object({
    name: z.string().min(1, { message: t('form.error.required_field') }),
  })

  const form = useForm<{ name: string }>({
    defaultValues: {
      name: category.name,
    },
    resolver: zodResolver(schema),
  })

  const { formState: { errors } } = form

  const rename = useCategoryRename({ categoryID: category.id })

  const onSubmit = form.handleSubmit((values) => {
    const { name } = values
    rename.mutate({ name }, { onSuccess: close })
  })

  return (
    <Dialog open={!!category} onOpenChange={close}>
      <DialogSurface>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <DialogTitle
              action={(
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    onClick={close}
                  />
                </DialogTrigger>
              )}
            >
              <T id="category.edit.title" />
            </DialogTitle>
            <DialogContent className={styles.content}>
              <Field
                label={t('category.field.name')}
                validationState={errors.name ? 'error' : 'none'}
                validationMessage={errors.name?.message}
              >
                <Input
                  {...form.register('name')}
                  appearance="filled-darker"
                  size="large"
                />
              </Field>
            </DialogContent>
            <DialogActions>
              <Button appearance="primary" type="submit">
                <T id="category.edit.submit" />
              </Button>
              <Button onClick={close}>
                <T id="category.edit.cancel" />
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )
}
