import type { SVGProps } from 'react'

export function IconUSD(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M9.5 2.5C8.58075 2.5 7.6705 2.68106 6.82122 3.03284C5.97194 3.38463 5.20026 3.90024 4.55025 4.55025C3.90024 5.20026 3.38463 5.97194 3.03284 6.82122C2.68106 7.6705 2.5 8.58075 2.5 9.5C2.5 10.4193 2.68106 11.3295 3.03284 12.1788C3.38463 13.0281 3.90024 13.7997 4.55025 14.4497C5.20026 15.0998 5.97194 15.6154 6.82122 15.9672C7.6705 16.3189 8.58075 16.5 9.5 16.5C10.4193 16.5 11.3295 16.3189 12.1788 15.9672C13.0281 15.6154 13.7997 15.0998 14.4497 14.4497C15.0998 13.7997 15.6154 13.0281 15.9672 12.1788C16.3189 11.3295 16.5 10.4193 16.5 9.5C16.5 8.58075 16.3189 7.6705 15.9672 6.82122C15.6154 5.97194 15.0998 5.20026 14.4497 4.55025C13.7997 3.90024 13.0281 3.38463 12.1788 3.03284C11.3295 2.68106 10.4193 2.5 9.5 2.5Z"
        fill="#BAE0BD"
      />
      <path
        d="M9.5 3C13.084 3 16 5.916 16 9.5C16 13.084 13.084 16 9.5 16C5.916 16 3 13.084 3 9.5C3 5.916 5.916 3 9.5 3ZM9.5 2C5.358 2 2 5.358 2 9.5C2 13.642 5.358 17 9.5 17C13.642 17 17 13.642 17 9.5C17 5.358 13.642 2 9.5 2Z"
        fill="#5E9C76"
      />
      <path
        d="M8.32504 7.58C8.32504 7.141 8.71904 6.762 9.52904 6.762C10.447 6.762 11.107 7.022 11.621 7.333V5.85C11.24 5.637 10.663 5.513 9.89004 5.476V4.476H9.08804V5.498C8.41804 5.564 7.88004 5.8 7.47304 6.204C7.06604 6.608 6.86304 7.098 6.86304 7.673C6.86304 9.945 10.676 10.015 10.676 11.26C10.676 11.703 10.121 12.046 9.50204 12.046C8.23404 12.046 7.22404 11.51 7.01204 11.337V12.859C7.65304 13.192 8.34504 13.361 9.08804 13.364V14.523H9.89004V13.331C10.611 13.25 11.166 13.024 11.555 12.653C11.942 12.281 12.137 11.791 12.137 11.183C12.137 8.758 8.32504 8.943 8.32504 7.58Z"
        fill="#45805B"
      />
      <path d="M9 4H10V6H9V4ZM9 12.482H10V15H9V12.482Z" fill="#45805B" />
    </svg>
  )
}
