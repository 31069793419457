/*
Heads up! This module is small in terms of lines of code,
but it's quite complicated due to the weird design and typing of SheetJS.
especially on the CSV exporting.

First of all, SheetJS works in UTF-8 (CP 65001) by default. This is a problem because:
1. The majority of our user content is in Japanese, and
2. Excel defaults to Shift JIS (CP 952) when the user region is set to Japan,
   showing garbled characters, not even asking the users to choose the correct
   encoding.
To achieve a good user experience, we decided to encode the file as UTF-16 (CP 1200).
This encoding should work in all situations, regardless of the encoding used by
Excel or any other applications.

See:
- https://h2corporation.slack.com/archives/C03TF9K9E3G/p1683469716344299?thread_ts=1683450964.741199&cid=C03TF9K9E3G
- https://superuser.com/questions/1400050/exporting-from-excel-to-csv-replaces-japanese-characters-with-even-though-wi
- https://en.wikipedia.org/wiki/Shift_JIS
- https://en.wikipedia.org/wiki/Code_page_932_(Microsoft_Windows)

Second of all, SheetJS API is wrongly typed. The typed `codepage` option in
`writeFile` does not work at all. Instead, it depends on `bookType`:
- For `"csv"`, the result is always in UTF-8
- For `"txt"`, and if a codepage table is loaded, the result is always in UTF-16
Therefore, we need to write a file with a `.csv` extension, but using the `"txt"` bookType.

See:
- https://git.sheetjs.com/sheetjs/sheetjs/issues/2624
- https://git.sheetjs.com/sheetjs/sheetjs/src/commit/5b33acfaf485d0cc24f1d2235a3ab02e5bd269b9/bits/90_utils.js#L154

Thankfully, none of this encoding issues happen when exporting as XLSX
*/

import * as XLSX from 'xlsx'
import * as cptable from 'xlsx/dist/cpexcel'

XLSX.set_cptable(cptable)

export type ExcelFileFormat = 'csv' | 'xlsx'

export function getRef<T>(ref: React.RefObject<T>): T {
  if (ref.current === null)
    throw new Error('ref is not attached')
  return ref.current
}

export function downloadReport(params: {
  table: HTMLTableElement
  name: string
  format: ExcelFileFormat
}): void {
  const { table, name, format } = params
  const book = XLSX.utils.table_to_book(table, {})
  XLSX.writeFile(book, `${name}.${format}`, { bookType: format })
}

export function downloadMultiTablesReport(params: {
  tables: { name: string, table: HTMLTableElement }[]
  name: string
  format: ExcelFileFormat
}): void {
  const { tables, name, format } = params
  const book = XLSX.utils.book_new()
  tables.forEach((table) => {
    const sheet = XLSX.utils.table_to_sheet(table.table, {})
    XLSX.utils.book_append_sheet(book, sheet, table.name)
  })
  XLSX.writeFile(book, `${name}.${format}`, { bookType: format })
}
