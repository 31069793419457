import type { TLGeoShape } from '@tldraw/tldraw'
import type { PipeLineSegmentExtension } from '@aisekisan/anya-api'
import type { AnnotShapeColor } from '../../../shape/color'
import type { SegmentMetaBase } from '../../base'
import { createSegmentExtensionPartial } from './create'

import type { ShapePartial } from '@/components/editor/editor/shape/util/base'

export type SegmentExtensionMeta = SegmentMetaBase & {
  segment: 'extension'
  mm: number
}

export type SegmentExtensionPartial = ShapePartial<
  TLGeoShape,
  SegmentExtensionMeta
>

export function fromServerSegmentExtension(props: {
  color: AnnotShapeColor
  group: string
  server: PipeLineSegmentExtension
}): SegmentExtensionPartial {
  const { color, group, server } = props
  const { length, point } = server

  return createSegmentExtensionPartial({
    color,
    meta: { group, mm: length },
    vec: { x: point.x, y: point.y },
  })
}
