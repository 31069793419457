import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Field,
  Input,
  OverlaySpinner,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import omit from 'lodash/omit'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { parseServerError, useOrgUpdate } from '@aisekisan/anya-api'
import type { OrgDetail, OrgUpdateBody } from '@aisekisan/anya-api'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
})

interface Props {
  org: OrgDetail
  close: () => void
}

export function OrgEdit(props: Props) {
  const { org, close } = props

  const t = useT()
  const styles = useStyles()

  const schema = z.object({
    address: z.string().min(1, { message: t('form.error.required_field') }),
    name: z.string().min(1, { message: t('form.error.required_field') }),
    phone: z.string().nullable(),
    taxCode: z.string().nullable(),
    email: z.string().nullable(),
  })

  const form = useForm<OrgUpdateBody>({
    defaultValues: omit(org, 'id'),
    resolver: zodResolver(schema),
  })

  const { formState: { errors } } = form

  const update = useOrgUpdate({ orgID: org.id })

  const onSubmit = (values: OrgUpdateBody) => {
    update.mutate(values, { onSuccess: close })
  }

  return (
    <Dialog open={!!org} onOpenChange={close} key={org.id}>
      <DialogSurface>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <DialogBody>
            <OverlaySpinner visible={update.isPending} appearance="primary" />

            <DialogTitle
              action={(
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    onClick={close}
                  />
                </DialogTrigger>
              )}
            >
              <T id="org.update.title" />
            </DialogTitle>

            <DialogContent className={styles.content}>
              {update.isError
                ? (
                  <ErrorAlert
                    title={t('org.update.error.title')}
                    help={t('org.update.error.help')}
                    detail={parseServerError(update.error)}
                    onClose={() => update.reset()}
                  />
                  )
                : null}

              <Field
                label={<T id="org.field.name" />}
                validationState={errors.name ? 'error' : 'none'}
                validationMessage={errors.name?.message}
              >
                <Input
                  size="large"
                  appearance="filled-darker"
                  defaultValue={form.getValues('name')}
                  {...form.register('name')}
                />
              </Field>
              <Field
                label={<T id="org.field.email" />}
                validationState={errors.email ? 'error' : 'none'}
                validationMessage={errors.email?.message}
              >
                <Input
                  size="large"
                  appearance="filled-darker"
                  defaultValue={form.getValues('email')}
                  {...form.register('email')}
                />
              </Field>
              <Field
                label={<T id="org.field.address" />}
                validationState={errors.address ? 'error' : 'none'}
                validationMessage={errors.address?.message}
              >
                <Input
                  size="large"
                  appearance="filled-darker"
                  defaultValue={form.getValues('address')}
                  {...form.register('address')}
                />
              </Field>
              <Field
                label={<T id="org.field.phone" />}
                validationState={errors.phone ? 'error' : 'none'}
                validationMessage={errors.phone?.message}
              >
                <Input
                  size="large"
                  appearance="filled-darker"
                  defaultValue={form.getValues('phone')}
                  {...form.register('phone')}
                />
              </Field>
              <Field
                label={<T id="org.field.tax-code" />}
                validationState={errors.taxCode ? 'error' : 'none'}
                validationMessage={errors.taxCode?.message}
              >
                <Input
                  size="large"
                  appearance="filled-darker"
                  defaultValue={form.getValues('taxCode')}
                  {...form.register('taxCode')}
                />
              </Field>

            </DialogContent>
            <DialogActions>
              <Button
                appearance="primary"
                type="submit"
              >
                <T id="org.update.submit" />
              </Button>
              <Button onClick={close}>
                <T id="dialog.action.cancel" />
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )
}
