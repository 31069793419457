import { Body1, Caption1, makeStyles, tokens } from '@aisekisan/bond'
import type { ReactNode } from 'react'

const useStyles = makeStyles({
  labelIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: tokens.colorNeutralForeground3,
    justifyContent: 'flex-start',
    columnGap: tokens.spacingHorizontalXXS,
  },
  item: {
    display: 'grid',
    rowGap: tokens.spacingVerticalXXS,
  },
})

export function FieldInfo(props: {
  label: ReactNode
  value: string
  icon?: ReactNode
}) {
  const { label, value, icon } = props
  const styles = useStyles()

  return (
    <div className={styles.item}>
      <Caption1 className={styles.labelIcon}>
        {icon}
        {' '}
        {label}
      </Caption1>
      <Body1 style={{ color: tokens.colorNeutralForeground1 }}>{value}</Body1>
    </div>
  )
}
