import { type ReactElement, useRef } from 'react'
import type { CategoryDetail, ConstructionDetail } from '@aisekisan/anya-api'
import { makeStyles } from '@aisekisan/bond'
import type { ReportHead, ReportRow } from '../type'
import { downloadMultiTablesReport, getRef } from '../export/download'
import { ReportExport } from '../export/button'
import { FirePipeReport } from './pipe/report'
import { FirePieceReport } from './piece/report'
import { useT } from '@/libs/intl/useT'

const useStyles = makeStyles({
  hidden: {
    display: 'none',
  },
})

interface Props {
  construction: ConstructionDetail
  category: CategoryDetail
  reportHeads: ReportHead[]
  reportRows: ReportRow[]
}

export function FireReportExport(props: Props): ReactElement | null {
  const { construction, category, reportHeads, reportRows } = props

  const t = useT()
  const s = useStyles()

  const pipeTableRef = useRef<HTMLTableElement>(null)
  const pieceTableRef = useRef<HTMLTableElement>(null)

  return (
    <>
      <ReportExport
        download={(format) => {
          downloadMultiTablesReport({
            tables: [
              {
                name: t('report-fire.tab.pipes'),
                table: getRef(pipeTableRef),
              },
              {
                name: t('report-fire.tab.pieces'),
                table: getRef(pieceTableRef),
              },
            ],
            name: `${construction.name}-${category.name}`,
            format,
          })
        }}
      />
      <div className={s.hidden}>
        <table ref={pipeTableRef}>
          <FirePipeReport
            construction={construction}
            category={category}
            reportHeads={reportHeads}
            reportRows={reportRows.filter(row => row.dataType === 'Pipes')}
          />
        </table>
        <table ref={pieceTableRef}>
          <FirePieceReport
            construction={construction}
            category={category}
            reportHeads={reportHeads}
            reportRows={reportRows.filter(row => row.dataType === 'Pieces')}
          />
        </table>
      </div>
    </>
  )
}
