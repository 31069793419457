import { Button, Panel, makeStyles, tokens } from '@aisekisan/bond'
import { Form24Regular, FormNew20Regular } from '@fluentui/react-icons'
import _groupBy from 'lodash/groupBy'
import type { ReactElement } from 'react'
import { useEquipmentAttributeTreeInOrgList } from '@aisekisan/anya-api'
import { OrgEquipmentPropertyOverview } from './overview'
import { T } from '@/libs/intl/t'
import { EmptyContent } from '@/components/empty-content'
import { useLocation } from '@/hooks/use-location'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: tokens.spacingHorizontalL,
    rowGap: tokens.spacingVerticalL,
  },
})

interface Props {
  orgPresetID: string
}

export function OrgEquipmentPropertyList(props: Props): ReactElement {
  const { orgPresetID } = props

  const styles = useStyles()
  const { navigate } = useLocation()

  const { data: list } = useEquipmentAttributeTreeInOrgList({ orgPresetID })
  const groups = Object.entries(
    _groupBy(list, 'equipmentClass'),
  )

  return (
    <Panel
      title={<T id="preset.equipment.properties" />}
      icon={<Form24Regular />}
      actions={(
        <Button
          icon={<FormNew20Regular />}
          onClick={() => void navigate(`/org-preset-property/${orgPresetID}/create`)}
        >
          <T id="preset.equipment.property.create" />
        </Button>
      )}
    >
      <div className={styles.wrapper}>
        {list && list.length > 0
          ? (
            <>
              {groups.map(([equipmentClass, properties]) => (
                <OrgEquipmentPropertyOverview
                  key={equipmentClass}
                  equipmentClass={equipmentClass}
                  properties={properties}
                  orgPresetID={orgPresetID}
                />
              ))}
            </>
            )
          : <EmptyContent />}
      </div>
    </Panel>
  )
}
