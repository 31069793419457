import { useEffect, useState } from 'react'
import type { AccountInvitationDetail, Locale } from '@aisekisan/anya-api'
import { useIntl } from '@aisekisan/bond'
import { SignUpAcceptCheck } from '@/components/account/signup/accept/check'
import { SignUpAcceptForm } from '@/components/account/signup/accept/form'
import { SignUpAcceptSuccess } from '@/components/account/signup/accept/success'
import { useLocation } from '@/hooks/use-location'

type Invitation = AccountInvitationDetail
export function SignUpAcceptPage(): JSX.Element | null {
  const { queryParams } = useLocation()
  const token = queryParams.token

  const locale = queryParams.locale as Locale

  const [invitation, setInvitation] = useState<Invitation | null>(null)
  const [success, setSuccess] = useState(false)

  const { lang, setLang } = useIntl()

  useEffect(() => {
    if (lang !== locale) {
      switch (locale) {
        case 'en':
          setLang(locale)
          break
        case 'ja':
          setLang(locale)
          break
        default:
          break
      }
    }
  }, [])

  if (invitation === null)
    return <SignUpAcceptCheck {...{ setInvitation, token }} />

  if (success || invitation.shouldCreateAccount === false)
    return <SignUpAcceptSuccess {...{ invitation }} />

  return <SignUpAcceptForm {...{ invitation, setSuccess, token, locale }} />
}
