import type { SVGProps } from 'react'

export function IntlFlagEn(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Filled icon SM">
        <path
          id="Vector"
          d="M12.5001 2.08332V2.04916C11.7109 1.80124 10.8713 1.66666 10.0001 1.66666C5.39758 1.66666 1.66675 5.39749 1.66675 9.99999C1.66675 10.2812 1.68175 10.5592 1.70883 10.8333H12.5001V2.08332Z"
          fill="#3F51B5"
        />
        <path
          id="Vector_2"
          d="M12.5 4.58334H16.325C15.9263 4.11834 15.4758 3.70126 14.9846 3.33334H12.5V4.58334Z"
          fill="#ECEFF1"
        />
        <path
          id="Vector_3"
          d="M18.2912 10.8333H12.5H1.70874C1.75124 11.2617 1.83499 11.6767 1.93999 12.0833H18.0604C18.165 11.6767 18.2487 11.2617 18.2912 10.8333Z"
          fill="#ECEFF1"
        />
        <path
          id="Vector_4"
          d="M15.9462 15.8333H4.05371C4.52329 16.3121 5.05288 16.7288 5.62579 17.0833H14.3741C14.947 16.7288 15.4766 16.3121 15.9462 15.8333Z"
          fill="#ECEFF1"
        />
        <path
          id="Vector_5"
          d="M17.6353 13.3333H2.3645C2.55575 13.7708 2.78325 14.1888 3.04367 14.5833H16.9562C17.2166 14.1888 17.4437 13.7708 17.6353 13.3333Z"
          fill="#ECEFF1"
        />
        <path
          id="Vector_6"
          d="M12.5 7.08334H17.7983C17.6354 6.64834 17.4396 6.23043 17.2096 5.83334H12.5V7.08334Z"
          fill="#ECEFF1"
        />
        <path
          id="Vector_7"
          d="M12.5 9.58334H18.3125C18.2913 9.15834 18.2487 8.73918 18.1662 8.33334H12.5V9.58334Z"
          fill="#ECEFF1"
        />
        <path
          id="Vector_8"
          d="M12.5 3.33334H14.985C14.2671 2.79584 13.4637 2.36834 12.5929 2.08334H12.5V3.33334Z"
          fill="#F44336"
        />
        <path
          id="Vector_9"
          d="M12.5 5.83334H17.2096C16.9525 5.38918 16.6571 4.97084 16.3254 4.58334H12.5V5.83334Z"
          fill="#F44336"
        />
        <path
          id="Vector_10"
          d="M12.5 8.33334H18.1658C18.0783 7.90251 17.9492 7.48751 17.7979 7.08334H12.5V8.33334Z"
          fill="#F44336"
        />
        <path
          id="Vector_11"
          d="M12.5 10.8333H18.2912C18.3183 10.5592 18.3333 10.2813 18.3333 10C18.3333 9.85918 18.3192 9.72209 18.3125 9.58334H12.5V10.8333Z"
          fill="#F44336"
        />
        <path
          id="Vector_12"
          d="M18.0599 12.0833H1.93994C2.05077 12.5138 2.18952 12.9329 2.36494 13.3333H17.6354C17.8104 12.9329 17.9491 12.5138 18.0599 12.0833Z"
          fill="#F44336"
        />
        <path
          id="Vector_13"
          d="M16.9562 14.5833H3.0437C3.33995 15.0325 3.67828 15.4508 4.0537 15.8333H15.9466C16.3216 15.4508 16.66 15.0325 16.9562 14.5833Z"
          fill="#F44336"
        />
        <path
          id="Vector_14"
          d="M5.62573 17.0833C6.89823 17.8708 8.39323 18.3333 9.9999 18.3333C11.6066 18.3333 13.1016 17.8708 14.3741 17.0833H5.62573Z"
          fill="#F44336"
        />
        <path
          id="Vector_15"
          d="M4.27075 5.41666L4.4645 5.82832L4.89575 5.89332L4.58325 6.21416L4.65825 6.66666L4.27075 6.45332L3.88325 6.66666L3.95825 6.21416L3.64575 5.89332L4.077 5.82832L4.27075 5.41666Z"
          fill="white"
        />
        <path
          id="Vector_16"
          d="M5.9375 5.41666L6.13125 5.82832L6.5625 5.89332L6.25 6.21416L6.325 6.66666L5.9375 6.45332L5.55 6.66666L5.625 6.21416L5.3125 5.89332L5.74375 5.82832L5.9375 5.41666Z"
          fill="white"
        />
        <path
          id="Vector_17"
          d="M2.60405 6.45332L2.99155 6.66666L2.91655 6.21416L3.22905 5.89332L2.7978 5.82832L2.79613 5.82416C2.65863 6.06082 2.53238 6.30457 2.41821 6.55541L2.60405 6.45332Z"
          fill="white"
        />
        <path
          id="Vector_18"
          d="M7.60425 5.41666L7.798 5.82832L8.22925 5.89332L7.91675 6.21416L7.99175 6.66666L7.60425 6.45332L7.21675 6.66666L7.29175 6.21416L6.97925 5.89332L7.4105 5.82832L7.60425 5.41666Z"
          fill="white"
        />
        <path
          id="Vector_19"
          d="M9.27075 5.41666L9.4645 5.82832L9.89575 5.89332L9.58325 6.21416L9.65825 6.66666L9.27075 6.45332L8.88325 6.66666L8.95825 6.21416L8.64575 5.89332L9.077 5.82832L9.27075 5.41666Z"
          fill="white"
        />
        <path
          id="Vector_20"
          d="M10.8333 5.41666L11.027 5.82832L11.4583 5.89332L11.1458 6.21416L11.2208 6.66666L10.8333 6.45332L10.4458 6.66666L10.5208 6.21416L10.2083 5.89332L10.6395 5.82832L10.8333 5.41666Z"
          fill="white"
        />
        <path
          id="Vector_21"
          d="M4.27075 8.75L4.4645 9.16167L4.89575 9.22667L4.58325 9.5475L4.65825 10L4.27075 9.78667L3.88325 10L3.95825 9.5475L3.64575 9.22667L4.077 9.16167L4.27075 8.75Z"
          fill="white"
        />
        <path
          id="Vector_22"
          d="M5.9375 8.75L6.13125 9.16167L6.5625 9.22667L6.25 9.5475L6.325 10L5.9375 9.78667L5.55 10L5.625 9.5475L5.3125 9.22667L5.74375 9.16167L5.9375 8.75Z"
          fill="white"
        />
        <path
          id="Vector_23"
          d="M7.60425 8.75L7.798 9.16167L8.22925 9.22667L7.91675 9.5475L7.99175 10L7.60425 9.78667L7.21675 10L7.29175 9.5475L6.97925 9.22667L7.4105 9.16167L7.60425 8.75Z"
          fill="white"
        />
        <path
          id="Vector_24"
          d="M2.60425 8.75L2.798 9.16167L3.22925 9.22667L2.91675 9.5475L2.99175 10L2.60425 9.78667L2.21675 10L2.29175 9.5475L1.97925 9.22667L2.4105 9.16167L2.60425 8.75Z"
          fill="white"
        />
        <path
          id="Vector_25"
          d="M9.27075 8.75L9.4645 9.16167L9.89575 9.22667L9.58325 9.5475L9.65825 10L9.27075 9.78667L8.88325 10L8.95825 9.5475L8.64575 9.22667L9.077 9.16167L9.27075 8.75Z"
          fill="white"
        />
        <path
          id="Vector_26"
          d="M10.8333 8.75L11.027 9.16167L11.4583 9.22667L11.1458 9.5475L11.2208 10L10.8333 9.78667L10.4458 10L10.5208 9.5475L10.2083 9.22667L10.6395 9.16167L10.8333 8.75Z"
          fill="white"
        />
        <path
          id="Vector_27"
          d="M4.6875 7.08334L4.88125 7.49501L5.3125 7.56001L5 7.88084L5.075 8.33334L4.6875 8.12001L4.3 8.33334L4.375 7.88084L4.0625 7.56001L4.49375 7.49501L4.6875 7.08334Z"
          fill="white"
        />
        <path
          id="Vector_28"
          d="M6.35425 7.08334L6.548 7.49501L6.97925 7.56001L6.66675 7.88084L6.74175 8.33334L6.35425 8.12001L5.96675 8.33334L6.04175 7.88084L5.72925 7.56001L6.1605 7.49501L6.35425 7.08334Z"
          fill="white"
        />
        <path
          id="Vector_29"
          d="M3.02075 7.08334L3.2145 7.49501L3.64575 7.56001L3.33325 7.88084L3.40825 8.33334L3.02075 8.12001L2.63325 8.33334L2.70825 7.88084L2.39575 7.56001L2.827 7.49501L3.02075 7.08334Z"
          fill="white"
        />
        <path
          id="Vector_30"
          d="M8.02075 7.08334L8.2145 7.49501L8.64575 7.56001L8.33325 7.88084L8.40825 8.33334L8.02075 8.12001L7.63325 8.33334L7.70825 7.88084L7.39575 7.56001L7.827 7.49501L8.02075 7.08334Z"
          fill="white"
        />
        <path
          id="Vector_31"
          d="M9.6875 7.08334L9.88125 7.49501L10.3125 7.56001L10 7.88084L10.075 8.33334L9.6875 8.12001L9.3 8.33334L9.375 7.88084L9.0625 7.56001L9.49375 7.49501L9.6875 7.08334Z"
          fill="white"
        />
        <path
          id="Vector_32"
          d="M11.25 7.08334L11.4438 7.49501L11.875 7.56001L11.5625 7.88084L11.6375 8.33334L11.25 8.12001L10.8625 8.33334L10.9375 7.88084L10.625 7.56001L11.0562 7.49501L11.25 7.08334Z"
          fill="white"
        />
        <path
          id="Vector_33"
          d="M4.6875 3.75L4.88125 4.16167L5.3125 4.22667L5 4.5475L5.075 5L4.6875 4.78667L4.3 5L4.375 4.5475L4.0625 4.22667L4.49375 4.16167L4.6875 3.75Z"
          fill="white"
        />
        <path
          id="Vector_34"
          d="M6.35425 3.75L6.548 4.16167L6.97925 4.22667L6.66675 4.5475L6.74175 5L6.35425 4.78667L5.96675 5L6.04175 4.5475L5.72925 4.22667L6.1605 4.16167L6.35425 3.75Z"
          fill="white"
        />
        <path
          id="Vector_35"
          d="M8.02075 3.75L8.2145 4.16167L8.64575 4.22667L8.33325 4.5475L8.40825 5L8.02075 4.78667L7.63325 5L7.70825 4.5475L7.39575 4.22667L7.827 4.16167L8.02075 3.75Z"
          fill="white"
        />
        <path
          id="Vector_36"
          d="M9.6875 3.75L9.88125 4.16167L10.3125 4.22667L10 4.5475L10.075 5L9.6875 4.78667L9.3 5L9.375 4.5475L9.0625 4.22667L9.49375 4.16167L9.6875 3.75Z"
          fill="white"
        />
        <path
          id="Vector_37"
          d="M11.25 3.75L11.4438 4.16167L11.875 4.22667L11.5625 4.5475L11.6375 5L11.25 4.78667L10.8625 5L10.9375 4.5475L10.625 4.22667L11.0562 4.16167L11.25 3.75Z"
          fill="white"
        />
        <path
          id="Vector_38"
          d="M6.32505 3.33334L6.25005 2.88084L6.56255 2.56L6.33297 2.52542C6.08755 2.64584 5.84963 2.77792 5.61838 2.92125L5.55005 3.33334L5.93755 3.12L6.32505 3.33334Z"
          fill="white"
        />
        <path
          id="Vector_39"
          d="M7.60425 2.08334L7.798 2.49501L8.22925 2.56001L7.91675 2.88084L7.99175 3.33334L7.60425 3.12001L7.21675 3.33334L7.29175 2.88084L6.97925 2.56001L7.4105 2.49501L7.60425 2.08334Z"
          fill="white"
        />
        <path
          id="Vector_40"
          d="M9.27075 2.08334L9.4645 2.49501L9.89575 2.56001L9.58325 2.88084L9.65825 3.33334L9.27075 3.12001L8.88325 3.33334L8.95825 2.88084L8.64575 2.56001L9.077 2.49501L9.27075 2.08334Z"
          fill="white"
        />
        <path
          id="Vector_41"
          d="M10.8333 2.08334L11.027 2.49501L11.4583 2.56043L11.1458 2.88126L11.2208 3.33334L10.8333 3.12001L10.4458 3.33334L10.5208 2.88084L10.2083 2.56001L10.6395 2.49501L10.8333 2.08334Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
