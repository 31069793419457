import { Link, makeStyles } from '@aisekisan/bond'
import type { ReactElement } from 'react'
import type { ReportHead } from '../../type'
import { FIRE_REPORT_FIXED_COLS, getFireReportEmptyCols } from '../utils'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  cArea: {
    textAlign: 'center',
    minWidth: '150px',
  },
  material: {
    textAlign: 'center',
    minWidth: '100px',
  },
  page: {
    textAlign: 'center',
    minWidth: '80px',
  },
  total: {
    textAlign: 'center',
    minWidth: '80px',
  },
  module: {
    textAlign: 'center',
    minWidth: '120px',
  },
})

const EDITOR_DOMAIN = import.meta.env.VITE_EDITOR_DOMAIN

interface Props {
  reportHeads: ReportHead[]
  material: string
}

export function FirePipeReportHeader(props: Props): ReactElement {
  const { reportHeads, material } = props

  const s = useStyles()

  const emptyCols = getFireReportEmptyCols(
    FIRE_REPORT_FIXED_COLS - reportHeads.length,
  )

  return (
    <>
      <tr>
        <th className={s.material}>
          <T id="report-fire.header.material" />
        </th>
        <th rowSpan={2} className={s.cArea}>
          <T id="report-fire.header.construction_area" />
        </th>
        {getFireReportEmptyCols(reportHeads.length)}
        {emptyCols}
        <th></th>
        <th></th>
      </tr>
      <tr>
        <th className={s.material}>
          {material}
        </th>
        {/* Pages with annotations */}
        {reportHeads.map(({ pageID, pageName }) => {
          return (
            <th key={pageID} className={s.page}>
              <Link href={`//${EDITOR_DOMAIN}/?page=${pageID}`} target="_blank">
                <strong>{pageName}</strong>
              </Link>
            </th>
          )
        })}
        {emptyCols}
        <th className={s.total}>
          <T id="report-fire.header.sub-total" />
        </th>
        <th className={s.total}>
          <T id="report-fire.header.total" />
        </th>
      </tr>
    </>
  )
}
