import { ApiError, useAccountInfo } from '@aisekisan/anya-api'
import type { AccountInfo } from '@aisekisan/anya-api'

type Props =
  | { status: 'loading', account: null }
  | { status: 'guest', account: null }
  | { status: 'user', account: AccountInfo }

export function useAuth(): Props {
  const query = useAccountInfo()

  if (query.isLoading)
    return { status: 'loading', account: null }
  if (query.isSuccess)
    return { status: 'user', account: query.data }
  if (query.error instanceof ApiError && query.error.status === 401)
    return { status: 'guest', account: null }

  throw query.error
}
