import type { Locale, Market } from '../type'

export interface PresetForm {
  description: string
  locale: Locale
  market: Market
  name: string
}

export const DEFAULT_PRESET_FORM: PresetForm = {
  description: '',
  locale: 'ja',
  market: 'JAPAN',
  name: '',
}
