import type {
  TableColumnDefinition,
} from '@aisekisan/bond'
import {
  Body1,
  Button,
  Caption1,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  createTableColumn,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import { SubtractCircleRegular } from '@fluentui/react-icons'
import type { Locale, OrgInvitationDetail, OrgMemberDetail, OrgRole } from '@aisekisan/anya-api'
import { useOrgInvitationList } from '@aisekisan/anya-api'
import { UpdateRoleSelect } from './update-role'
import { useFormatDate } from '@/utils/date'
import { useT } from '@/libs/intl/useT'
import { T } from '@/libs/intl/t'

type OrgMember = OrgMemberDetail | OrgInvitationDetail

function isOrgMember(obj: any): obj is OrgMemberDetail {
  return (
    'email' in obj
    && 'firstName' in obj
    && 'lastName' in obj
    && 'id' in obj
    && 'middleName' in obj
    && 'role' in obj
  )
}

const useStyles = makeStyles({
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  caption1: {
    color: tokens.colorBrandForeground1,
  },
})

interface Props {
  role: OrgRole
  memberList: OrgMemberDetail[]
  locale: Locale
  orgID: string
  setRemove: (member: OrgMemberDetail) => void
  setRevokeInvitation: (member: OrgInvitationDetail) => void
}

export function OrgMemberTable(props: Props): JSX.Element {
  const { orgID, setRemove, setRevokeInvitation, memberList, role, locale } = props

  const t = useT()
  const styles = useStyles()

  const formatDate = useFormatDate('short-none')

  const invitation = useOrgInvitationList({ orgID, status: 'pending', role }).data ?? []

  const data: OrgMember[] = [...memberList, ...invitation]

  const columns: TableColumnDefinition<OrgMember>[] = [
    createTableColumn<OrgMember>({
      columnId: 'email',
      renderHeaderCell: () => {
        return <T id="account.field.email" />
      },
      renderCell: (member) => {
        return isOrgMember(member)
          ? (
              member.email
            )
          : (
            <Body1 className={styles.flexCol}>
              {member.email}
              <Caption1 className={styles.caption1}>
                {`${t('org.invitation.invite.invited.on')} ${formatDate(
                member.createdAt,
              )}`}
                <br />
                {`${t('org.invitation.invite.expired.on')} ${formatDate(
                member.expiresAt,
              )}`}
              </Caption1>
            </Body1>
            )
      },
      compare: (a, b) => {
        return a.email.localeCompare(b.email)
      },
    }),
    createTableColumn<OrgMember>({
      columnId: 'full-name',
      renderHeaderCell: () => {
        return <T id="account.field.name" />
      },
      renderCell: (member) => {
        return isOrgMember(member)
          ? locale === 'en'
            ? `${member?.firstName} ${member?.lastName}`
            : `${member?.lastName} ${member?.firstName}`
          : '-'
      },
    }),
    createTableColumn<OrgMember>({
      columnId: 'role',
      renderHeaderCell: () => {
        return <T id="org.role.label" />
      },
      renderCell: (member) => {
        return (
          <UpdateRoleSelect
            orgID={orgID}
            accountID={member.id}
            role={member.role}
          />
        )
      },
    }),
    createTableColumn<OrgMember>({
      columnId: 'actions',
      renderHeaderCell: () => {
        return ''
      },
      renderCell: (member) => {
        if (role === 'owner') {
          if (isOrgMember(member)) {
            return (
              <Button
                aria-label="Remove"
                appearance="transparent"
                icon={<SubtractCircleRegular />}
                onClick={() => setRemove(member)}
              >
                <T id="org.member.table.remove" />
              </Button>
            )
          }
          return (
            <Button
              aria-label="Remove"
              appearance="transparent"
              icon={<SubtractCircleRegular />}
              onClick={() => setRevokeInvitation(member)}
            >
              <T id="org.member.table.remove" />
            </Button>
          )
        }
      },
    }),
  ]

  return (
    <DataGrid
      items={data ?? []}
      columns={columns}
      sortable
      getRowId={member => member.id}
      focusMode="composite"
      resizableColumns
      columnSizingOptions={{
        'email': { minWidth: 300 },
        'full-name': { defaultWidth: 300 },
        'action': { defaultWidth: 150 },
      }}
    >
      <DataGridHeader>
        <DataGridRow>
          {({ renderHeaderCell }) => (
            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
          )}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<OrgMemberDetail>>
        {({ item, rowId }) => (
          <DataGridRow<OrgMemberDetail> key={rowId}>
            {({ renderCell }) => (
              <DataGridCell>{renderCell(item)}</DataGridCell>
            )}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  )
}
