export function matchRoute(currentLocation: string, routePattern: string) {
  const escapedPattern = routePattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  // Replace ':id' in the pattern with a regex capturing group
  const patternRegex = new RegExp(`^${escapedPattern.replace(/:id/g, '([a-zA-Z0-9-]+)')}$`)

  // Test if the current location matches the pattern
  const match = currentLocation.match(patternRegex)

  if (match) {
    // Extract parameter values if any
    const params = match.slice(1)
    return { isMatch: true, params }
  }
  else {
    return { isMatch: false, params: {} }
  }
}
