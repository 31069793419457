import { LATEST_VERSION, useNotifyVersion } from './help';
import { makeStyles, tokens, Button, Body1, Link } from '@aisekisan/bond';
import { useT } from '@/libs/intl/useT';
import { Dismiss16Regular } from '@fluentui/react-icons';

const useStyles = makeStyles({
  banner: {
    borderRadius: tokens.borderRadiusXLarge,
    width: 'auto',
    backgroundColor: tokens.colorPaletteDarkOrangeBackground3,
    padding: `${tokens.spacingVerticalXXS} ${tokens.spacingVerticalS}`,
    display: 'flex',
    justifyContent: 'space-between',
    gap: tokens.spacingHorizontalXXXL,
    alignItems: 'center',
    color: tokens.colorNeutralBackground1,
    minHeight: '28px',
    boxShadow: tokens.shadow16,
    '@media(max-width: 1024px)': {
      position: 'fixed',
      left: 'calc(50% - 132px)',
      transform: 'translateX(-50%,0)',
      zIndex: 1,
      opacity: 0.9,
  },
},
  message: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: tokens.spacingHorizontalXXS,
  },
  action: {
    display: 'flex',
    gap: tokens.spacingHorizontalXS,
  },
  button: {
    'width': 'auto',
    'color': tokens.colorNeutralBackground1,
    ':hover': {
      color: tokens.colorNeutralBackground1Hover,
    },
    'maxWidth': '24px',
    'minWidth': '24px',
    'height': '24px',
  },
  link: {
    'width': 'auto',
    'fontSize': '14px',
    'color': tokens.colorNeutralBackground1,
    'height': '24px',
    'display': 'flex',
    'alignItems': 'center',
    'textDecoration': 'underline',
    ':hover': {
      color: tokens.colorNeutralBackground1Hover,
    },
  }
})

export function AppNotifyVersion() {
  const s = useStyles()

  const { updateVersion, version } = useNotifyVersion()
  const t = useT()

  if (version.seen === 'true')
    return null

  const onClose = () => {
    updateVersion({ ...version, seen: 'true' })
  }

  return (
    <div className={s.banner}>
      <div className={s.message}>
        <Body1>
          {`${t('app.notify.update-version')} ${LATEST_VERSION}.`}
        </Body1>
        <Link
          className={s.link}
          href="https://docs.aisekisan.com"
          target="_blank"
          onClick={onClose}
        >
          {t('app.notify.learn-more')}
        </Link>
      </div>
      <div className={s.action}>
        <Button appearance="transparent" icon={<Dismiss16Regular />} onClick={onClose} className={s.button} />
      </div>
    </div>
  )
}