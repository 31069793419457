import { createContext, useContext } from 'react'
import type { SetState } from '../utils/react'
import type { IntlLang } from './lang'

export type Translation = Record<string, string>

export interface IntlProps {
  lang: IntlLang
  setLang: SetState<IntlLang>
  dict: Translation
}

export const IntlContext = createContext<IntlProps>({
  lang: 'ja',
  setLang: () => {
    throw new Error('Lang context is not provided')
  },
  dict: {},
})

export function useIntl(): IntlProps {
  return useContext(IntlContext)
}
