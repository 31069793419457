/**
 * Convenient utility to use when a value could only be null or undefined due
 * to coding error. This is a better than TypeScript's "!" assertion in most
 * case as we could be wrong about our assertion, especially after a long time.
 *
 * For more complex case (e.g., "value" is unknown), use Zod (e.g.,
 * `z.string().parse()`).
 */
export function getStrict<Value>(value: Value): NonNullable<Value> {
  if (value === undefined)
    throw new Error('value must not be undefined')
  if (value === null)
    throw new Error('value must not be null')
  return value
}

/**
 * "string & {}" workaround for auto-complete. See:
 * - https://github.com/microsoft/TypeScript/issues/29729
 */
// export type FamilyFriendlyString = (string & NonNullable<unknown>)

// @TODO getNumberStrict (not NaN)
