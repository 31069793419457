import type { AnnotationDetail } from '@aisekisan/anya-api'
import {
  useEquipmentAttributeTrees,
  useEquipmentClasses,
} from '@aisekisan/anya-api'
import { useIntl } from '@aisekisan/bond'
import type { ReportRow } from '../type'
import { groupReportData } from './group'
import { transformReportData } from './transform'

interface Params {
  constructionID: string
  reportAnnots: AnnotationDetail[]
}

export function useReportRows(params: Params): ReportRow[] {
  const { constructionID, reportAnnots } = params

  const { lang } = useIntl()
  const equipClasses = useEquipmentClasses().data ?? []
  const equipClassLocale = equipClasses.filter(({ locale }) => locale === lang)

  const trees = useEquipmentAttributeTrees({ constructionID }).data ?? []

  const rows = reportAnnots.map((annot) => {
    const equip = equipClassLocale.find(({ equipmentClass }) => {
      return equipmentClass === annot.equipmentClass
    })
    return transformReportData(annot, trees, equip ? equip.localed : '')
  })

  return groupReportData(rows)
}
