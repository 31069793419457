import { Field, Input } from '@aisekisan/bond'
import type { UseFormReturn } from 'react-hook-form'
import type { ReactElement } from 'react'
import type { ConstructionFormValues } from './type'
import { T } from '@/libs/intl/t'

interface Props {
  form: UseFormReturn<ConstructionFormValues>
}

export function ConstructionCode(props: Props): ReactElement {
  const { form } = props
  const { formState: { errors } } = form

  return (
    <Field
      label={<T id="construction.field.code" />}
      validationState={errors.code ? 'error' : 'none'}
      validationMessage={errors.code?.message}
    >
      <Input
        size="large"
        appearance="filled-darker"
        defaultValue={form.getValues('code')}
        {...form.register('code')}
      />
    </Field>
  )
}
