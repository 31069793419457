import { useForm } from 'react-hook-form'
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, OverlaySpinner, Select, makeStyles, tokens } from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { parseServerError, useConstructionMemberAdd, useOrgMemberList } from '@aisekisan/anya-api'
import type { ConstructionMemberAddBody, ConstructionRole } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
    width: '100%',
    flexWrap: 'wrap',
  },
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
})

interface Props {
  constructionID: string
  orgID: string
  close: () => void
  role: ConstructionRole
}

export function ConstructionMemberAdd(props: Props): JSX.Element {
  const { constructionID, close, orgID, role } = props

  const t = useT()
  const styles = useStyles()

  const orgMemberList = useOrgMemberList({ orgID }).data ?? []

  const schema = z.object({
    accountID: z.string().min(1, { message: t('form.error.required_field') }),
    role: z.string().min(1, { message: t('form.error.required_field') }),
  })

  const form = useForm<ConstructionMemberAddBody>({
    defaultValues: { accountID: '', role: 'viewer' },
    resolver: zodResolver(schema),

  })

  const { formState: { errors } } = form

  const add = useConstructionMemberAdd({ constructionID })

  const onSubmit = form.handleSubmit((body) => {
    add.mutate(body, { onSuccess: close })
  })

  return (
    <Dialog open={!!constructionID} onOpenChange={close}>
      <DialogSurface>
        <form
          onSubmit={onSubmit}
        >
          <DialogBody>
            <OverlaySpinner visible={add.isPending} appearance="primary" />
            <DialogTitle
              action={(
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    onClick={close}
                  />
                </DialogTrigger>
              )}
            >
              <T id="construction.member.add.title" />
            </DialogTitle>
            <DialogContent className={styles.content}>
              {role === 'owner'
                ? (
                  <div className={styles.content}>
                    {add.isError
                      ? (
                        <ErrorAlert
                          title={t('construction.member.add.error')}
                          help={t('all.error.review')}
                          detail={parseServerError(add.error)}
                          onClose={() => add.reset()}
                        />
                        )
                      : null}
                    <Field
                      label={<T id="construction.member.add.member" />}
                      validationState={errors.accountID ? 'error' : 'none'}
                      validationMessage={errors.accountID?.message}
                      hint={(t('construction.member.add.hint'))}
                      className={styles.wrap}
                    >
                      <Select
                        size="large"
                        appearance="filled-darker"
                        defaultValue={form.getValues('accountID')}
                        {...form.register('accountID')}
                        className={styles.wrap}
                      >
                        {orgMemberList.map(member => (
                          <option value={member.id} key={member.id}>
                            {`${member.firstName} ${member.lastName} - ${member.email}`}
                          </option>
                        ))}
                      </Select>
                    </Field>
                    <Field
                      label={<T id="org.role.label" />}
                      validationState={errors.role ? 'error' : 'none'}
                      validationMessage={errors.role?.message}
                      hint={(
                        <div>
                          <T id="construction.member.add.hint.1" />
                          <ul style={{ margin: 0 }}>
                            <li><T id="construction.member.add.hint.2" /></li>
                            <li><T id="construction.member.add.hint.3" /></li>
                          </ul>
                        </div>
                      )}
                    >
                      <Select
                        size="large"
                        appearance="filled-darker"
                        defaultValue={form.getValues('role')}
                        {...form.register('role')}
                      >
                        <option value="owner">
                          <T id="construction.role.owner.title" />
                        </option>
                        <option value="viewer">
                          <T id="construction.role.viewer.title" />
                        </option>
                        <option value="editor">
                          <T id="construction.role.editor.title" />
                        </option>
                      </Select>
                    </Field>
                  </div>
                  )
                : <T id="construction.invitation.create.role-viewer" />}
            </DialogContent>
            <DialogActions>
              {role === 'owner'
                ? (
                  <Button
                    appearance="primary"
                    type="submit"
                  >
                    <T id="construction.member.add.submit" />
                  </Button>
                  )
                : null}
              <Button onClick={close}>
                <T id="dialog.action.cancel" />
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )
};
