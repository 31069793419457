import { useState } from 'react'
import { ResetPasswordForm } from '@/components/account/reset-password/accept-form'
import { SignUpAcceptSuccess } from '@/components/account/reset-password/success'

interface Props {
  token: string
}

export function ResetPasswordAcceptPage(props: Props): JSX.Element | null {
  const { token } = props

  const [success, setSuccess] = useState(false)

  if (success)
    return <SignUpAcceptSuccess />

  return <ResetPasswordForm {...{ setSuccess, token }} />
}
