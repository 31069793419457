import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  OverlaySpinner,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import { parseServerError, useConstructionMemberRemove } from '@aisekisan/anya-api'
import type { ConstructionMemberDetail } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'

interface Props {
  constructionID: string
  account: ConstructionMemberDetail
  close: () => void
}

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalL,
  },
})

export function ConstructionMemberRemove(props: Props): JSX.Element {
  const { constructionID, close, account } = props
  const accountID = account.id
  const email = account.email

  const t = useT()
  const styles = useStyles()

  const remove = useConstructionMemberRemove({ constructionID })

  return (
    <Dialog open={!!constructionID} onOpenChange={close}>
      <DialogSurface>
        <DialogBody>
          <OverlaySpinner visible={remove.isPending} appearance="primary" />
          <DialogTitle>
            <T id="construction.member.remove" />
          </DialogTitle>
          <DialogContent>
            {remove.isError
              ? (
                <ErrorAlert
                  title={t('construction.member.remove.error.title')}
                  help={<T id="all.error.review" />}
                  detail={parseServerError(remove.error)}
                  onClose={() => remove.reset()}
                />
                )
              : null}
            <div className={styles.content}>
              <Body1>
                <T id="construction.member.remove.helper {{.email}}" params={{ email }} />
              </Body1>
              <Body1>
                <T id="construction.member.remove.helper.2" />
              </Body1>
              <Body1>
                <T id="construction.member.remove.helper.3" />
              </Body1>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              onClick={() => {
                remove.mutate(accountID, { onSuccess: close })
              }}
            >
              <T id="construction.member.remove.submit" />
            </Button>
            <Button onClick={close}>
              <T id="construction.member.remove.cancel" />
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
