import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Field,
  Input,
  OverlaySpinner,
  Select,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { parseServerError, useOrgInvitationCreate } from '@aisekisan/anya-api'
import type { OrgInvitationCreateBody, OrgRole } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
})

interface Props {
  orgID: string
  role: OrgRole
  close: () => void
}

export function InviteMemberCreate(props: Props) {
  const { orgID, close, role } = props

  const t = useT()
  const styles = useStyles()

  const schema = z.object({
    email: z.string().email(),
    role: z.string().min(1, { message: t('form.error.required_field') }),
    locale: z.string().min(1, { message: t('form.error.required_field') }),
  })

  const form = useForm<OrgInvitationCreateBody>({
    defaultValues: { role: 'member', email: '', locale: 'ja' },
    resolver: zodResolver(schema),
  })

  const { formState: { errors } } = form

  const create = useOrgInvitationCreate({ orgID })

  const onSubmit = form.handleSubmit((values: OrgInvitationCreateBody) => {
    const { email, role, locale } = values
    create.mutate(
      { email, role, locale },
      { onSuccess: close },
    )
  })

  return (
    <Dialog open={!!orgID} onOpenChange={close}>
      <DialogSurface>
        <form
          onSubmit={onSubmit}
        >
          <DialogBody>
            <OverlaySpinner visible={create.isPending} appearance="primary" />
            <DialogTitle
              action={(
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    onClick={close}
                  />
                </DialogTrigger>
              )}
            >
              <T id="org.invitation.create.title" />
            </DialogTitle>
            <DialogContent className={styles.content}>
              {role === 'owner'
                ? (
                  <div>
                    {create.isError
                      ? (
                        <ErrorAlert
                          title={t('org.invitation.create.error.title')}
                          help={t('org.invitation.create.error.help')}
                          detail={parseServerError(create.error)}
                          onClose={() => create.reset()}
                        />
                        )
                      : null}
                    <Field
                      label={<T id="org.field.email" />}
                      validationState={errors.email ? 'error' : 'none'}
                      validationMessage={errors.email?.message}
                      hint={t('org.field.email.hint')}
                    >
                      <Input
                        size="large"
                        appearance="filled-darker"
                        {...form.register('email')}
                      />
                    </Field>
                    <Field
                      label={<T id="org.role.label" />}
                      validationState={errors.role ? 'error' : 'none'}
                      validationMessage={errors.role?.message}
                      hint={(
                        <div>
                          <T id="org.field.email.rules" />
                          <ul style={{ margin: 0 }}>
                            <li>
                              <T id="org.field.email.rules.1" />
                            </li>
                            <li>
                              <T id="org.field.email.rules.2" />
                            </li>
                          </ul>
                        </div>
                      )}
                    >
                      <Select
                        size="large"
                        appearance="filled-darker"
                        defaultValue={form.getValues('role')}
                        {...form.register('role')}
                      >
                        <option value="member">
                          <T id="org.role.member.title" />
                        </option>
                        <option value="owner">
                          <T id="org.role.owner.title" />
                        </option>
                      </Select>
                    </Field>

                    <Field
                      label={<T id="account.field.locale" />}
                      validationState={errors.locale ? 'error' : 'none'}
                      validationMessage={errors.locale?.message}
                      hint={t('org.invite.member.field.locale.hint')}
                    >
                      <Select
                        size="large"
                        appearance="filled-darker"
                        defaultValue={form.getValues('locale')}
                        {...form.register('locale')}
                      >
                        <option value="ja">
                          <T id="locale.japan" />
                        </option>
                        <option value="en">
                          <T id="locale.english" />
                        </option>
                      </Select>
                    </Field>
                  </div>
                  )
                : <T id="org.invitation.create.role-member" />}
            </DialogContent>
            <DialogActions>
              {role === 'owner'
                ? (
                  <Button
                    appearance="primary"
                    type="submit"
                  >
                    <T id="org.invitation.create.submit" />
                  </Button>
                  )
                : null}
              <Button onClick={close}>
                <T id="dialog.action.cancel" />
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )
}
