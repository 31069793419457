import * as Sentry from '@sentry/react'
import { FluentProvider, webLightTheme } from '@aisekisan/bond'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider, ReactQueryDevtools } from '@aisekisan/anya-api'
import ReactDOM from 'react-dom/client'
import React from 'react'
import App from './App.js'
import { IntlProvider } from './provider/intl/intl'
import { ErrorBoundary } from './error/boundary.js'
import { initError } from './error/init.js'

function captureException(error: Error) {
  Sentry.captureException(error, { tags: { component: 'ServerError' } })
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
  // Handle errors at the global level
  // Ref: https://tkdodo.eu/blog/react-query-error-handling#putting-it-all-together
  mutationCache: new MutationCache({
    onError: (err) => {
      captureException(err)
    },
  }),
  queryCache: new QueryCache({
    onError: (err) => {
      captureException(err)
    },
  }),
})

initError()

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ErrorBoundary>
      <FluentProvider theme={webLightTheme}>
        <IntlProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen />
            <App />
          </QueryClientProvider>
        </IntlProvider>
      </FluentProvider>
    </ErrorBoundary>
  </React.StrictMode>,
)
