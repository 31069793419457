import type { ReactElement } from 'react'
import type { CategoryDetail, ConstructionDetail } from '@aisekisan/anya-api'
import { Card, makeStyles, shorthands } from '@aisekisan/bond'
import type { ReportHead, ReportRow } from '../type'
import { DefaultReportOverview } from './overview'
import { DefaultReportHeader } from './header'
import { DefaultReportBody } from './body'

const useStyles = makeStyles({
  container: {
    width: '100%',
    maxHeight: '50vh',
    ...shorthands.overflow('auto'),
  },
})

interface Props {
  construction: ConstructionDetail
  category: CategoryDetail
  reportHeads: ReportHead[]
  reportRows: ReportRow[]
}

export function DefaultReport(props: Props): ReactElement {
  const { construction, category, reportHeads, reportRows } = props

  const s = useStyles()

  return (
    <Card className={s.container}>
      <table>
        <tbody>
          <DefaultReportOverview
            category={category}
            construction={construction}
            reportHeads={reportHeads}
          />
          <DefaultReportHeader
            reportHeads={reportHeads}
          />
          <DefaultReportBody
            reportHeads={reportHeads}
            reportRows={reportRows}
          />
        </tbody>
      </table>
    </Card>
  )
}
