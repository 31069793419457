import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, OverlaySpinner } from '@aisekisan/bond'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { parseServerError, usePageClone } from '@aisekisan/anya-api'
import type { PageDetail } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'

interface DuplicatePageProps {
  page: PageDetail
  close: () => void
}
export function DuplicatePage(props: DuplicatePageProps): JSX.Element {
  const { page, close } = props

  const t = useT()

  const duplicate = usePageClone()

  return (
    <Dialog open={!!page.id} onOpenChange={close}>
      <DialogSurface>
        <DialogBody>
          <OverlaySpinner visible={duplicate.isPending} appearance="primary" />
          <DialogTitle action={(
            <DialogTrigger action="close">
              <Button
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
                onClick={close}
              />
            </DialogTrigger>
          )}
          >
            <T id="page.duplicate.title" />
          </DialogTitle>
          <DialogContent>
            {duplicate.isError
              ? (
                <ErrorAlert
                  title={t('page.duplicate.error.title')}
                  help={t('all.error.review')}
                  detail={parseServerError(duplicate.error)}
                  onClose={() => duplicate.reset()}
                />
                )
              : null}
            <T id="page.duplicate.help" />
          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              onClick={() => {
                duplicate.mutate(page.id, { onSuccess: close })
              }}
            >
              <T id="page.duplicate.action.submit" />
            </Button>
            <Button onClick={close}>
              <T id="page.duplicate.action.cancel" />
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
