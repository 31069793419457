import { AppPage } from '@/AppPage'
import { ContainerWrapper } from '@/ContainerWrapper'
import { AccountInformationDetail } from '@/components/manage-account/account-info'
import { SecurityInfo } from '@/components/manage-account/security/2FA-switcher'
import { useT } from '@/libs/intl/useT'

export function ManageAccountPage(): JSX.Element {
  const t = useT()
  return (
    <AppPage title={t('account.manage.title')}>
      <ContainerWrapper>
        <AccountInformationDetail />
        <SecurityInfo />
      </ContainerWrapper>
    </AppPage>
  )
}
