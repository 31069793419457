import type { UseFormReturn } from 'react-hook-form'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { makeStyles, shorthands, tokens } from '@aisekisan/bond'
import type { EquipmentAttributeTreeNode, SelectedNode } from '../type'
import { findPathIDToNode, findPathToNode, getSelectedLevel } from '../tree/utils'
import type { EquipmentClassDetail } from '../../type'
import { PropertyList } from './list'
import { PropertyOverview } from './overview'
import type { PropertyFormBody } from './type'

const useStyles = makeStyles({
  container: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    rowGap: tokens.spacingVerticalL,
    columnGap: tokens.spacingHorizontalL,
    boxSizing: 'border-box',
    alignItems: 'self-start',
    paddingBottom: tokens.spacingHorizontalL,
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalL,
    ...shorthands.margin(0),
  },
})

interface Props {
  equipmentClasses: EquipmentClassDetail[]
  form: UseFormReturn<PropertyFormBody>
  insulTree: EquipmentAttributeTreeNode | null
  isCreate?: boolean
}

export function PropertyForm(props: Props): ReactElement {
  const { equipmentClasses, form, insulTree, isCreate = false } = props

  const root = form.getValues('tree')

  const styles = useStyles()

  const [selected, setSelected] = useState<SelectedNode | null>(null)

  const activeIDNodes = selected !== null
    ? findPathIDToNode(root, selected.node.id)
      .filter((_node, index) => index % 2 === 1)
    : []

  const activeNodes = selected !== null
    ? findPathToNode(root, selected.node.id)
      .filter((_tree, index) => index % 2 === 1)
    : []

  return (
    <div className={styles.container}>
      <PropertyOverview
        equipmentClasses={equipmentClasses}
        form={form}
        isCreate={isCreate}
        selected={selected}
        setSelected={setSelected}
        onChange={(tree) => {
          form.setValue('tree', tree)
        }}
      />

      {activeNodes.map(node => (
        <PropertyList
          key={node.id}
          equipmentClasses={equipmentClasses}
          form={form}
          selected={{
            node,
            level: getSelectedLevel(root, node.id, 0),
          }}
          setSelected={setSelected}
          activeIDNodes={activeIDNodes}
          insulTree={insulTree}
        />
      ))}
    </div>
  )
}
