import type { ReactElement } from 'react'
import type { CategoryDetail, ConstructionDetail } from '@aisekisan/anya-api'
import type { ReportHead, ReportRow } from '../../type'
import { FirePieceReportBody } from './body'
import { FirePieceReportHeader } from './header'
import { FirePieceReportOverview } from './overview'

interface Props {
  construction: ConstructionDetail
  category: CategoryDetail
  reportHeads: ReportHead[]
  reportRows: ReportRow[]
}

export function FirePieceReport(props: Props): ReactElement | null {
  const { construction, category, reportHeads, reportRows } = props

  if (reportRows.length === 0)
    return null

  return (
    <tbody>
      <FirePieceReportOverview
        category={category}
        construction={construction}
      />
      <FirePieceReportHeader
        reportHeads={reportHeads}
      />
      <FirePieceReportBody
        reportHeads={reportHeads}
        reportRows={reportRows}
      />
    </tbody>
  )
}
