import { Text, Title3, makeStyles } from '@aisekisan/bond'
import * as Sentry from '@sentry/react'
import type { ReactElement } from 'react'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
})

export function ErrorBoundary(props: { children: ReactElement }) {
  const { children } = props

  const s = useStyles()

  const fallbackComponent = (
    <div className={s.container}>
      <Title3>Something went wrong 🥲</Title3>
      <Text>Please refresh the page or contact us</Text>
    </div>
  )

  return (
    <Sentry.ErrorBoundary
      fallback={fallbackComponent}
      onError={(error) => {
        Sentry.captureException(error, { tags: { component: 'ErrorBoundary' } })
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
