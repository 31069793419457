import type {
  TableColumnDefinition,
} from '@aisekisan/bond'
import {
  Button,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  createTableColumn,
} from '@aisekisan/bond'
import { EditRegular } from '@fluentui/react-icons'
import type {
  ConstructionModuleDetail,
} from '@aisekisan/anya-api'
import { useConstructionModuleList } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'

interface Props {
  constructionID: string
  setUpdate: (member: ConstructionModuleDetail) => void
}

export function ConstructionModuleTable(props: Props): JSX.Element {
  const { constructionID, setUpdate } = props

  const data = useConstructionModuleList({ constructionID }).data ?? []

  const columns: TableColumnDefinition<ConstructionModuleDetail>[] = [
    createTableColumn<ConstructionModuleDetail>({
      columnId: 'name',
      renderHeaderCell: () => {
        return <T id="construction.module.field.name" />
      },
      renderCell: cModule => cModule.name,

    }),
    createTableColumn<ConstructionModuleDetail>({
      columnId: 'full-name',
      renderHeaderCell: () => {
        return <T id="construction.module.field.quantity" />
      },
      renderCell: cModule => cModule.quantity,
    }),
    createTableColumn<ConstructionModuleDetail>({
      columnId: 'actions',
      renderHeaderCell: () => {
        return ''
      },
      renderCell: cModule => (
        <Button
          aria-label="update"
          appearance="transparent"
          icon={<EditRegular />}
          onClick={() => setUpdate(cModule)}
        >
          <T id="annotation.module.edit.submit" />
        </Button>
      ),
    }),
  ]

  return (
    <DataGrid
      items={data ?? []}
      columns={columns}
      getRowId={cModule => cModule.id}
    >
      <DataGridHeader>
        <DataGridRow>
          {({ renderHeaderCell }) => (
            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
          )}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<ConstructionModuleDetail>>
        {({ item, rowId }) => (
          <DataGridRow<ConstructionModuleDetail> key={rowId}>
            {({ renderCell }) => (
              <DataGridCell>{renderCell(item)}</DataGridCell>
            )}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  )
}
