import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { server } from '../server'

const KEY = 'org'

export function useOrgList() {
  return useQuery({
    queryKey: [KEY],
    queryFn: () => server.listOrgs(0, 200),
  })
}

export function useOrgDetail(params: { orgID: string }) {
  const { orgID } = params
  return useQuery({
    queryKey: [KEY, orgID],
    queryFn: () => server.getOrgDetail(orgID),
    enabled: orgID !== '',
  })
}
export type OrgUpdateBody = Parameters<typeof server.updateOrg>[1]

export function useOrgUpdate(params: { orgID: string }) {
  const { orgID } = params
  const client = useQueryClient()

  return useMutation({
    mutationFn: (body: OrgUpdateBody) => {
      return server.updateOrg(orgID, body)
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: [KEY] })
    },
  })
}

export function useOrgStats(params: { orgID: string }) {
  const { orgID } = params
  return useQuery({
    queryKey: [KEY, 'stats', orgID],
    queryFn: () => server.getOrgStats(orgID),
    select: (data) => {
      const capacity = data.storageCapacity / (1024 ** 3)
      const used = data.storageUsed / (1024 ** 3)
      return {
        ...data,
        storageCapacity: Math.round(capacity * 100) / 100,
        storageUsed: Math.round(used * 100) / 100,
      }
    },
  })
}
