import { Translate } from '@aisekisan/bond'
import type { IntlDictId } from './dicts/type'

interface TProps {
  id: IntlDictId
  params?: Record<string, string>
}

export function T(props: TProps): JSX.Element {
  return <Translate {...props} />
}
