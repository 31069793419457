import { DefaultService } from './generated'

/**
 * It is NOT expected to use this "server" instance outside of "src/server".
 * Instead, most places should use one of those queries or mutations so that
 * global caching works properly.
 *
 * In other words, only use what available at "@/server"
 */
export const server = DefaultService
