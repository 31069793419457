import type { TLLineShape, TLShape } from '@tldraw/tldraw'
import type { PipeLineSegmentFlat } from '@aisekisan/anya-api'
import type { AnnotSize } from '../../shape/base'
import type { AnnotShapeColor } from '../../shape/color'
import type { SegmentMetaBase } from '../base'
import { isSegmentShapeBase } from '../base'
import { createSegmentFlatPartial } from './create'
import type { ShapePartial } from '@/components/editor/editor/shape/util/base'
import { getLineShapeEdgeRelative } from '@/components/editor/editor/shape/util/line'
import { getStrict } from '@/utils/primitive'

export type SegmentFlatMeta = SegmentMetaBase & {
  segment: 'flat'
}

export type SegmentFlatShape = TLLineShape & {
  meta: SegmentFlatMeta
}

export type SegmentFlatPartial = ShapePartial<
  TLLineShape,
  SegmentFlatMeta
>

export function isSegmentFlatShape(
  shape: TLShape,
): shape is SegmentFlatShape {
  if (!isSegmentShapeBase(shape))
    return false
  const test = shape as SegmentFlatShape
  return test.meta.segment === 'flat'
}

export function getSegmentFlatSize(flat: SegmentFlatShape): AnnotSize {
  return {
    direction: null,
    unit: 'px',
    value: getLineShapeEdgeRelative(flat).length,
  }
}

export function fromServerSegmentFlat(props: {
  color: AnnotShapeColor
  group: string
  server: PipeLineSegmentFlat
}): SegmentFlatPartial {
  const { color, group, server } = props

  const start = getStrict(server.points.at(0))
  const end = getStrict(server.points.at(1))
  return createSegmentFlatPartial({ color, start, end, group })
}
