import type { EquipmentAttributeTreeNode } from '../type'

export function getLatestID(tree: EquipmentAttributeTreeNode): number {
  return Math.max(tree.id, ...tree.children.map(node => getLatestID(node)))
}

export function getSelectedLevel(root: EquipmentAttributeTreeNode, id: number, currentLevel: number): number {
  if (root.children.length === 0)
    return -1

  if (root.id === id)
    return currentLevel

  for (const child of root.children) {
    const childLevel = getSelectedLevel(child, id, currentLevel + 1)
    if (childLevel !== -1)
      return childLevel
  }

  return -1
}

export function updateNodeInTree(root: EquipmentAttributeTreeNode, id: number, newNode: EquipmentAttributeTreeNode): EquipmentAttributeTreeNode | null {
  if (root.id === id)
    return newNode

  const newChildren = root.children.map(child =>
    updateNodeInTree(child, id, newNode),
  )

  return {
    ...root,
    children: newChildren as EquipmentAttributeTreeNode[],
  }
}

export function findPathIDToNode(root: EquipmentAttributeTreeNode, targetId: number): number[] {
  if (!root)
    return []

  if (root.id === targetId)
    return [root.id]

  for (const child of root.children) {
    const path = findPathIDToNode(child, targetId)
    if (path.length > 0)
      return [root.id, ...path]
  }

  return []
}

export function findPathToNode(root: EquipmentAttributeTreeNode, targetId: number): EquipmentAttributeTreeNode[] {
  if (!root)
    return []

  if (root.id === targetId)
    return [root]

  for (const child of root.children) {
    const path = findPathToNode(child, targetId)
    if (path.length > 0)
      return [root, ...path]
  }

  return []
}

let id: number = 0

export function cloneAndReplaceIDs(node: EquipmentAttributeTreeNode, idCounter: number): EquipmentAttributeTreeNode {
  const clonedNode: EquipmentAttributeTreeNode = {
    ...node,
    id: idCounter++,
  }

  id = idCounter

  clonedNode.children = node.children.map((childNode) => {
    return cloneAndReplaceIDs(childNode, ++id)
  })
  return clonedNode
}
