import type {
  MessageBarIntent,
  Slot,
} from '@fluentui/react-components'
import {
  MessageBar as FMessageBar,
  MessageBarActions,
  MessageBarBody,
  MessageBarTitle,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from '@fluentui/react-components'
import * as React from 'react'

const useStyles = makeStyles({
  messageBar: {
    ...shorthands.padding(tokens.spacingHorizontalMNudge),
    display: 'flex',
    flexDirection: 'row',
    ...shorthands.gap(0),
    justifyContent: 'space-between',
    maxWidth: '480px',
  },
  compact: {
    width: 'auto',
  },
  resizableArea: {
    'display': 'flex',
    'flexDirection': 'column',
    ...shorthands.gap('10px'),
    'position': 'relative',
    ...shorthands.overflow('hidden'),
    '::after': {
      position: 'absolute',
      ...shorthands.padding(tokens.spacingHorizontalMNudge),
      top: '-2px',
      left: '-2px',
      fontFamily: 'monospace',
      fontSize: '15px',
      fontWeight: 900,
      lineHeight: 1,
      letterSpacing: '1px',
      backgroundColor: tokens.colorBrandBackground,
    },
  },
})

interface MessageBarProps {
  id: string
  intent: MessageBarIntent
  title: React.ReactNode
  body: React.ReactNode
  actions: Slot<'div'>
  compact?: boolean
}

// Avoid naming conflict with Fluent's MessageBar
export function BondMessageBar(props: MessageBarProps): JSX.Element {
  const { intent, title, body, id, actions, compact = false } = props
  const styles = useStyles()

  return (
    <div
      className={mergeClasses(styles.resizableArea, compact && styles.compact)}
    >
      <FMessageBar
        key={`${intent}-${id}`}
        intent={intent}
        className={styles.messageBar}
      >
        <MessageBarBody>
          <MessageBarTitle>{title}</MessageBarTitle>
          {body}
        </MessageBarBody>
        <MessageBarActions containerAction={actions} />
      </FMessageBar>
    </div>
  )
}
