import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { OmitCaptcha } from '../captcha'
import { server } from '../server'

const KEY = 'account'

// Heads up! Account/auth queries and mutations are super sensitive so in many
// places in this file we need to use "resetQueries" instead of "invalidateQueries"
// - To reproduce bugs, sleep in queries such as `useAccountInfo` and see that
//   "router/start" would navigate wrongly

export function useAccountInfo() {
  return useQuery({
    queryKey: [KEY],
    queryFn: async () => {
      // @debug: Uncomment this to debug unstable auth issues
      // await new Promise((r) => window.setTimeout(r, 5000));
      return await server.getAccountInfo()
    },
  })
}

export type AccountInfoUpdateBody = Partial<
  Parameters<typeof server.updateAccountInfo>[0]
>
export function useAccountInfoUpdate() {
  const client = useQueryClient()

  return useMutation({
    mutationFn: async (update: AccountInfoUpdateBody): Promise<void> => {
      const account = await server.getAccountInfo()
      await server.updateAccountInfo({ ...account, ...update })
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: [KEY] })
    },
  })
}

type AccountSignInBody = OmitCaptcha<Parameters<typeof server.login>[0]>

export function useAccountSignIn() {
  const client = useQueryClient()

  return useMutation({
    mutationFn: async (params: AccountSignInBody) => {
      const { email, password } = params
      return await server.login({ email, password })
    },
    onSuccess: async () => {
      // See comment at top
      client.resetQueries()
    },
  })
}

type AccountSignInOTPBody = OmitCaptcha<Parameters<typeof server.loginTotp>[0]>

export function useAccountSignInOTP() {
  const client = useQueryClient()

  return useMutation({
    mutationFn: async (params: AccountSignInOTPBody) => {
      const { passcode } = params
      await server.loginTotp({ passcode })
    },
    onSuccess: async () => {
      // See comment at top
      client.resetQueries()
    },
  })
}

export function useAccountSignOut() {
  const client = useQueryClient()

  return useMutation({
    mutationFn: server.logout,
    onSuccess: async () => {
      client.resetQueries()
    },
  })
}

export type AccountInvitationDetail = Awaited<
  ReturnType<typeof server.acceptOrgInvitePreflight>
>

export function useAccountCheckInvitation() {
  const client = useQueryClient()

  return useMutation({
    mutationFn: server.acceptOrgInvitePreflight,
    onSuccess: () => {
      // See comment at top
      client.resetQueries()
    },
  })
}

export type AccountAcceptInvitationBody = Parameters<
  typeof server.acceptOrgInvite
  >[0]

type SignUpBody = AccountAcceptInvitationBody & {
  email: string
}

export function useAccountAcceptInvitation() {
  const client = useQueryClient()

  return useMutation({
    mutationFn: async (body: SignUpBody) => {
      return server.acceptOrgInvite(body)
    },
    onSuccess: async () => {
      // See comment at top
      client.resetQueries()
    },
  })
}

export type AccountResetPasswordByEmailBody = OmitCaptcha<
  Parameters<typeof server.resetPasswordByEmail>[0]
>
export function useAccountResetPasswordByEmail() {
  const client = useQueryClient()

  return useMutation({
    mutationFn: async (
      params: AccountResetPasswordByEmailBody,
    ) => {
      const { email } = params
      return await server.resetPasswordByEmail({ email })
    },
    onSuccess: async () => {
      // See comment at top
      client.resetQueries()
    },
  })
}

export type AccountResetPasswordBody = Parameters<
  typeof server.resetPassword
>[0]
export function useAccountResetPassword() {
  const client = useQueryClient()

  return useMutation({
    mutationFn: server.resetPassword,
    onSuccess: () => {
      // See comment at top
      client.resetQueries()
    },
  })
}
